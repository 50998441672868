import React from "react";
import { MingcuteWarningLine } from '@/icons/MingcuteWarningLine';

type tabType = "all-projects" | "KalMedia" | "KalAudio" | "KalChat" | "KalDocs";

interface Props {
  activeTab: tabType;
  onTabChange: (tab: tabType) => void;
  productAvailability: {
    [key: string]: boolean;
  };
}

const TabMenu: React.FC<Props> = ({ activeTab, productAvailability, onTabChange }) => {

  const tabs = [
    { name: "All Projects", key: "all-projects", color: "#1890ff", left: "0px" },
    { name: "Kal Audio", key: "KalAudio", color: "#722ed1", left: "160px" },
    { name: "Kal Media", key: "KalMedia", color: "#2f54eb", left: "320px" },
    { name: "Kal Doc", key: "KalDocs", color: "#1890ff", left: "480px" },
    { name: "Kal Chat", key: "KalChat", color: "#17c2c2", left: "640px" }
  ];

  return (
    <div className="relative w-[800px] h-7">
      {tabs.map((tab) => {
        const isActive = activeTab === tab.key;
        return (
          <div
            key={tab.key}
            className={`absolute top-0 h-6 px-2 py-0 flex items-center justify-center cursor-pointer ${isActive ? "border-b-2 text-black" : "text-black"}`}
            style={{
              left: tab.left,
              color: isActive ? tab.color : undefined,
              borderColor: isActive ? tab.color : undefined
            }}
            onClick={() => onTabChange(tab.key as tabType)}
          >
            <div className="relative flex items-center font-medium text-base text-center tracking-[0] leading-[19.2px] whitespace-nowrap">
              {tab.name}
              {tab.key !== "all-projects" && !productAvailability[tab.key] && isActive && (
                <MingcuteWarningLine className="ml-1" color={tab.color} />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TabMenu;
