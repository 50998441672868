import React from "react";

interface Props {
  className?: string;
}

export const EditResultsIcon = ({ className }: Props): JSX.Element => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_78_11061)">
        <path
          d="M4.66602 4.6665H3.99935C3.64573 4.6665 3.30659 4.80698 3.05654 5.05703C2.80649 5.30708 2.66602 5.64622 2.66602 5.99984V11.9998C2.66602 12.3535 2.80649 12.6926 3.05654 12.9426C3.30659 13.1927 3.64573 13.3332 3.99935 13.3332H9.99935C10.353 13.3332 10.6921 13.1927 10.9422 12.9426C11.1922 12.6926 11.3327 12.3535 11.3327 11.9998V11.3332"
          stroke="#5C5D71"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.59 4.39007C13.8526 4.12751 14.0001 3.77139 14.0001 3.40007C14.0001 3.02875 13.8526 2.67264 13.59 2.41007C13.3274 2.14751 12.9713 2 12.6 2C12.2287 2 11.8726 2.14751 11.61 2.41007L6 8.00007V10.0001H8L13.59 4.39007Z"
          stroke="#5C5D71"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.666 3.3335L12.666 5.3335"
          stroke="#5C5D71"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_78_11061">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
