import React from "react";
import { ProductTag } from "../tags/ProductTags";
import { MarketplaceButton } from "@/components/Buttons";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { OrgData } from "@/types";
import { useTranslation } from 'react-i18next';


interface PurchasedCardProps {
  isSuperAdmin: boolean;
  selectedOrg: OrgData | null; // Change to OrgData | null to account for undefined state
  selectedProducts: { [key: string]: boolean };
  setSelectedProducts: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
}

const PurchasedCard: React.FC<PurchasedCardProps> = ({
  isSuperAdmin,
  selectedOrg,
  selectedProducts,
  setSelectedProducts,
}) => {
  const { t } = useTranslation('PurchasedCard');
  const products = useSelector(
    (state: RootState) => state.systemService.products
  );

  const checkProductExists = (productName: string) => {
    return selectedOrg?.products?.hasOwnProperty(productName) || false;
  };

  const handleProductClick = (productName: string) => {
    if (isSuperAdmin) {
      setSelectedProducts((prev) => ({
        ...prev,
        [productName]: !prev[productName],
      }));
    }
  };

  return (
    <div
      className={`w-[692px] ${
        isSuperAdmin ? "h-[110px] top-8" : "h-[180px]"
      } p-5 rounded-lg border border-black/opacity-10 flex-col justify-start items-start gap-2.5 inline-flex relative`}
    >
      <div className="w-[628px] h-[116px] relative">
        <div className="absolute top-0 text-black text-base font-medium capitalize leading-normal">
          {t("purchasedProducts")}
        </div>
        <div
          className="absolute top-[28px]"
          onClick={() => handleProductClick("KalAudio")}
          style={isSuperAdmin ? { cursor: "pointer" } : {}}
        >
          <ProductTag
            type="KalAudio"
            disabled={
              !selectedProducts["KalAudio"] && !checkProductExists("KalAudio")
            }
          />
        </div>
        <div
          className="absolute top-[28px] left-[101px]"
          onClick={() => handleProductClick("KalChat")}
          style={isSuperAdmin ? { cursor: "pointer" } : {}}
        >
          <ProductTag
            type="KalChat"
            disabled={
              !selectedProducts["KalChat"] && !checkProductExists("KalChat")
            }
          />
        </div>
        <div
          className="absolute top-[28px] left-[202px]"
          onClick={() => handleProductClick("KalDocs")}
          style={isSuperAdmin ? { cursor: "pointer" } : {}}
        >
          <ProductTag
            type="KalDocs"
            disabled={
              !selectedProducts["KalDocs"] && !checkProductExists("KalDocs")
            }
          />
        </div>
        <div
          className="absolute top-[28px] left-[289px]"
          onClick={() => handleProductClick("KalMedia")}
          style={isSuperAdmin ? { cursor: "pointer" } : {}}
        >
          <ProductTag
            type="KalMedia"
            disabled={
              !selectedProducts["KalMedia"] && !checkProductExists("KalMedia")
            }
          />
        </div>
        {!isSuperAdmin && (
          <>
            <div className="absolute top-[65px] text-black text-base font-semibold capitalize leading-normal">
              {t("purchasePlan")}
            </div>
            <div className="absolute top-[90px] w-[628px] text-black text-sm font-normal capitalize leading-[16.80px] tracking-tight">
              {t("paragraph")}
            </div>
          </>
        )}
      </div>
      {!isSuperAdmin && (
        <div className="mb-[-15px]">
          <MarketplaceButton onClick={() => {}} />
        </div>
      )}
    </div>
  );
};

export default PurchasedCard;
