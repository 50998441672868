import React, { useEffect, useState } from "react";
import styles from "./css/CreateNewProjectCard.module.css";
import { Button } from "@/components/Buttons";
import { Plus } from "../../icons/Plus";
import SelectField from "../inputFields/SelectField";
import { useNavigate } from "react-router-dom";
import { ProductData } from "@/types";
import { ProductTypes } from "@/types";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { useTranslation } from 'react-i18next';

interface CreateNewProjectCardProps {
  type?: ProductTypes | "";
  productsList: ProductData[];
}

const CreateNewProjectCard: React.FC<CreateNewProjectCardProps> = ({
  type = "",
  productsList,
}) => {
  const { t } = useTranslation('CreateNewProjectCard');
  const navigate = useNavigate();
  const [selected, setSelected] = useState<ProductData>();
  const org = useSelector((state: RootState) => state.org.userOrg);

  const handleClick = () => {
    if (selected) {
      navigate(
        `/${org.org_name}/project/create/${selected.name.replace(" ", "")}`
      );
    }
  };

  useEffect(() => {
    if (type === "") {
      setSelected(undefined);
    } else {
      const product = productsList.find((product) => product.name === type);
      setSelected(product);
    }
  }, [type]);

  const handleUpdate = (product: ProductData | undefined) => {
    setSelected(product);
  };

  return (
    <div className={styles.frameBorder}>
      <div className={styles.headerContainer}>
        <div className="absolute w-[157px] h-[51px] top-0 left-[35px]">
          <div className={styles.title}>{t('CreateProject')}</div>
          <div className={styles.subtitle}>{t('SelectSoftware')}</div>
        </div>
        <div className={styles.fieldContainer}>
          <SelectField
            data={productsList}
            updateFunction={handleUpdate}
            disabledState={type !== ""}
            selectedValue={selected as ProductData}
            size="small"
          />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          text={t('NewProject')}
          icon="right-side"
          iconComponent={Plus}
          onClick={handleClick}
          size="small"
          className="h-[unset] whitespace-nowrap w-fit"
          disabled={selected === undefined}
          iconColorType="text"
        />
      </div>
    </div>
  );
};

export default CreateNewProjectCard;
