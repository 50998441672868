import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuButton } from "@/components/Buttons/MenuButton";
import { DashboardIcon } from "@/icons/DashboardIcon";
import { SettingIcon } from "@/icons/SettingIcon";
import { AdminIcon } from "@/icons/AdminIcon";
import { UserHomeIcon } from "@/icons/UserHomeIcon";
import { UserMediaIcon } from "@/icons/userMediaIcon";
import { UserDocsIcon } from "@/icons/UserDocsIcon";
import { UserAudioIcon } from "@/icons/UserAudioIcon";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { ChevronLeft } from "lucide-react";
import { useDispatch } from "react-redux";
import { setChatState } from "@/store/chatSlice";
import { useTranslation } from 'react-i18next';

interface Props {
  type: "Dashboard" | "Settings" | "Users" | "Home";
}

const SideBar: React.FC<Props> = ({ type }) => {
  const { t } = useTranslation('SideBar');
  const user = useSelector((state: RootState) => state.user.user);
  const org = useSelector((state: RootState) => state.org.userOrg);
  const isSuprAdmin = user.user_type === "super_admin";
  const isAdmin = user.user_type === "admin";
  const [selected, setSelected] = React.useState<string>(type);
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const path = location.pathname.split("/")[2];
    const homeOrDashboard = (user.user_type === "super_admin" || user.user_type === "admin") ? "Dashboard" : "Home";
    switch (path) {
      case "dashboard":
        setSelected(homeOrDashboard);
        break;
      case "settings":
        setSelected("Settings");
        break;
      case "users":
        setSelected("Users");
        break;
      case "Home":
        setSelected("Home");
        break;
      case "doc":
        setSelected("Doc");
        break;
      case "audio":
        setSelected("Audio");
        break;
      case "media":
        setSelected("Media");
        break;
      default:
        setSelected(homeOrDashboard);
    }
  }, [location, type]);

  const handleMenuClick = (selectedItem: string) => {
    setSelected(selectedItem);
    switch (selectedItem) {
      case "Dashboard":
        setSelected("Dashboard");
        dispatch(setChatState({ 
          chatSearchFocus: "none", 
          isChatResMode: false, 
          toShowPrompt: true, 
          chatWaiting: false, 
          chatQuery: "",
          responseMessage: "",
          responseFilteredFiles: [],
        }));
        navigate(`/${org.org_name}/dashboard`);
        break;
      case "Settings":
        setSelected("Settings");
        navigate(`/${org.org_name}/settings`);
        break;
      case "Users":
        setSelected("Users");
        navigate(`/${org.org_name}/users`);
        break;
      case "Doc":
        setSelected("Doc");
        navigate(`/${org.org_name}/doc/files`);
        break;
      case "Audio":
        setSelected("Audio");
        navigate(`/${org.org_name}/audio/files`);
        break;
      case "Media":
        setSelected("Media");
        navigate(`/${org.org_name}/media/files`);
        break;
      default:
        setSelected("Dashboard");
        navigate(`/${org.org_name}/dashboard`);
    }
  };

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={`h-full bg-white shadow-lg flex overflow-hidden transition-all duration-300 ${isCollapsed ? 'w-[65px]' : 'w-[216px]'}`}>
      <div className={`flex flex-col items-start space-y-4 ${isCollapsed ? 'p-2 pr-0' : 'p-4'} overflow-hidden flex-1`}>
        {isSuprAdmin || isAdmin ? (
          <>
            <div className={`w-full ${isCollapsed ? 'mr-2' : ''}`}>
              <MenuButton
                iconComponent={DashboardIcon}
                text={t("Dashboard")}
                selected={selected}
                iconColorType="stroke"
                onClick={() => handleMenuClick("Dashboard")}
                showText={!isCollapsed}
              />
            </div>
            {!isSuprAdmin && (
              <>
                <div className={`w-full ${isCollapsed ? 'mr-2' : ''}`}>
                  <MenuButton
                    iconComponent={AdminIcon}
                    text={t("Users")}
                    selected={selected}
                    iconColorType="stroke"
                    onClick={() => handleMenuClick("Users")}
                    showText={!isCollapsed}
                  />
                </div>
                <div className={`w-full ${isCollapsed ? 'mr-2' : ''}`}>
                  <MenuButton
                    iconComponent={SettingIcon}
                    text={t("Settings")}
                    selected={selected}
                    iconColorType="stroke"
                    onClick={() => handleMenuClick("Settings")}
                    showText={!isCollapsed}
                  />
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className="w-full">
              <MenuButton
                iconComponent={UserHomeIcon}
                text={t("Home")}
                selected={selected}
                iconColorType="stroke"
                onClick={() => handleMenuClick("Dashboard")}
                showText={!isCollapsed}
              />
            </div>
            <div className="w-full">
              <MenuButton
                iconComponent={UserAudioIcon}
                text={t("Doc")}
                selected={selected}
                iconColorType="stroke"
                onClick={() => handleMenuClick("Doc")}
                showText={!isCollapsed}
              />
            </div>
            <div className="w-full">
              <MenuButton
                iconComponent={UserDocsIcon}
                text={t("Audio")}
                selected={selected}
                iconColorType="stroke"
                onClick={() => handleMenuClick("Audio")}
                showText={!isCollapsed}
              />
            </div>
            <div className="w-full">
              <MenuButton
                iconComponent={UserMediaIcon}
                text={t("Media")}
                selected={selected}
                iconColorType="stroke"
                onClick={() => handleMenuClick("Media")}
                showText={!isCollapsed}
              />
            </div>
          </>
        )}
      </div>
      <div className={`w-[8px] ${isCollapsed ? 'bg-[#519bf4]' : 'bg-[#E5EDFF]'} flex items-center justify-center cursor-pointer`} onClick={toggleSidebar}>
        <div className={`h-12 w-8 ${isCollapsed ? 'bg-[#519bf4]' : 'bg-[#E5EDFF]'} rounded-l-full flex items-center justify-center`}>
          <ChevronLeft size={24} color={isCollapsed ? "white" : "#519bf4"} className={`transform transition-transform duration-300 ${isCollapsed ? 'rotate-180' : ''}`} />
        </div>
      </div>
    </div>
  );
};

export default SideBar;