import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FileData, ProjectColors } from "@/types";
import styles from "./css/FileCard.module.css";
import { InProcessingIcon } from "@/icons/InProcessingIcon";
import { AudioIcon } from "@/icons/docs/audio";
import { WordIcon } from "@/icons/docs/word";
import { InvoiceIcon } from "@/icons/docs/invoice";
import { DocsIcon } from "@/icons/docs/docs";
import { AudioMegaphoneIcon } from "@/icons/docs/audioMegaphone";
import { FolderIcon } from "@/icons/docs/folder";
import { AlbumIcon } from "@/icons/docs/album";
import { ProjectTag } from "../tags/ProjectTag";
import TruncateText from "../addons/TruncatedText";
import { useTranslation } from 'react-i18next';


interface FileCardProps {
  className?: string;
  data?: FileData;
  folder?: boolean;
  album?: boolean;
  projectName: string;
  projectColor: ProjectColors;
  disabled?: boolean;
}

export const FileCard: React.FC<FileCardProps> = ({
  className,
  data,
  folder = false,
  album = false,
  projectName,
  projectColor,
  disabled = false
}) => {
  const { t } = useTranslation('FileCard');
  const navigate = useNavigate();
  const {orgName} = useParams<{orgName: string}>();
  const fileType = data
    ? !data.status.task_done
      ? "aiContainer"
      : data.product === "KalAudio"
        ? "audioContainter"
        : data.product === "KalDocs"
          ? "docsContainter"
          : "albumContainter"
    : folder
      ? "folderContainter"
      : "albumContainter";
  const filePathType = data
    ? data.product === "KalAudio"
      ? "audio"
      : data.product === "KalDocs"
        ? "doc"
        : "media"
    : ""

  const handleClick = () => {
    if (data && data.status.task_done) {
      navigate(`/${orgName}/${filePathType}/file/${data.id}`);
    }
  };

  return (
    <div className={`${styles.fileCard} ${className}`} onClick={handleClick}>
      <div className={`${styles.fileCardTopContainer} ${!data?.status.task_done ? styles.notClickable : ''} ${styles[fileType]}`}>
        {data && !data.status.task_done ? (
          <>
            <InProcessingIcon className={`${styles.processing} ${styles.iconProcessing}`} />
            <div className={`${styles.typeTextProcessing} ${styles.processing}`}>{t('processing')}</div>
          </>
        ) : (
          data && (
            data.product === "KalDocs" ? (
              <>
                {/* {data.type === "word" ? (
                  <WordIcon className={`${styles.docs} ${styles.icon}`} />
                ) : data.type === "invoice" ? (
                  <InvoiceIcon className={`${styles.docs} ${styles.icon}`} />
                ) : ( */}
                  <DocsIcon className={`${styles.docs} ${styles.icon}`} />
                {/* )} */}
                {/* <div className={`${styles.typeText} ${styles.docs}`}>Docs</div> */}
              </>
            ) : data.product === "KalAudio" ? (
              <>
                {/* {data.type === "audio" ? (
                  <AudioIcon className={`${styles.audio} ${styles.icon}`} />
                ) : ( */}
                  <AudioIcon className={`${styles.audio} ${styles.icon}`} />
                {/* )} */}
                {/* <div className={`${styles.typeText} ${styles.audio}`}>Audio</div> */}
              </>
            ) : (
              <></>
            )
          )
        )}
        {!data &&
          (folder ? (
            <>
              <FolderIcon className={`${styles.folder} ${styles.icon}`} />
              <div className={`${styles.typeText} ${styles.folder}`}>{t('folder')}</div>
            </>
          ) : album ? (
            <>
              <AlbumIcon className={`${styles.album} ${styles.icon}`} />
              <div className={`${styles.typeText} ${styles.album}`}>{t('album')}</div>
            </>
          ) : (
            <></>
          ))}
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.name}>
            <TruncateText text={data?.name || "FileName"} maxLength={13} tooltipPlacement="top"/>
        </div>
        <ProjectTag color={projectColor} name={projectName || data?.name} disabled={disabled}/>
      </div>
    </div>
  );
};
