import { useMutation } from '@tanstack/react-query';
import { createLicense } from "@/api/organizationApi";
import { CreateLicenseData, ReturnCreateLicense } from "@/types/organiztion";
import { PostParam } from "@/types/index";

export function useCreateLicense(props?: PostParam) {
  const { onSuccess, onError } = props || {};

  return useMutation<ReturnCreateLicense, Error, CreateLicenseData>({
    mutationFn: createLicense,
    onSuccess,
    onError
  });
}
