import React from "react";

interface Props {
  className?: string;
}

export const DownloadFileIcon = ({ className }: Props): JSX.Element => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_78_11066)">
        <path
          d="M2.66602 11.3335V12.6668C2.66602 13.0205 2.80649 13.3596 3.05654 13.6096C3.30659 13.8597 3.64573 14.0002 3.99935 14.0002H11.9993C12.353 14.0002 12.6921 13.8597 12.9422 13.6096C13.1922 13.3596 13.3327 13.0205 13.3327 12.6668V11.3335"
          stroke="#5C5D71"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.66602 7.3335L7.99935 10.6668L11.3327 7.3335"
          stroke="#5C5D71"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 2.6665V10.6665"
          stroke="#5C5D71"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_78_11066">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
