import { ProductTypes } from '@/types/projectCard';
import { FileData, TransactionResult } from '../types/files';

const formatDate = (date: Date): string => {
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit'
    }).replace(/\//g, '/');
  };

export const mockFiles: FileData[] = Array(30).fill(null).map((_, index) => ({
  id: `file_${index + 1}`,
  project_id: `project_${Math.floor(index / 5) + 1}`,
  org_id: `org_${Math.floor(index / 10) + 1}`,
  source_id: `source_${Math.floor(index / 3) + 1}`,
  path: `/path/to/file_${index + 1}`,
  name: `File ${index + 1}`,
  type: index % 2 === 0 ? 
    ['doc', 'word', 'invoice'][Math.floor(Math.random() * 3)] : 
    ['audio', 'record'][Math.floor(Math.random() * 2)],
  size: Math.floor(Math.random() * 10000000),
  created_at: formatDate(new Date(Date.now() - Math.floor(Math.random() * 10000000000))),
  status: {'task_done': true, 'deleted': false},
  product: index % 2 === 0 ? 'KalDocs' : 'KalAudio' as ProductTypes,
  ...(index % 2 !== 0 && { audio_duration: `${Math.floor(Math.random() * 300)}s` }),
  duration: "20:35:44",
  source_name: "Source",
  url: ""
}));

export const mockTransactionResult: TransactionResult = {
    "client": [
      { start: "00:00:00", end: "00:00:02", word: "שלום" },
      { start: "00:00:02", end: "00:00:03", word: "מה" },
      { start: "00:00:03", end: "00:00:04", word: "שלומך" },
      { start: "00:00:04", end: "00:00:05", word: "היום" },
    ],
    "customer": [
      { start: "00:00:06", end: "00:00:07", word: "שלום" },
      { start: "00:00:07", end: "00:00:08", word: "לך" },
      { start: "00:00:08", end: "00:00:10", word: "הכל" },
      { start: "00:00:10", end: "00:00:11", word: "בסדר" },
    ],
    "client_2": [
      { start: "00:00:12", end: "00:00:13", word: "אני" },
      { start: "00:00:13", end: "00:00:15", word: "שמח" },
      { start: "00:00:15", end: "00:00:16", word: "לשמוע" },
    ],
    customer_2: [
      { start: "00:00:17", end: "00:00:18", word: "תודה" },
      { start: "00:00:18", end: "00:00:19", word: "רבה" },
      { start: "00:00:19", end: "00:00:21", word: "איך" },
      { start: "00:00:21", end: "00:00:22", word: "אוכל" },
      { start: "00:00:22", end: "00:00:23", word: "לעזור" },
    ],
    client_3: [
      { start: "00:00:24", end: "00:00:25", word: "יש" },
      { start: "00:00:25", end: "00:00:26", word: "לי" },
      { start: "00:00:26", end: "00:00:27", word: "שאלה" },
      { start: "00:00:27", end: "00:00:29", word: "לגבי" },
      { start: "00:00:29", end: "00:00:30", word: "החשבון" },
    ],
    customer_3: [
      { start: "00:00:31", end: "00:00:32", word: "בוודאי" },
      { start: "00:00:32", end: "00:00:33", word: "אשמח" },
      { start: "00:00:33", end: "00:00:34", word: "לעזור" },
      { start: "00:00:34", end: "00:00:36", word: "במה" },
      { start: "00:00:36", end: "00:00:37", word: "מדובר" },
    ],
    client_4: [
      { start: "00:00:38", end: "00:00:39", word: "ראיתי" },
      { start: "00:00:39", end: "00:00:40", word: "חיוב" },
      { start: "00:00:40", end: "00:00:41", word: "מוזר" },
      { start: "00:00:41", end: "00:00:43", word: "בחשבון" },
      { start: "00:00:43", end: "00:00:44", word: "שלי" },
    ],
    customer_4: [
      { start: "00:00:45", end: "00:00:46", word: "אני" },
      { start: "00:00:46", end: "00:00:47", word: "מבין" },
      { start: "00:00:47", end: "00:00:48", word: "בוא" },
      { start: "00:00:48", end: "00:00:49", word: "נבדוק" },
      { start: "00:00:49", end: "00:00:50", word: "את" },
      { start: "00:00:50", end: "00:00:51", word: "זה" },
    ]
  };