import React from "react";

interface Props {
  className: string;
}

export const AdminIcon = ({ className }: Props): JSX.Element => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Frame">
        <g id="Group 1261153548">
          <path
            id="Vector"
            d="M5.31561 5.63158C5.31561 6.85996 5.80358 8.03802 6.67217 8.90661C7.54076 9.7752 8.71882 10.2632 9.9472 10.2632C11.1756 10.2632 12.3536 9.7752 13.2222 8.90661C14.0908 8.03802 14.5788 6.85996 14.5788 5.63158C14.5788 4.40321 14.0908 3.22515 13.2222 2.35656C12.3536 1.48797 11.1756 1 9.9472 1C8.71882 1 7.54076 1.48797 6.67217 2.35656C5.80358 3.22515 5.31561 4.40321 5.31561 5.63158Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M3 21.8422V19.5264C3 18.298 3.48797 17.1199 4.35656 16.2513C5.22515 15.3827 6.40321 14.8948 7.63158 14.8948H11.6842"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_3"
            d="M17.3808 15.6011C17.6066 15.3753 17.8747 15.1962 18.1697 15.074C18.4648 14.9518 18.781 14.8889 19.1003 14.8889C19.4197 14.8889 19.7359 14.9518 20.0309 15.074C20.3259 15.1962 20.594 15.3753 20.8198 15.6011C21.0456 15.827 21.2247 16.095 21.3469 16.39C21.4691 16.6851 21.532 17.0013 21.532 17.3206C21.532 17.64 21.4691 17.9562 21.3469 18.2512C21.2247 18.5462 21.0456 18.8143 20.8198 19.0401L16.8945 23.0001H13.4208V19.5264L17.3808 15.6011Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};
