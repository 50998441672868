import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import React, { useMemo, useState, useCallback, useEffect } from "react";
import { RoleData } from "@/types";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  ColumnDef,
  flexRender,
  SortingState,
} from "@tanstack/react-table";
import { UserData, UpdateUser } from "@/types/user";
import SelectField from "../inputFields/SelectField";
import styles from "./css/UsersTable.module.css";
import { UserModal } from "../modals/UserModal";
import { useSelector, useDispatch } from "react-redux";
import { truncateText } from "@/utils/truncateText";
import { UserAvatar } from "@/components/addons/UserAvatar";
import { RootState } from "@/store/store";
import { useUpdateUser } from "@/hooks/user/useUpdateUser";
import { setToast } from "@/store/systemSlice";
import { useFetchAllUsers } from "@/utils/fetchData";
import { useTranslation } from 'react-i18next';


interface UsersTableProps {
  className?: string;
  data: UserData[];
  users: UserData[];
}

const UsersTable: React.FC<UsersTableProps> = ({ className, data, users }) => {
  const { t } = useTranslation('UsersTable');
  const [selectedUser, setSelectedUser] = useState<UserData>();
  const [showModal, setShowModal] = useState(false);
  const [localData, setLocalData] = useState<UserData[]>(data);
  const roles = useSelector((state: RootState) => state.systemService.roles);
  const dispatch = useDispatch();
  const getAllUsers = useFetchAllUsers();

  useEffect(() => {
    setLocalData(data);
  }, [data]);

  const updateUserMutation = useUpdateUser({
    onSuccess: () => {
      const orgId = selectedUser?.org_id || "";
      getAllUsers.mutate({ org_id: orgId });
      dispatch(
        setToast({
          toastMessage: "",
          toastTitle: "Role updated successfully",
          toastType: "success",
        })
      );
    },
    onError: (error) => {
      dispatch(
        setToast({
          toastMessage: error.response.data.message,
          toastTitle: "Failed to update Role",
          toastType: "error",
        })
      );
    },
  });

  const columns = useMemo<ColumnDef<UserData>[]>(
    () => [
      {
        accessorKey: "color",
        header: "",
        cell: ({ row }) => {
          const color = row.original.color;
          const image = row.original.user_image;
          return <UserAvatar color={color || "blue"} userImage={image} />;
        },
      },
      {
        accessorKey: "user_name",
        header: t("userName"),
        cell: ({ getValue }) => {
          const value = getValue<string>();
          return (
            <div className={styles.userNameColumn}>
              {truncateText(value, 26)}
            </div>
          );
        },
      },
      {
        accessorKey: "role_id",
        header: t("userRole"),
        cell: ({ getValue, row }) => {
          const value = getValue<string>();
          const role = roles.find((role) => role.id === value);

          const handleUpdate = (selectedRole: RoleData | undefined) => {
            // Update the data directly
            const updatedUser: UpdateUser = {
              org_id: row.original.org_id,
              user_id: row.original.id,
              role_id: selectedRole ? selectedRole.id : "",
            };
            updateUserMutation.mutate(updatedUser);

            // Update local state for immediate visual change
            setLocalData((prevData) =>
              prevData.map((user) =>
                user.id === row.original.id
                  ? { ...user, role_id: selectedRole ? selectedRole.id : "" }
                  : user
              )
            );
          };

          return (
            <div
              className={styles.roleColumn}
              onClick={(e) => e.stopPropagation()}
            >
              <SelectField
                size="small"
                data={roles}
                updateFunction={handleUpdate}
                selectedValue={role as RoleData}
              />
            </div>
          );
        },
      },
      {
        accessorKey: "email",
        header: t("email"),
        cell: ({ getValue }) => {
          const value = getValue<string>();
          return (
            <div className={styles.emailColumn}>{truncateText(value, 30)}</div>
          );
        },
      },
      {
        accessorKey: "status",
        header: t("status"),
        cell: ({ getValue }) => {
          const value = getValue<string>();
          return (
            <div
              className={`${styles.statusColumn} ${
                value === "On Hold" ? styles.statusOnHold : ""
              }`}
            >
              {value}
            </div>
          );
        },
      },
      {
        accessorKey: "created_by",
        header: t("createdBy"),
        cell: ({ getValue }) => {
          const value = getValue<string>();
          return (
            <div className={styles.createdByColumn}>
              {truncateText(value, 14)}
            </div>
          );
        },
      },
    ],
    [roles, updateUserMutation]
  );

  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    data: localData,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const handleRowClick = useCallback((row: any) => {
    setSelectedUser(row.original);
    setShowModal(true);
  }, []);

  return (
    <>
      <div className={`${styles.tableContainer} ${className}`}>
        <table className={styles.table}>
          <thead className={styles.tableHeadersRow}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={`${styles.headerText} ${
                      styles[header.id + "Column"]
                    }`}
                    onClick={
                      header.id !== "color"
                        ? header.column.getToggleSortingHandler()
                        : () => {}
                    }
                  >
                    <div className="flex items-center">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {header.id !== "color" && (
                        <span className="ml-2">
                          {{
                            asc: <AiOutlineArrowUp className="text-black" />,
                            desc: <AiOutlineArrowDown className="text-black" />,
                            undefined: (
                              <AiOutlineArrowUp className="text-black" />
                            ),
                          }[header.column.getIsSorted() as string] ?? (
                            <AiOutlineArrowUp className="text-black" />
                          )}
                        </span>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className={styles.bodyRow}>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className={styles.rowHover}
                onClick={() => handleRowClick(row)}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className={`${styles.rowText} ${
                      cell.column.id === "project_name"
                        ? styles.projectName
                        : ""
                    }`}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showModal && (
        <UserModal
          users={users}
          user={selectedUser as UserData}
          setUser={() => {}}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default React.memo(UsersTable, (prevProps, nextProps) => {
  return (
    JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data) &&
    prevProps.className === nextProps.className
  );
});
