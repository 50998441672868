import OrganizationProjectsTable from "@/components/tables/OrganizationProjectsTable";
import { ProjectData } from "@/types";
import { useTranslation } from 'react-i18next';


interface Props {
  projects: ProjectData[];
}
const OrganizationProjectsTab: React.FC<Props>= ({ projects }) => {
  const { t, i18n } = useTranslation('OrganizationSettingPage');

  return (
    <div className="p-6 sm:p-9">
      <h2 className="form-title-text mb-3">
        {t('Projects')}
      </h2>
      <OrganizationProjectsTable data={projects}/>
    </div>
  );
};

export default OrganizationProjectsTab;