import { PostRequest } from "./requests";
import { Interact } from "@/types";

export const interract = async (data: Interact) => {
    try {
        return await PostRequest("/api/system_service/interact", data);
    } catch (error) {
        console.log('Error in runEvaluation:', error);
        throw error;
    }
}
