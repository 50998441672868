// src/store/userSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import secureLocalStorage from 'react-secure-storage';
import { getStoredData, getBooleanValue } from '@/utils/store';
import { OrgData } from '@/types';

interface OrgState {
  userOrg: OrgData
  orgs: OrgData[] // for super admin
  orgsFetched: boolean
}

const defaultOrgData = {
  org_name: 'Kaleidoo',
  org_description: "",
  id: "",
  org_color: "blue",
  logo: "",
  domain: "",
  admin_email: "",
  products: {}
} as OrgData

  const initialState: OrgState = {
    userOrg: getStoredData<OrgData>('userOrg', defaultOrgData),
    orgs: getStoredData<OrgData[]>('orgs', []),
    orgsFetched: getBooleanValue('orgsFetched')

  };

const orgSlice = createSlice({
  name: 'org',
  initialState,
  reducers: {
    setOrg: (state, action: PayloadAction<OrgState>) => {
      state.userOrg = action.payload.userOrg;
      state.orgs = action.payload.orgs;

      secureLocalStorage.setItem('userOrg', JSON.stringify(action.payload.userOrg) || '');
      secureLocalStorage.setItem('orgs', JSON.stringify(action.payload.orgs) || '');
      secureLocalStorage.setItem('orgsFetched', JSON.stringify(true) || '');

    },
    setUserOrg: (state, action) => {
      state.userOrg = action.payload;

      secureLocalStorage.setItem('userOrg', JSON.stringify(action.payload) || '');
    },
    setOrgs: (state, action) => {
      state.orgs = action.payload;
      state.orgsFetched = true;

      secureLocalStorage.setItem('orgsFetched', JSON.stringify(true) || '');
      secureLocalStorage.setItem('orgs', JSON.stringify(action.payload) || '');
    },
    clearOrg: (state) => {
      state.userOrg = defaultOrgData;
      state.orgs = [];
      state.orgsFetched = false;

      secureLocalStorage.removeItem('userOrg');
      secureLocalStorage.removeItem('orgs');
      secureLocalStorage.removeItem('orgsFetched');
    },
  },
});

export const { setOrg, setUserOrg, setOrgs, clearOrg } = orgSlice.actions;
export default orgSlice.reducer;
