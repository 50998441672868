import React, { useState, useRef, useEffect, useCallback } from "react";
import { createPortal } from "react-dom";
import { truncateText } from "../../utils/truncateText";
import { DropDown } from "@/icons/dropdown";
import styles from "./css/SelectField.module.css";

interface ListObject {
  name: string;
  [key: string]: any;
}

interface SelectButtonProps<T extends string | ListObject | undefined> {
  data: T[];
  updateFunction: (value: T | undefined) => void;
  disabledState?: boolean;
  selectedValue: T;
  size?: "small" | "large" | "xs";
  placeholder?: string;
}

const SelectField = <T extends string | ListObject | undefined>({
  data,
  updateFunction,
  disabledState = false,
  selectedValue,
  size = "large",
  placeholder = "",
}: SelectButtonProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownStyles, setDropdownStyles] = useState<React.CSSProperties>({});
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLUListElement | null>(null);
  const buttonRef = useRef<HTMLDivElement | null>(null);

  const getDisplayValue = (value: T) => {
    if (value) {
      return typeof value === "string" ? value : value.name;
    }
    return "";
  };

  const truncatedValue = truncateText(getDisplayValue(selectedValue), 22);

  const updateDropdownPosition = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setDropdownStyles({
        top: `${rect.bottom + window.scrollY}px`,
        left: `${rect.left + window.scrollX}px`,
        width: `${rect.width}px`,
        position: "absolute",
        zIndex: 9999,
      });
    }
  };

  const handleToggle = () => {
    if (!disabledState) {
      setIsOpen((prev) => {
        const newState = !prev;
        if (newState && buttonRef.current) {
          updateDropdownPosition();
        }
        return newState;
      });
    }
  };

  const handleSelect = (value: T) => {
    setIsOpen(false);
    const isStr = data.length !== 0 && typeof data[0] === "string";
    if (value === "" && !isStr) {
      updateFunction(undefined);
    } else {
      updateFunction(value);
    }
    setHighlightedIndex(null);
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  }, []);

  const handleScrollOrResize = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleClickOutside);
      window.addEventListener("scroll", handleScrollOrResize, true); // Use capture phase
      window.addEventListener("resize", handleScrollOrResize);
    } else {
      document.removeEventListener("click", handleClickOutside);
      window.removeEventListener("scroll", handleScrollOrResize, true);
      window.removeEventListener("resize", handleScrollOrResize);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
      window.removeEventListener("scroll", handleScrollOrResize, true);
      window.removeEventListener("resize", handleScrollOrResize);
    };
  }, [isOpen, handleClickOutside]);

  const renderItem = (item: T) => {
    if (item) {
      return typeof item === "string" ? item : item.name;
    }
    return "";
  };

  const isSelected = (item: T) => {
    if (typeof item === "string" && typeof selectedValue === "string") {
      return item === selectedValue;
    }
    if (typeof item === "object" && typeof selectedValue === "object") {
      return item.name === selectedValue.name;
    }
    return false;
  };

  const dropdown = (
    <ul ref={dropdownRef} className={styles.dropdown} style={dropdownStyles}>
      <li
        key="empty-option"
        className={`${styles["dropdown-item"]} ${styles["dropdown-item-small"]} bg-white opacity-0 ${styles["dropdown-item-hover"]}`}
        onClick={() => handleSelect("" as unknown as T)}
      >
        empty
      </li>
      {data.map((item, index) => (
        <li
          key={index}
          className={`${styles["dropdown-item"]} ${size === "small" ? styles["dropdown-item-small"] : styles["dropdown-item-large"]} ${styles["dropdown-item-hover"]} ${isSelected(item) ? styles["dropdown-item-selected"] : ""} ${highlightedIndex === index ? "bg-gray-200" : ""}`}
          onClick={() => handleSelect(item)}
        >
          {renderItem(item)}
        </li>
      ))}
    </ul>
  );

  return (
    <div ref={containerRef} className={styles.container}>
      <div
        ref={buttonRef}
        className={`${styles.button} ${size === "small" ? styles["button-small"] : size === "xs" ? styles.xs : styles["button-large"]} ${disabledState ? styles["button-disabled"] : styles["button-enabled"]}`}
        onClick={handleToggle}
        tabIndex={disabledState ? -1 : 0}
      >
        <span className={`${styles["text-enabled"]} ${disabledState ? styles["text-disabled"] : ""} ml-auto`}>
          {truncatedValue || <span className="placeholder">{placeholder}</span>}
        </span>
        <span className={`${styles["dropdown-icon-wrapper"]} ${disabledState ? styles["text-disabled"] : ""}`}>
          <DropDown
            className={`${styles["dropdown-icon"]} ${isOpen ? styles["dropdown-icon-open"] : ""} ${disabledState ? "text-gray-400" : "text-gray-600"}`}
          />
        </span>
      </div>
      {isOpen && createPortal(dropdown, document.body)}
    </div>
  );
};

export default SelectField;
