import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./css/FilesPage.module.css";
import { PdfView } from "@/components/files/PdfView";
import { AudioView } from "@/components/files/AudioView";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { FileData, TaskData } from "@/types";
import { FileHeader } from "@/components/headers/FileHeader";
import { useGetTask } from "@/hooks/tasks/UseGetTask";
import { useGetFile } from "@/hooks/files/useGetFile";
import { LoadingPageAnimation } from "@/components/addons/LoadingPageAnimation";

export const FilePage: React.FC = () => {
  const navigate = useNavigate();
  const { type, fileId } = useParams<{ type: string; fileId: string }>();
  const allFiles = useSelector((state: RootState) => state.files.allFiles);
  const [file, setFile] = useState<FileData>();
  const [task, setTask] = useState<TaskData>();

  const getFileMutation = useGetFile({
    onSuccess(data) {
      setFile(data);
    },
  });

  const getTaskMutation = useGetTask({
    onSuccess(data) {
      setTask(data.task);
    },
  });

  useEffect(() => {
    const fileFound = allFiles.find((file) => file.id === fileId);
    if (!fileFound) {
      navigate("/404");
    } else {
      getTaskMutation.mutate({
        org_id: fileFound.org_id,
        project_id: fileFound.project_id,
        obj_id: fileFound.id,
      });
      getFileMutation.mutate({
        org_id: fileFound.org_id,
        project_id: fileFound.project_id,
        file_id: fileFound.id,
      });
    }
  }, []);

  return (
    <>
      <div>
      {!file ? (
        <div>
          <LoadingPageAnimation />
        </div>): (
            <>
            <FileHeader file={file} />
            <div className={styles.dataView}>
              {file &&
                (type === "doc" ? (
                  <PdfView file={file as FileData} task={task}/>
                ) : (
                  <AudioView task={task} file={file as FileData} />
                ))}
            </div>
            <div className={styles.resultsView}></div>
            </>
        )}
      </div>
    </>
  );
};
