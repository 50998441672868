import { useMutation } from '@tanstack/react-query'
import { interract } from '../../api/chat'
import { PostParam } from '@/types'

export function useChatInteracte(props?: PostParam) {
    const { onSuccess, onError } = props || {};

    return useMutation({
        mutationFn: (data: any) => interract(data),
        onSuccess,
        onError
    })
}