import { useMutation } from '@tanstack/react-query'
import {getByOrgID} from "../../api/organizationApi"
import {GetDeleteOrgData,OrgData} from "@/types"
import {PostParam} from "@/types"

export function useGetByOrgID(props?: PostParam) {
    const { onSuccess, onError } = props || {};

    return useMutation<OrgData, Error, GetDeleteOrgData>({
        mutationFn: getByOrgID,
        onSuccess,
        onError
    })
}