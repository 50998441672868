import React, { useState, useRef, useEffect } from "react";
import KalSenseLogo from "@/assets/kalSenseLogo.png";
import KalSenseUserIcon from "@/assets/KalSenseUserIcon.png";
import KaleidooLogo from "@/assets/kaleidooLogo.svg";
import styles from "./css/Navbar.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearAll } from "@/utils/fetchData";
import { UserAvatar } from "../addons/UserAvatar";
import { RootState } from "@/store/store";
import { setChatState } from "@/store/chatSlice";
import LanguageSwitcher from '../Buttons/LanguageSwitcher';
import { useTranslation } from 'react-i18next';

interface NavbarProps {
  className?: string;
  type: "super_admin" | "user" | "logo" | "org_user";
}

const Navbar: React.FC<NavbarProps> = ({ type, className }) => {
  const { t, i18n } = useTranslation('Navbar');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const org = useSelector((state: RootState) => state.org.userOrg);
  const user = useSelector((state: RootState) => state.user.user);

  const avatarColor = {
    super_admin: "warning",
    org_user: "blue",
    user: "blue",
    logo: "",
  }[type];

  const handleClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setDropdownOpen(false);
    }
  };

  const handleLogoClick = () => {
    if(user.user_type !== ""){
      navigate(`/${org.org_name}/dashboard`)
      dispatch(setChatState({ 
        chatSearchFocus: "none", 
        isChatResMode: false, 
        toShowPrompt: true, 
        chatWaiting: false, 
        chatQuery: "",
        responseMessage: "",
        responseFilteredFiles: [],
      }));
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSettingsClick = () => {
    navigate(`/${org.org_name}/user/settings`);
  };

  const handleLogoutClick = () => {
    clearAll(dispatch);
    navigate(`/${org.org_name}/`);
  };

  return (
    <div className={`${styles.nav} ${className}`}>
      <div className={`${styles.logoContainer} ${user.user_type !== ""? styles.logoClickable: ''}`} onClick={() => handleLogoClick()}>
        <img src={KalSenseUserIcon} alt={t('KalSenseLogo')} className={styles.logo} />
      </div>
      <div className={styles.userContainer}>
        {["org_user", "super_admin"].includes(type) && (
          <div className={styles.orgContainer}>
            {type === "super_admin" || org.logo === "" ? (
              <img src={KaleidooLogo} className={styles.orgLogo}></img>
            ) : (
              org.logo && (
                <img
                  src={org.logo}
                  alt={KaleidooLogo}
                  className={styles.orgLogo}
                  onError={(e) => {
                    e.currentTarget.src = KaleidooLogo;
                  }}
                />
              )
            )}
            <div className={styles.orgName}>{org.org_name}</div>
          </div>
        )}
        {["org_user", "super_admin", "user"].includes(type) && (
          <div
            className={`${styles.user} ${avatarColor}`}
            onClick={handleClick}
            ref={dropdownRef}
          >
            <UserAvatar
              color={user.color || avatarColor}
              userImage={user.user_image}
            />
            {dropdownOpen && (
              <div className={styles.dropdownMenu}>
                <div
                  className={styles.dropdownItem}
                  onClick={handleSettingsClick}
                >
                  {t('Settings')}
                </div>
                <div
                  className={styles.dropdownItem}
                  onClick={handleLogoutClick}
                >
                  {t('Logout')}
                </div>
              </div>
            )}
          </div>
        )}
            <LanguageSwitcher />
      </div>
    </div>
  );
};

export default Navbar;
