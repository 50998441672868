import React from "react";

interface Props {
  className: string;
}

export const InProcessingIcon = ({ className }: Props): JSX.Element => {
  return (
    <svg
      width="98"
      height="93"
      viewBox="0 0 98 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className} 
    >
      <g clip-path="url(#clip0_2011_3479)">
        <path
          d="M31.4438 81.4062H62.5563C68.6213 81.4062 71.6388 78.33 71.6388 72.2363V45.635C71.6388 41.855 71.2288 40.215 68.8851 37.8125L52.7126 21.3775C50.4876 19.0913 48.6701 18.5938 45.3601 18.5938H31.4438C25.4088 18.5938 22.3613 21.6987 22.3613 27.7937V72.2363C22.3613 78.3588 25.4088 81.4062 31.4438 81.4062ZM31.6776 76.69C28.6601 76.69 27.0776 75.0775 27.0776 72.1488V27.8813C27.0776 24.9813 28.6601 23.31 31.7076 23.31H44.7151V40.3325C44.7151 44.0238 46.5901 45.84 50.2226 45.84H66.9226V72.1488C66.9226 75.0775 65.3688 76.69 62.3226 76.69H31.6776ZM50.7501 41.415C49.6076 41.415 49.1376 40.9475 49.1376 39.775V24.2188L66.0126 41.4162L50.7501 41.415Z"
          fill="white"
        />
        <path
          d="M34.9737 69C34.8457 69 34.7257 68.952 34.6137 68.856C34.5177 68.76 34.4697 68.64 34.4697 68.496C34.4697 68.432 34.4857 68.36 34.5177 68.28L40.3017 52.8C40.3498 52.64 40.4378 52.504 40.5658 52.392C40.7098 52.264 40.9018 52.2 41.1418 52.2H43.7578C43.9978 52.2 44.1818 52.264 44.3098 52.392C44.4378 52.504 44.5338 52.64 44.5978 52.8L50.3818 68.28C50.3978 68.36 50.4058 68.432 50.4058 68.496C50.4058 68.64 50.3578 68.76 50.2618 68.856C50.1658 68.952 50.0538 69 49.9258 69H47.8378C47.6138 69 47.4458 68.952 47.3338 68.856C47.2378 68.744 47.1738 68.64 47.1418 68.544L46.0138 65.616H38.8858L37.7578 68.544C37.7258 68.64 37.6538 68.744 37.5418 68.856C37.4458 68.952 37.2858 69 37.0618 69H34.9737ZM39.7258 62.856H45.1738L42.4378 55.44L39.7258 62.856ZM55.1424 69C54.9824 69 54.8464 68.944 54.7344 68.832C54.6224 68.72 54.5664 68.584 54.5664 68.424V52.776C54.5664 52.616 54.6224 52.48 54.7344 52.368C54.8464 52.256 54.9824 52.2 55.1424 52.2H57.3984C57.5744 52.2 57.7104 52.256 57.8064 52.368C57.9184 52.48 57.9744 52.616 57.9744 52.776V68.424C57.9744 68.584 57.9184 68.72 57.8064 68.832C57.7104 68.944 57.5744 69 57.3984 69H55.1424Z"
          fill="white"
        />
        <path
          d="M75.5169 79.2796C75.7197 78.1903 77.2803 78.1903 77.4831 79.2796L78.0227 82.1773C78.0984 82.5837 78.4163 82.9016 78.8227 82.9773L81.7204 83.5169C82.8097 83.7197 82.8097 85.2803 81.7204 85.4831L78.8227 86.0227C78.4163 86.0984 78.0984 86.4163 78.0227 86.8227L77.4831 89.7204C77.2803 90.8097 75.7197 90.8097 75.5169 89.7204L74.9773 86.8227C74.9016 86.4163 74.5837 86.0984 74.1773 86.0227L71.2796 85.4831C70.1903 85.2803 70.1903 83.7197 71.2796 83.5169L74.1773 82.9773C74.5837 82.9016 74.9016 82.5837 74.9773 82.1773L75.5169 79.2796Z"
          fill="white"
        />
        <path
          d="M14.0169 4.27958C14.2197 3.19033 15.7803 3.19033 15.9831 4.27958L17.3861 11.8139C17.4617 12.2203 17.7797 12.5383 18.1861 12.6139L25.7204 14.0169C26.8097 14.2197 26.8097 15.7803 25.7204 15.9831L18.1861 17.3861C17.7797 17.4617 17.4617 17.7797 17.3861 18.1861L15.9831 25.7204C15.7803 26.8097 14.2197 26.8097 14.0169 25.7204L12.6139 18.1861C12.5383 17.7797 12.2203 17.4617 11.8139 17.3861L4.27958 15.9831C3.19033 15.7803 3.19033 14.2197 4.27958 14.0169L11.8139 12.6139C12.2203 12.5383 12.5383 12.2203 12.6139 11.8139L14.0169 4.27958Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2011_3479">
          <rect width="98" height="93" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
