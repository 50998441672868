import { useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { useGetAllProject } from "@/hooks/project/useGetAllProject";
import { useGetProducts } from "@/hooks/product/useGetProducts";
import { useGetRoles } from "@/hooks/systemService/useGetRoles";
import { clearProjects, setProjects } from "@/store/projectsSlice";
import { clearOrg, setOrgs, setUserOrg } from "@/store/orgSlice";
import { clearUser, setUsers } from "@/store/userSlice";
import { clearSystemService, setProducts, setProjectTypes, setRoles } from "@/store/systemServiceSlice";
import { clearChat } from "@/store/chatSlice";
import { useGetAllProjectsTypes } from "@/hooks/product/useGetAllProjectsTypes";
import { useGetByOrgID } from "@/hooks/organization/useGetByOrgID";
import { useGetAllOrgs } from "@/hooks/organization/useGetAllOrg";
import { useGetAllUsers } from "@/hooks/user/useGetAllUsers";
import { useGetAllFiles } from "@/hooks/files/useGetAllFiles";
import { setAllFiles, setFiles } from "@/store/filesSlice";
import { ProjectData } from "@/types";

export const useFetchAllFiles = () => {
    const dispatch = useDispatch();

    const getAllFiles = useGetAllFiles({
        onSuccess(data) {
            dispatch(setAllFiles(data))
        },
        onError(error) {
            console.log(`Error fetching files: ${error.response.data}`)
        },
    })

    return getAllFiles;
}

export const useFetchAllProjects = () => {
    const dispatch = useDispatch();
    const getAllFiles = useFetchAllFiles();

    const getAllProjects = useGetAllProject({
        onSuccess(data) {
            dispatch(setProjects({projects: data, projectsFetched: true}))
            if(data.length > 0){
                let project_ids: string[] = []
                let org_id = ""
                data.forEach((project: ProjectData) => {
                    project_ids.push(project.id)
                    if(org_id == ""){
                        org_id = project.org_id
                    }
                });
                getAllFiles.mutate({project_ids, org_id})
            }
        },
        onError(error) {
            console.log(`Error fetching projects: ${error.response.data}`)
        },
    })

    return getAllProjects;
}

export const useFetchAllOrgs = () => {
    const dispatch = useDispatch();

    const getAllOrgs = useGetAllOrgs({
        onSuccess(data) {
            dispatch(setOrgs(data))
        },
        onError(error) {
            console.log(`Error fetching organizations: ${error.response.data}`)
        },
    })

    return getAllOrgs;
}

export const useFetchUserOrg = () => {
    const dispatch = useDispatch();

    const getOrg = useGetByOrgID({
        onSuccess(data) {
            dispatch(setUserOrg(data))
        },
        onError(error) {
            console.log(`Error fetching organization: ${error.response.data}`)
        },
    })

    return getOrg;
}

export const useFetchAllProducts = () => {
    const dispatch = useDispatch();

    const getAllProducts = useGetProducts({
        onSuccess(data) {
            dispatch(setProducts(data));
        },
        onError(error) {
            console.log(`Error fetching products: ${error.message}`);
        },
    });

    return getAllProducts;
};

export const useFetchAllProjectTypes = () => {
    const dispatch = useDispatch();

    const getProjectTypes = useGetAllProjectsTypes({
        onSuccess(data) {
            dispatch(setProjectTypes(data))
        },
        onError(error) {
            console.log(`Error fetching project types: ${error.response.data}`)
        },
    })

    return getProjectTypes;
}

export const useFetchRoles = () => {
    const dispatch = useDispatch();

    const getAllRoles = useGetRoles({
        onSuccess(data) {
            dispatch(setRoles(data))
        },
        onError(error) {
            console.log(`Error fetching project roles: ${error.response.data}`)
        },
    })

    return getAllRoles;
}

export const useFetchAllUsers = () => {
    const dispatch = useDispatch();

    const getAllUsers = useGetAllUsers({
        onSuccess(data) {
            dispatch(setUsers(data))
        },
        onError(error) {
            console.log(`Error fetching users: ${error.response.data}`)
        },
    })

    return getAllUsers;
}


export const clearAll = (dispatch: Dispatch) => {
    dispatch(clearOrg());
    dispatch(clearProjects());
    dispatch(clearUser());
    dispatch(clearSystemService());
    dispatch(clearChat());
}