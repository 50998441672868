import React, { useState } from "react";
import styles from "./css/UsersAttachments.module.css";
import { ClutchEmailField } from "@/components/inputFields/ClutchEmailField";
import { UserLink } from "@/components/links/UserLink";
import { EmailSuggestionObject } from "@/types";
import { useTranslation } from 'react-i18next';

interface UsersAttachmentsProps<T extends EmailSuggestionObject> {
  users: T[];
  selectedUsers: T[];
  handleRemoveUser: (user: T) => void;
  handleAddUser: (user: T) => boolean;
  className?: string;
  heightUsersListClass?: string;
}

export const UsersAttachments = <T extends EmailSuggestionObject>({
  users,
  selectedUsers,
  handleRemoveUser,
  handleAddUser,
  className,
  heightUsersListClass = ""
}: UsersAttachmentsProps<T>) => {
  const { t } = useTranslation('UsersAttachments');
  const [removingUser, setRemovingUser] = useState<string | null>(null);

  const handleRemove = (user: T) => {
    setRemovingUser(user.email);
    setTimeout(() => {
      handleRemoveUser(user);
      setRemovingUser(null);
    }, 500); // Match this duration with your CSS transition duration
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.fieldTile}>{t('Email')}</div>
      <ClutchEmailField
        handleAddUser={handleAddUser}
        suggestions={users}
        selectedEmails={selectedUsers}
      />
      <div className={`${styles.users} ${heightUsersListClass}`}>
        {selectedUsers.map((user) => (
          <UserLink
            link={user.user_name}
            onClick={() => handleRemove(user)}
            key={user.email}
            className={`${styles.userLink} ${removingUser === user.email ? styles.removing : ""}`}
          />
        ))}
      </div>
    </div>
  );
};
