import React from "react";
import styles from "./css/Link.module.css";
import { truncateText } from "@/utils/truncateText";

interface LinkProps {
  link: string;
  onClick: () => void;
  size?: "sm" | "lg";
  lgTruncate?: number;
  className?: string;
}

export const Link: React.FC<LinkProps> = ({
  link,
  size = "sm",
  lgTruncate = 90,
  onClick,
  className,
}) => {
  return (
    <div className={`${className} ${styles.container} ${size === 'sm'? styles.small: styles.large}`} onClick={onClick}>
      <div className={styles.link}>
        <p className={styles.text}>
          {size === "sm" ? truncateText(link, 42) : truncateText(link, lgTruncate)}
        </p>
      </div>
    </div>
  );
};
