import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setChatState } from "@/store/chatSlice";
import AllOrganizationsPage from "./AllOrganizationsPage";
import { UserProjectsPage } from "./UserProjectPage";
import { ProjectsPage } from "./ProjectsPage";
import { RootState } from "@/store/store";

export const Dashboard: React.FC = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setChatState({ 
      chatSearchFocus: "none", 
      isChatResMode: false, 
      toShowPrompt: true,
      chatQuery: "",
      responseMessage: "",
      responseFilteredFiles: [],
      }));
  }, [dispatch]);

  return (
    <>
      {user.user_type === "super_admin" ? (
        <AllOrganizationsPage />
      ) : user.user_type === "admin" ? (
        <ProjectsPage />
      ) : (
        <UserProjectsPage />
      )}
    </>
  );
};