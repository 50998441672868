import React from "react";

interface Props {
  className: string;
}

export const DocsIcon = ({ className }: Props): JSX.Element => {
  return (
    <svg
      viewBox="0 0 58 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.2213 71.8926H46.7785C53.7099 71.8926 57.1584 68.3769 57.1584 61.4127V31.0112C57.1584 26.6912 56.6899 24.8169 54.0113 22.0712L35.5285 3.28836C32.9856 0.675505 30.9085 0.106934 27.1256 0.106934H11.2213C4.32417 0.106934 0.841309 3.6555 0.841309 10.6212V61.4127C0.841309 68.4098 4.32417 71.8926 11.2213 71.8926ZM11.4885 66.5027C8.03988 66.5027 6.23131 64.6598 6.23131 61.3127V10.7212C6.23131 7.40693 8.03988 5.49693 11.5227 5.49693H26.3885V24.9512C26.3885 29.1698 28.5313 31.2455 32.6827 31.2455H51.7685V61.3127C51.7685 64.6598 49.9927 66.5027 46.5113 66.5027H11.4885ZM33.2856 26.1884C31.9799 26.1884 31.4427 25.6541 31.4427 24.3141V6.53551L50.7284 26.1898L33.2856 26.1884ZM41.3213 40.4526H15.907C14.7027 40.4526 13.8327 41.3569 13.8327 42.4955C13.8327 43.6669 14.7042 44.5712 15.9085 44.5712H41.3213C41.5951 44.5757 41.867 44.525 42.1209 44.4223C42.3747 44.3195 42.6053 44.1668 42.799 43.9732C42.9926 43.7795 43.1453 43.5489 43.2481 43.2951C43.3508 43.0412 43.4015 42.7693 43.397 42.4955C43.397 41.3569 42.4927 40.4526 41.3213 40.4526ZM41.3213 52.1384H15.907C14.7027 52.1384 13.8327 53.0755 13.8327 54.2469C13.8327 55.3855 14.7042 56.2569 15.9085 56.2569H41.3213C42.4927 56.2569 43.397 55.3855 43.397 54.2469C43.397 53.0755 42.4927 52.1384 41.3213 52.1384Z"
        fill="currentColor"
      />
    </svg>
  );
};
