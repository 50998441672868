import React, { useEffect } from 'react';
import { RootState } from "@/store/store";
import { useDispatch, useSelector } from "react-redux";
import { hideToast } from '@/store/systemSlice';
// Import your SVG components
import { ToastSuccess } from '@/icons/ToastSuccess';
import { ToastWarning } from '@/icons/ToastWarning';
import { ToastError } from '@/icons/ToastError';
import { ToastInfo } from '@/icons/ToastInfo';
import { ToastClose } from '@/icons/ToastClose';
import { ToastTypes } from '@/types';

interface Props {
  type: ToastTypes;
  className?: string;
  message: string;
  customTitle?: string;
}

const Toast: React.FC<Props> = ({ type, className, message, customTitle }) => {
  const dispatch = useDispatch();
  const isVisible = useSelector((state: RootState) => state.system.isToastVisible);

  useEffect(() => {
    if (message === 'init') {
      dispatch(hideToast());
    }

    const timer = setTimeout(() => {
      dispatch(hideToast());
    }, 5000);

    return () => clearTimeout(timer);
  }, [message, dispatch]);

  // Map type to the corresponding SVG component
  const icons: Record<string, React.ReactNode> = {
    success: <ToastSuccess />,
    warning: <ToastWarning />,
    error: <ToastError />,
    info: <ToastInfo />,
    custom: null,
  };

  const styles: Record<string, { borderColor: string, backgroundColor: string }> = {
    success: { borderColor: '#75DE85', backgroundColor: '#E6F4E7' },
    warning: { borderColor: '#FAC914', backgroundColor: '#FFF1DB' },
    error: { borderColor: '#FF3B3B', backgroundColor: '#FFE7E0' },
    info: { borderColor: '#4299E1', backgroundColor: '#EBF8FF' },
    custom: { borderColor: '#73738C', backgroundColor: '#73738C' },
  };

  const title: Record<string, string> = {
    success: 'Success',
    warning: 'Warning',
    error: 'Error',
    info: 'Info',
    custom: 'System',
  };

  const closeIconColor = type === 'custom' ? '#FFFFFF' : styles[type].borderColor;

  return (
    <>
      {isVisible && (
        <div
          className={`fixed bottom-12 right-5 w-[400px] shadow-standard h-[72px] overflow-hidden rounded-lg border-l-8 z-50 ${className}`}
          style={{ borderColor: styles[type].borderColor, backgroundColor: styles[type].backgroundColor }}
        >
          <div className="flex items-center h-full">
            <div className="text-2xl ml-4">{icons[type]}</div>
            <div className="ml-4 flex-grow">
              <div className="font-bold">{customTitle ?? title[type]}</div>
              <div>{message}</div>
            </div>
            <button onClick={() => dispatch(hideToast())} className="mr-4 text-gray-600">
              <ToastClose color={closeIconColor} />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Toast;
