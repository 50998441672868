import React from "react";
import { RootState } from "@/store/store";
import { useSelector, } from "react-redux";
import PromptCard from "../components/cards/PromptCard";
import SearchRes from "../components/pageParts/SearchRes";
import styles from "./css/UserProjectPage.module.css";
import { useTranslation } from 'react-i18next';



interface PagesProps {}

export const UserProjectsPage: React.FC<PagesProps> = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const chatState = useSelector((state: RootState) => state.chat);
  const { t } = useTranslation('UserProjectPage');


  return (
    <div className={styles.container}>
      {!chatState.isChatResMode ? (
        <div className={styles.mainContent}>
          <div className={styles.welcomeMessage}> {t("welcomeMessage")} {user.first_name}</div>
          <div className={styles.continueMessage}>
            {t("continueMessage")}
          </div>
          <div className={styles.promptCardContainer}>
            <PromptCard />
          </div>
        </div>
      ) : (
        <SearchRes />
      )}
    </div>
  );
};
