import secureLocalStorage from 'react-secure-storage';

export const getStringValue = (key: string): string | null => {
  const value = secureLocalStorage.getItem(key);
  if (value === null || value === undefined) return null;
  try {
    return JSON.parse(value as string);
  } catch {
    return value as string;
  }
};

export const getBooleanValue = (key: string): boolean => {
  const value = secureLocalStorage.getItem(key);
  if (value === null || value === undefined) return false;
  try {
    return JSON.parse(value as string) === true;
  } catch {
    return value === 'true';
  }
};

export function getStoredData<T>(key: string, defaultValue: T): T {
  const storedData = secureLocalStorage.getItem(key);
  if (storedData) {
    if (typeof storedData === 'string') {
      try {
        return JSON.parse(storedData) as T;
      } catch (error) {
        console.error(`Failed to parse ${key} from secure storage`, error);
        return defaultValue;
      }
    } else {
      console.warn(`Expected string in secure storage for key ${key}, but got ${typeof storedData}`);
    }
  }
  return defaultValue;
}
