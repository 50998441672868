import React from "react";

interface Props {
  className: string;
}

export const FolderIcon = ({ className }: Props): JSX.Element => {
  return (
    <svg
      width="75"
      height="75"
      viewBox="0 0 75 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M2.53116 18.1406L2.34366 14.0625C2.34366 11.5761 3.33138 9.19153 5.08953 7.43337C6.84769 5.67522 9.23225 4.6875 11.7187 4.6875H28.9312C31.4174 4.68803 33.8015 5.6761 35.5593 7.43437L39.4405 11.3156C41.1983 13.0739 43.5825 14.062 46.0687 14.0625H64.7343C66.037 14.0624 67.3255 14.3337 68.5175 14.8593C69.7096 15.3849 70.779 16.1532 71.6575 17.1151C72.5361 18.077 73.2045 19.2115 73.6202 20.4461C74.0359 21.6808 74.1897 22.9885 74.0718 24.2859L71.0858 57.0984C70.8742 59.4277 69.7995 61.5938 68.0727 63.1713C66.346 64.7489 64.0919 65.6241 61.753 65.625H13.2468C10.9079 65.6241 8.65383 64.7489 6.92708 63.1713C5.20034 61.5938 4.12564 59.4277 3.91397 57.0984L0.928034 24.2859C0.73227 22.1149 1.29915 19.9436 2.53116 18.1453V18.1406ZM10.2655 18.75C9.61437 18.75 8.97035 18.8856 8.37451 19.1482C7.77867 19.4109 7.24409 19.7948 6.80485 20.2755C6.3656 20.7562 6.03133 21.3232 5.82333 21.9402C5.61533 22.5572 5.53818 23.2109 5.59678 23.8594L8.58272 56.6719C8.68797 57.8365 9.22474 58.9198 10.0877 59.709C10.9506 60.4982 12.0774 60.9364 13.2468 60.9375H61.753C62.9224 60.9364 64.0492 60.4982 64.9121 59.709C65.7751 58.9198 66.3118 57.8365 66.4171 56.6719L69.403 23.8594C69.4616 23.2109 69.3845 22.5572 69.1765 21.9402C68.9685 21.3232 68.6342 20.7562 68.195 20.2755C67.7557 19.7948 67.2211 19.4109 66.6253 19.1482C66.0295 18.8856 65.3854 18.75 64.7343 18.75H10.2655ZM32.2499 10.7484C31.8142 10.3126 31.2968 9.96692 30.7273 9.73124C30.1578 9.49557 29.5475 9.37452 28.9312 9.375H11.7187C10.4907 9.37478 9.31175 9.85639 8.43519 10.7163C7.55863 11.5762 7.05449 12.7457 7.03116 13.9734L7.05928 14.625C8.06866 14.2531 9.13741 14.0656 10.2655 14.0625H35.5593L32.2499 10.7484Z"
        fill="currentColor"
      />
    </svg>
  );
};
