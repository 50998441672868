import React from "react";

interface Props {
  className: string;
}

export const KalChatText = ({ className }: Props): JSX.Element => {
  return (
    <svg
      width="85"
      height="16"
      viewBox="0 0 85 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.72 15H0.8V1.54H3.72V6.96H5.44C5.6 6.54 5.86 6.12 6.18 5.68L9.44 1.54H12.76L8.92 6.48C8.38 7.2 8.1 7.7 8.1 8.2C8.1 8.7 8.38 9.18 8.92 9.9L12.88 15H9.3L6.18 10.9C5.86 10.48 5.62 10.06 5.46 9.64H3.72V15ZM18.018 15.26C15.138 15.26 13.118 12.9 13.118 10.02C13.118 7.18 15.438 4.8 18.478 4.8C21.578 4.8 23.858 7.04 23.858 10.02V15H21.158V13.84C20.458 14.72 19.378 15.26 18.018 15.26ZM18.478 12.6C19.938 12.6 21.018 11.44 21.018 10.04C21.018 8.64 19.938 7.48 18.478 7.48C17.018 7.48 15.958 8.64 15.958 10.04C15.958 11.44 17.018 12.6 18.478 12.6ZM26.6352 0.539999H29.4752V15H26.6352V0.539999ZM44.3753 15.28C40.4553 15.28 37.3953 12.18 37.3953 8.28C37.3953 4.38 40.4553 1.28 44.3753 1.28C46.7153 1.28 48.9553 2.52 49.9553 4.62L47.5753 5.96C46.9153 4.8 45.8753 4.06 44.2553 4.06C42.0153 4.06 40.3953 5.84 40.3953 8.28C40.3953 10.72 41.9953 12.5 44.2553 12.5C45.8753 12.5 46.9553 11.76 47.5753 10.6L49.9553 11.94C48.9553 14.04 46.7153 15.28 44.3753 15.28ZM54.6705 15H51.8305V0.539999H54.6705V5.7C55.4105 5.16 56.3905 4.82 57.4705 4.82C60.4905 4.82 62.0705 6.74 62.0705 9.86V15L59.2305 14.98V10.14C59.2305 8.74 58.4705 7.5 57.0305 7.5C55.6105 7.5 54.6705 8.62 54.6705 10.18V15ZM68.8773 15.26C65.9973 15.26 63.9773 12.9 63.9773 10.02C63.9773 7.18 66.2973 4.8 69.3373 4.8C72.4373 4.8 74.7173 7.04 74.7173 10.02V15H72.0173V13.84C71.3173 14.72 70.2373 15.26 68.8773 15.26ZM69.3373 12.6C70.7973 12.6 71.8773 11.44 71.8773 10.04C71.8773 8.64 70.7973 7.48 69.3373 7.48C67.8773 7.48 66.8173 8.64 66.8173 10.04C66.8173 11.44 67.8773 12.6 69.3373 12.6ZM82.1945 15.28C79.3345 15.28 77.9145 13.56 77.9145 11.08V7.5H76.0945V5.1H77.9145V2.58H80.7545V5.1H84.2545V7.5H80.7545V11C80.7545 12.1 81.4145 12.74 82.4545 12.74C83.0345 12.74 83.7745 12.56 84.2545 12.26V14.8C83.7545 15.12 82.8945 15.28 82.1945 15.28Z"
        fill="white"
      />
    </svg>
  );
};
