import React, { useEffect, useState } from "react";
import styles from "./css/ProjectsPage.module.css";
import TabMenu from "@/components/tabs/TabMenue";
import CreateNewProjectCard from "@/components/cards/CreateNewProjectCard";
import ProjectCard from "@/components/cards/ProjectCard";
import dontHaveImage from "@/assets/dontHaveProduct.png";
import { MarketplaceButton } from "@/components/Buttons";
import { useSelector } from "react-redux";
import { LoadingPageAnimation } from "@/components/addons/LoadingPageAnimation";
import { ProductData } from "@/types";
import { useFetchAllProjects } from "@/utils/fetchData";
import { RootState } from "@/store/store";
import { useTranslation } from 'react-i18next';

interface PagesProps {}

type tabType = "all-projects" | "KalMedia" | "KalAudio" | "KalChat" | "KalDocs";

const showProuct = {
  KalMedia: "Kal Media",
  KalDocs: "Kal Docs",
  KalChat: "Kal Chat",
  KalAudio: "Kal Audio",
  "all-projects": "",
};

export const ProjectsPage: React.FC<PagesProps> = () => {
  const { t, i18n } = useTranslation('ProjectsPage');
  const projects = useSelector((state: RootState) => state.projects.projects);
  const projectsFetched = useSelector(
    (state: RootState) => state.projects.projectsFetched
  );
  const org = useSelector((state: RootState) => state.org.userOrg);
  const products = useSelector(
    (state: RootState) => state.systemService.products
  );
  const [productsList, setProductsList] = useState<ProductData[] | null>(null);
  const [selectedTab, setSelectedTab] = useState<tabType>("all-projects");
  const [filteredProjects, setFilteredProjects] = useState(projects);
  const [firstState, setFirstState] = useState(false);
  const user = useSelector((state: RootState) => state.user.user);

  const getAllProjects = useFetchAllProjects();

  const productAval: { [key: string]: boolean } = {
    "all-projects": true,
    ...Object.fromEntries(
      products.map((product) => [
        product.name,
        org?.products
          ? Object.prototype.hasOwnProperty.call(org.products, product.name)
          : false,
      ])
    ),
  };

  useEffect(() => {
    if (firstState) {
      setFirstState(false);
    } else {
      const filtered = projects.filter(
        (item) =>
          item.product_name === selectedTab || selectedTab === "all-projects"
      );
      setFilteredProjects(filtered);
    }
  }, [selectedTab, projects]);

  useEffect(() => {
    if (org) {
      const product_list: ProductData[] = products.filter((product) =>
        Object.keys(org.products).includes(product.name)
      );
      setProductsList(product_list);
    }
  }, [org, products]);

  useEffect(() => {
    const fetchProjects = () => {
      getAllProjects.mutate({ org_id: org.id });
    };

    fetchProjects(); // Initial fetch
    const intervalId = setInterval(fetchProjects, 60000);

    return () => clearInterval(intervalId);
  }, [org.id]);

  return (
    <>
      {org.id === "" || !projectsFetched || productsList === null ? (
        <LoadingPageAnimation />
      ) : (
        <div className={styles.container}>
          <div className={styles.tabs}>
            <TabMenu
              activeTab={selectedTab}
              onTabChange={setSelectedTab}
              productAvailability={productAval}
            />
          </div>
          <div className={styles.projects}>
            {productAval[selectedTab] ? (
              <div className={`${styles.customGrid}`}>
                {user.user_type === "admin" && (
                  <div className={styles.gridItem}>
                    <CreateNewProjectCard
                      type={selectedTab !== "all-projects" ? selectedTab : ""}
                      productsList={productsList}
                    />
                  </div>
                )}
                {filteredProjects.map((project) => (
                  <div className={styles.gridItem} key={project.id}>
                    <ProjectCard data={project} />
                  </div>
                ))}
              </div>
            ) : (
              <div className={`${styles.notAval}`}>
                <div className={styles.gradiantTop}></div>
                <div className={styles.notAvalMiddle}>
                  <div className={styles.notAvalText}>
                      {t('SorryDont')}
                    <div className={styles.notAvalProduct}>
                      {showProuct[selectedTab]}
                    </div>
                    {t('DontMiss')}
                  </div>
                  <div className={styles.notAvalTextBottom}>
                  {t('JoinWithGoogle')}
                  </div>
                  <div className={styles.marketplaceButton}>
                    <MarketplaceButton onClick={() => {}} />
                  </div>
                </div>
                <img
                  src={dontHaveImage}
                  alt=""
                  className={styles.dontHaveImage}
                ></img>
                <div className={styles.gradiantBottom}></div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
