import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import styles from "./css/FilesPage.module.css";
import { FileCard } from "@/components/cards/FileCard";
import { GridViewIcon } from "@/icons/GridView";
import { TableViewIcon } from "@/icons/TableView";
import FilesTable from "@/components/tables/FilesTable";
import { ProjectColors } from "@/types";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setFiles } from "@/store/filesSlice";
import { setChatState } from "@/store/chatSlice";
import { useFetchAllFiles } from "@/utils/fetchData";

export const FilesPage: React.FC = () => {
  const { files } = useSelector((state: RootState) => state.files);
  const dispatch = useDispatch();
  const allFiles = useSelector((state: RootState) => state.files.allFiles);
  const getAllFiles = useFetchAllFiles();
  const orgId = useSelector((state: RootState) => state.org.userOrg?.id);
  const chat = useSelector((state: RootState) => state.chat);
  const projects = useSelector((state: RootState) => state.projects.projects);
  const { type } = useParams<{ type: string }>();
  const allProjects = useSelector(
    (state: RootState) => state.projects.projects
  );
  const [view, setView] = useState<"grid" | "table">("grid");
  const colors = ["green", "pink", "yellow", "blue"];
  const randomColor = () => colors[Math.floor(Math.random() * colors.length)];
  const projectId = projects.length > 0 ? projects[0].id : "null";
  const mediaUrls = ["https://storage.googleapis.com/kal-media-dev-storage-bucket-thumbnail/cee9641a-e724-4d55-916b-5853ccfa3ddb.webp", "https://storage.googleapis.com/kal-media-dev-storage-bucket-thumbnail/37ef95a1-c1ae-477e-85a6-6b771b313198.webp", "https://storage.googleapis.com/kal-media-dev-storage-bucket-thumbnail/ce9ec687-de93-4930-bd17-e6192abded2a.webp", "https://storage.googleapis.com/kal-media-dev-storage-bucket-thumbnail/b5fb2440-cd7a-4a16-8652-5c8d02721c15.webp", "https://storage.googleapis.com/kal-media-dev-storage-bucket-thumbnail/057d19e8-efc5-47d8-a8ed-425685c847aa.webp", "https://storage.googleapis.com/kal-media-dev-storage-bucket-thumbnail/f7012933-c461-4df8-925d-7f4d07fca019.webp"]

  useEffect(() => {
    const productType = type === "doc" ? "KalDocs" : "KalAudio";
    console.log("chat.responseFilteredFiles", chat.responseFilteredFiles);
    if (chat.responseFilteredFiles && chat.responseFilteredFiles.length > 0) {
      dispatch(
        setFiles(
          chat.responseFilteredFiles.filter(
            (file) => file.product === productType
          )
        )
      );
    } else if (type === "doc") {
      dispatch(setFiles(allFiles.filter((file) => file.product === "KalDocs")));
      dispatch(setChatState({ chatSearchFocus: "doc" }));
    } else if (type === "audio") {
      dispatch(
        setFiles(allFiles.filter((file) => file.product === "KalAudio"))
      );
      dispatch(setChatState({ chatSearchFocus: "audio" }));
    } else if (type === "media") {
      dispatch(
        setFiles(allFiles.filter((file) => file.product === "KalMedia"))
      );
      dispatch(setChatState({ chatSearchFocus: "none" }));
    }
  }, [type, chat.responseFilteredFiles]);

  useEffect(() => {
    if (type === "doc") {
      dispatch(setFiles(allFiles.filter((file) => file.product === "KalDocs")));
      dispatch(setChatState({ chatSearchFocus: "doc" }));
    } else if (type === "audio") {
      dispatch(
        setFiles(allFiles.filter((file) => file.product === "KalAudio"))
      );
      dispatch(setChatState({ chatSearchFocus: "audio" }));
    } else if (type === "media") {
      dispatch(
        setFiles(allFiles.filter((file) => file.product === "KalMedia"))
      );
      dispatch(setChatState({ chatSearchFocus: "none" }));
    }
  }, [type]);

  const filesArray = Array.isArray(files) ? files : [];

  useEffect(() => {
    // Add interval to call getAllFiles every 30 seconds
    const interval = setInterval(() => {
      getAllFiles.mutate({
        project_id: projectId,
        org_id: orgId,
      });
    }, 10000);
    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [type, getAllFiles]);

  useEffect(() => {
    if (chat.responseFilteredFiles && chat.responseFilteredFiles.length > 0) {
      return;
    }
    if (type === "doc") {
      dispatch(setFiles(allFiles.filter((file) => file.product === "KalDocs")));
    } else if (type === "audio") {
      dispatch(
        setFiles(allFiles.filter((file) => file.product === "KalAudio"))
      );
    } else if (type === "media") {
      dispatch(
        setFiles(allFiles.filter((file) => file.product === "KalMedia"))
      );
    }
  }, [allFiles, chat.responseFilteredFiles]);

  return (
    <div className={styles.container}>
      <div className={styles.filterWrapper}>
        {type !== "media" && (
          <div className={styles.filter}>
            <div onClick={() => setView("grid")}>
              <GridViewIcon
                containerColor={`${styles.viewContainer} ${view === "grid" ? styles.selected : ""}`}
                gridColor={`${styles.viewIcon} ${view === "grid" ? styles.selected : ""}`}
                className={styles.view}
              />
            </div>
            <div onClick={() => setView("table")}>
              <TableViewIcon
                containerColor={`${styles.viewContainer} ${view === "table" ? styles.selected : ""}`}
                tableColor={`${styles.viewIcon} ${view === "table" ? styles.selected : ""}`}
                className={styles.view}
              />
            </div>
          </div>
        )}
      </div>
      {view === "grid" ? (
        type === "media" ? (
          <div className={styles.gridView}>
            {mediaUrls.map((url, index) => (
            <img 
              key={index}
              src={url}
              alt={`Media ${index + 1}`}
              className={styles.mediaItem}
            />
          ))}
            </div>
        ) : (
          <div className={styles.gridView}>
            {filesArray.map((file, index) => {
              const project = allProjects.find(
                (project) => project.id === file.project_id
              );
              return (
                <FileCard
                  key={index}
                  projectName={project?.name || "תמלול ועדה רפואית"}
                  projectColor={
                    project?.color || (randomColor() as ProjectColors)
                  }
                  data={file}
                />
              );
            })}
          </div>
        )
      ) : (
        <div className={styles.tableView}>
          <FilesTable data={filesArray} />
        </div>
      )}
    </div>
  );
};
