import React from "react";
import styles from "./css/ProjectHeader.module.css";
import kalAudioLogo from "@/assets/kalAudioHeader.svg";
import kalChatLogo from "@/assets/kalChatHeader.svg";
import kalDocsLogo from "@/assets/kalDocsHeader.svg";
import kalMediaLogo from "@/assets/kalMediaHeader.svg";
import { ProductTypes } from "@/types";

interface Props {
  type: ProductTypes;
  className?: string;
}

export const ProjectHeader: React.FC<Props> = ({ type, className }) => {
  return (
    <div
      className={`${styles.container} ${className} ${type === "KalChat" ? "bg-kalChat-default" : type === "KalAudio" ? "bg-kalAudio-default" : type === "KalDocs" ? "bg-kalDocs-default" : "bg-kalMedia-default"}`}
    >
      <img
        src={
          type === "KalAudio"
            ? kalAudioLogo
            : type === "KalChat"
              ? kalChatLogo
              : type === "KalDocs"
                ? kalDocsLogo
                : kalMediaLogo
        }
        className={`${styles.logo}`}
      ></img>
    </div>
  );
};
