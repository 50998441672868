import { useMutation } from '@tanstack/react-query';
import { updateProject } from "@/api/project"; 
import { UpdateProject, RetuenUpdateDeleteProject } from "@/types/project"; 
import { PostParam } from "@/types/index"; 

export function useUpdateProject(props?: PostParam) {
  const { onSuccess, onError } = props || {};

  return useMutation<RetuenUpdateDeleteProject, Error, UpdateProject>({
    mutationFn: updateProject,
    onSuccess,
    onError
  });
}
