import React, { useState } from 'react';
import styles from './css/PromptCard.module.css';
import prompt1 from '../../assets/prompt1.png';
import prompt2 from '../../assets/prompt2.png';
import prompt3 from '../../assets/prompt3.png';
import { Button } from "../Buttons";
import { useTranslation } from 'react-i18next';


interface Stage {
  title: string;
  description: React.ReactNode;
  buttonBackground: string;
}



const PromptCard: React.FC = () => {
  const [activeStage, setActiveStage] = useState(0);
  const { t } = useTranslation('PromptCard');

  const stages: Stage[] = [
    {
      title: t("titleDocuments"),
      description: t("descriptionDocuments"),
      buttonBackground: prompt1
    },
    {
      title: t("titleTranscription"),
      description: (
        <>
        {t("descriptionTranscription")}
        </>
      ),    buttonBackground: prompt2
    },
    {
      title: t("titleAllProjects"),
      description: t("descriptionAllProjects"),
      buttonBackground: prompt3
    }
  ];

  return (
    <div className={styles.cardContainer}>
      <div className={styles.backgroundGradient} style={{ backgroundImage: `url(${stages[activeStage].buttonBackground})` }} />
      <div className={styles.titleText}>{stages[activeStage].title}</div>
      <div className={styles.descriptionText}>{stages[activeStage].description}</div>
      <Button 
        className={styles.button }
        text={t("button")} 
        icon="right-side"
        iconColorType="stroke"
        onClick={() => {}}
      />
      <div className={styles.dotsContainer}>
        {stages.map((_, i) => (
          <div key={i} className={`${styles.dot} ${i === activeStage ? styles.dotActive : styles.dotInactive}`} onClick={() => setActiveStage(i)} />
        ))}
      </div>
    </div>
  );
};

export default PromptCard;
