import React from "react";
import { InputField } from "@/components/inputFields/inputFields";
import { CreateOrgData } from "@/types";
import styles from "./css/AdminUserFormCard.module.css";
import { useTranslation } from 'react-i18next';

interface Props {
  adminDetails: CreateOrgData;
  onAdminDetailsChange: (field: string, value: string) => void;
  errorMessage?: string;
}

const AdminUserFormCard: React.FC<Props> = ({ adminDetails, errorMessage, onAdminDetailsChange }) => {
  const { t } = useTranslation('AdminUserFormCard');
  const handleChange = (field: string) => (value: string) => {
    onAdminDetailsChange(field, value);
  };

  return (
    <div className={styles.formCard}>
      <div className={styles.formContent}>
        <div className={styles.buttonContainer}>
          {/* <Button icon="no" onClick={()=>{}} text="Create User" ready={true}/> */}
        </div>
        <div className={styles.formTitle}></div>
        <div className={`${styles.inputGroup} ${styles.inputGroupLeft} top-[33px]`}>
          <div className={styles.formLabel}>{t("userName")}</div>
          <div className={styles.inputContainer}>
            <InputField 
              data={adminDetails.user_name} 
              inputType="text" 
              setData={handleChange("user_name")} 
              id="admin_username"
            />
          </div>
        </div>
        <div className={`${styles.inputGroup} ${styles.inputGroupRight} top-[33px]`}>
          <div className={styles.formLabel}>{t("email")}</div>
          <div className={styles.inputContainer}>
            <InputField 
              data={adminDetails.admin_email} 
              inputType="text" 
              setData={handleChange("admin_email")} 
              errorMessage={errorMessage}
              id="admin_address"
            />
          </div>
        </div>
        <div className={`${styles.inputGroup} ${styles.inputGroupLeft} top-[117px]`}>
          <div className={styles.formLabel}>{t("firstName")}</div>
          <div className={styles.inputContainer}>
            <InputField 
              data={adminDetails.first_name} 
              inputType="text" 
              setData={handleChange("first_name")} 
              id="admin_first_name"
            />
          </div>
        </div>
        <div className={`${styles.inputGroup} ${styles.inputGroupRight} top-[117px]`}>
          <div className={styles.formLabel}>{t("lastName")}</div>
          <div className={styles.inputContainer}>
            <InputField 
              data={adminDetails.last_name} 
              inputType="text" 
              setData={handleChange("last_name")} 
              id="admin_last_name"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminUserFormCard;