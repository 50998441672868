import React from 'react';
import { useTranslation } from 'react-i18next';


const Footer: React.FC = () => {
  const { t, i18n } = useTranslation('Footer');

  return (
    <>
      <div className="flex justify-between items-center w-full px-8 py-2 bg-white shadow">
        <div className="font-bold text-black text-2xl leading-7 tracking-wider" style={{ fontFamily: "Poppins, sans-serif" }}>
          Kaleidoo
        </div>
        <div className="flex-grow text-center text-gray-400">
        {t('description')}
        </div>
        <div></div>
      </div>
    </>
  );
};

export default Footer;
