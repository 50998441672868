import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import store from "./store/store";
import "./index.css";
import { I18nextProvider } from 'react-i18next'; 
import i18next from './translations/i18n';

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <Provider store={store}>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18next}> 
          <App />
        </I18nextProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </Provider>
);