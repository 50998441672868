import { PostRequest ,PostRequestFormData } from "./requests";
import {CreateUser ,ReturnUpdateDeleteUser,UpdateUser,GatDeleteUser, GetAllUsers, UserData,GetUser} from "@/types"; 



export const createUser = async (data: CreateUser) => {
    try {
        return await PostRequestFormData("/api/user/create", data);
    } catch (error) {
        console.log('Error in createUser:', error);
        throw error;
    }
};

export const updateUser = async (data: UpdateUser) => {
    try {
        console.log('updateUser body:', data);
        return await PostRequestFormData("/api/user/update", data);
    } catch (error) {
        console.log('Error in updateUser:', error);
        throw error;
    }
};


export const deleteUser = async (data: GatDeleteUser): Promise<ReturnUpdateDeleteUser> => {
    try {
        return await PostRequest<ReturnUpdateDeleteUser>("/api/user/delete", data);
    } catch (error) {
        console.log('Error in deleteUser:', error);
        throw error;
    }
};

export const getAllUsers = async (data: GetAllUsers): Promise<UserData[]> => {
    try {
        return await PostRequest<UserData[]>("/api/user/get_all", data);
    } catch (error) {
        console.log('Error in getAllUser:', error);
        throw error;
    }
}

export const getUser = async (data: GetUser): Promise<UserData> => {
    try {
        return await PostRequest<UserData>("/api/user/get", data);
    } catch (error) {
        console.log('Error in getUser:', error);
        throw error;
    }
}

export const getUserById = async (data: GatDeleteUser): Promise<UserData> => {
    try {
        return await PostRequest<UserData>("/api/user/get_by_id", data);
    } catch (error) {
        console.log('Error in getUser:', error);
        throw error;
    }
}