import React from "react";
import styles from "./css/FieldLink.module.css";
import { LinkIcon } from "@/icons/LinkIcon";
import { truncateText } from "@/utils/truncateText";

interface FieldLinkProps {
  link: string;
  onClick: () => void;
  className?: string;
  size?: "lg" | "xl"
  truncate?: number | null
}

export const FieldLink: React.FC<FieldLinkProps> = ({
  link,
  onClick,
  className,
  size = "lg",
  truncate = null
}) => {

  const truncateDefault = truncate? truncate:  size === "xl"? 144: 126;
  return (
    <div className={`${className} ${styles.container} ${size === "xl"? styles.xl: ''}`} onClick={onClick}>
      <div className={`${styles.link} ${size === "xl"? styles.xl: ''}`}>
        <LinkIcon className={`${styles.icon}`} />
        <p className={styles.text}>
          {truncateText(link, truncateDefault)}
        </p>
      </div>
    </div>
  );
};
