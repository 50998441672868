import { useMutation } from '@tanstack/react-query';
import { updateUser } from "@/api/user"; 
import { ReturnUpdateDeleteUser,UpdateUser } from "@/types"; 
import { PostParam } from "@/types/index"; 

export function useUpdateUser(props?: PostParam) {
  const { onSuccess, onError } = props || {};

  return useMutation<ReturnUpdateDeleteUser, Error, UpdateUser>({
    mutationFn: updateUser,
    onSuccess,
    onError
  });
}