import { useMutation } from '@tanstack/react-query'
import {createOrg} from "../../api/organizationApi"
import {ReturnCreateOrg,CreateOrgData} from "@/types"
import {PostParam} from "@/types"

export function useCreateOrg(props?: PostParam) {
  const { onSuccess, onError } = props || {};

  const mutationFn = async (data: CreateOrgData) => {
    try {
      const result = await createOrg(data);
      if (onSuccess) onSuccess(result);
      return result;
    } catch (error) {
      if (onError) onError(error);
      throw error; // Throw the error to keep react-query's error handling
    }
  };

  return useMutation<ReturnCreateOrg, Error, CreateOrgData>({
    mutationFn,
  });
}
