import React from "react";

interface Props {
  className: string;
}

export const EnterIcon = ({ className }: Props): JSX.Element => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Frame" clipPath="url(#clip0_40_1089)">
        <path
          id="Vector"
          d="M5.48104 4.7063L24.5 14L5.48104 23.2936C5.37248 23.3354 5.25397 23.3439 5.14055 23.3181C5.02714 23.2924 4.92393 23.2335 4.84404 23.149C4.76181 23.0622 4.7052 22.9544 4.68049 22.8374C4.65578 22.7204 4.66393 22.5989 4.70404 22.4863L7.58337 14L4.70404 5.51363C4.66393 5.40102 4.65578 5.2795 4.68049 5.16253C4.7052 5.04557 4.76181 4.93773 4.84404 4.85097C4.92393 4.76644 5.02714 4.70757 5.14055 4.68181C5.25397 4.65605 5.37248 4.66458 5.48104 4.7063Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M7.5835 14H24.5002"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_40_1089">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
