import { PostRequest } from "./requests";
import { GetDeleteProjectData, ProjectData, GetAllProjec, CreateProject, RetuenCreateProject, UpdateProject, RetuenUpdateDeleteProject } from "@/types"; 

export const getProject = async (data: GetDeleteProjectData): Promise<ProjectData> => {
    try {
       return await PostRequest<ProjectData>("/api/project/get", data);
    } catch (error) {
        console.log('Error in getProject:', error);
        throw error;
    }
};

export const getAllProject = async (data: GetAllProjec): Promise<ProjectData[]> => {
    try {
        return await PostRequest<ProjectData[]>("/api/project/get_all", data);
    } catch (error) {
        console.log('Error in getAllProject:', error);
        throw error;
    }
};

export const getAllProjectByOrgID = async (data: GetAllProjec): Promise<ProjectData[]> => {
    try {
        return await PostRequest<ProjectData[]>("/api/project/get_projects_by_org_id", data);
    } catch (error) {
        console.log('Error in getAllProject:', error);
        throw error;
    }
};

export const createProject = async (data: CreateProject): Promise<RetuenCreateProject> => {
    try {
        return await PostRequest<RetuenCreateProject>("/api/project/create", data);
    } catch (error) {
        console.log('Error in createProject:', error);
        throw error;
    }
};

export const updateProject = async (data: UpdateProject): Promise<RetuenUpdateDeleteProject> => {
    try {
        return await PostRequest<RetuenUpdateDeleteProject>("/api/project/update", data);
    } catch (error) {
        console.log('Error in updateProject:', error);
        throw error;
    }
};

export const deleteProject = async (data: GetDeleteProjectData): Promise<RetuenUpdateDeleteProject> => {
    try {
        return await PostRequest<RetuenUpdateDeleteProject>("/api/project/delete", data);
    } catch (error) {
        console.log('Error in deleteProject:', error);
        throw error;
    }
};
