import React, { useState } from "react";
import styles from "./css/MarketplaceButton.module.css";
import { useTranslation } from 'react-i18next';

interface MarketplaceButtonProps {
  className?: string;
  onClick: () => void;
}

export const MarketplaceButton: React.FC<MarketplaceButtonProps> = ({
  className,
  onClick,
}) => {
  const { t, i18n } = useTranslation('MarketplaceButton');
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <div
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
      onClick={onClick}
      className={`${styles.button} ${
        isHovered ? styles.hover : styles.notHover
      } ${isHovered ? styles.hover : styles.notHover}`}
    >
      <div className={styles.text}>{t('GoogleMarketplace')}</div>
    </div>
  );
};
