import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setChatState } from "@/store/chatSlice";
import { RootState } from "@/store/store";
import ChatInput from "./ChatInput";
import ChatPrompt from "./ChatPrompt";
import ChatFocus from "./ChatFocus";
import { UserData } from "@/types";
import styles from "./css/ChatHeader.module.css";
import ChatTag from "./ChatTag";
import { useChatInteracte } from "@/hooks/chat/useChatInteract";

interface ChatHeaderProps {
  user: UserData;
}

const ChatHeader: React.FC<ChatHeaderProps> = ({ user }) => {
  const [inputValue, setInputValue] = useState("");
  const [isChatFocusVisible, setIsChatFocusVisible] = useState(false);
  const [promptTagMode, setPromptTagMode] = useState(false);
  const chat = useSelector((state: RootState) => state.chat);
  const orgId = useSelector((state: RootState) => state.org.userOrg.id);
  const projects = useSelector((state: RootState) => state.projects.projects);
  const allFiles = useSelector((state: RootState) => state.files.allFiles);
  const dispatch = useDispatch();
  const projectId = projects.length > 0 ? projects[0].id : "null";
  console.log("projectId", projectId)
  const chatInterract = useChatInteracte(
    {
      onSuccess: (data) => {
        const filteredFiles = allFiles.filter((file) => data.file_ids.includes(file.id));
        console.log("data", data.file_ids)
        console.log("filteredFiles", filteredFiles)
        dispatch(setChatState({
          responseMessage: data.answer,
          responseFilteredFiles: filteredFiles,
          toShowPrompt: false,
          isChatResMode: true,
          chatWaiting: false,
        }));
      },
      onError: (error) => {
        console.log("error", error)
        dispatch(setChatState({
          responseMessage: "We apologize, but we couldn't get an answer from the server. Please try again later.",
          responseFilteredFiles: [],
          toShowPrompt: false,
          isChatResMode: true,
          chatWaiting: false,
        }));
      }
    }
  );

  const [prompts, setPrompts] = useState([
    {
      id: "1",
      text: 'Documents In Which The Word "Manager" Was Said More Than Once',
      product: "doc"
    },
    {
      id: "2",
      text: "A Call From A Customer Who Wants To Disconnect Or Switch To A Competing Company",
      product: "audio"
    },
    { 
      id: "3", 
      text: "Calls Where The Call Time Is More Than 15 Minutes", 
      product: "audio" 
    },
    { 
      id: "4", 
      text: "A Customer Yelled During A Call", 
      product: "audio" 
    },
    {
      id: "5",
      text: 'Documents In Which The Word "Customer Service" Was Mentioned More Than Once',
      product: "doc"
    },
  ]);

  const handleCloseClick = () => {
    setIsChatFocusVisible(false); 
  };

  const handlePromptClick = (promptId: string, promptText: string) => {
    setPromptTagMode(true);
    setInputValue(promptText);
    // Move the onSubmit call to the next render cycle
    setTimeout(() => {
      onSubmit(promptText);
    }, 0);
  };

  const onSubmit = async (message: string) => {

      dispatch(setChatState({
        toShowPrompt: false,
        isChatResMode: true,
        chatWaiting: true,
        chatQuery: message,
        responseMessage: "",
        responseFilteredFiles: [],
      }));
    try {
      console.log({
        org_id: orgId,
        project_id: projectId,
        query: message
      })
      chatInterract.mutateAsync({
        org_id: orgId,
        project_id: projectId,
        query: message,
      });

      if(!promptTagMode) {
        setInputValue("");
      }

    } catch (error) {
      console.error("Error submitting message:", error);
    } finally {
      
    }
  };

  const handleSubmit = (message: string) => {
    onSubmit(message);
  };

  return (
    <>
      <div className={styles.headerFocus}>
      {isChatFocusVisible && (
          <ChatFocus onClose={handleCloseClick} />
        )}    
      </div>
      <div className={styles.headerContainer}>
      {chat.toShowPrompt && prompts.length > 0 && chat.chatSearchFocus !== "none" && (
        <div className={styles.promptsContainer}>
          {prompts
            .filter((prompt) => prompt.product === chat.chatSearchFocus)
            .map((prompt) => (
              <ChatPrompt
                key={prompt.id}
                prompt={prompt}
                onPromptClick={handlePromptClick}
              />
            ))}
        </div>
      )}
      <div className={styles.chatInputWrapper}>
        {promptTagMode ? (
            <ChatTag onClose={() => setPromptTagMode(false)} tagPrompt={inputValue}/>) : 
          (
            <ChatInput
              userName={user.first_name}
              inputValue={inputValue}
              setInputValue={setInputValue}
              onSubmit={handleSubmit}
              onFocus={setIsChatFocusVisible}
            />
        )}
      </div>
      </div>
    </>
  );
};

export default ChatHeader;
