import React from "react";
import styles from "./css/ProductTags.module.css";
import { ProductTypes } from "@/types";

interface Props {
  type: ProductTypes;
  className?: string;
  disabled?: boolean;
  short?: boolean;
}

export const ProductTag: React.FC<Props> = ({
  type,
  disabled,
  short,
  className,
}) => {
  const text = {
    KalAudio: "Kal Audio",
    KalChat: "Kal Chat",
    KalDocs: "Kal Doc",
    KalMedia: "Kal Media",
  }[type];

  const shortText = {
    KalAudio: "KA",
    KalChat: "KC",
    KalDocs: "KD",
    KalMedia: "KM",
  }[type];

  return (
    <div
      className={`${styles.container} ${className} ${disabled ? styles.disabled : ""} ${type === "KalChat" ? styles.kalChat : type === "KalAudio" ? styles.kalAudio : type === "KalDocs" ? styles.kalDocs : styles.kalMedia}`}
    >
      <div
        className={`${styles.text} ${disabled ? styles.disabled : ""}  ${type === "KalChat" ? styles.kalChat : type === "KalAudio" ? styles.kalAudio : type === "KalDocs" ? styles.kalDocs : styles.kalMedia}`}
      >
        {short ? shortText : text}
      </div>
    </div>
  );
};
