import React from "react";

interface Props {
  className: string;
}

export const CustomerIcon = ({ className }: Props): JSX.Element => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_2036_1525)">
        <path
          d="M18.1699 14.1597C18.5294 13.2868 18.7245 12.3523 18.7245 11.3254C18.7245 10.586 18.6115 9.8774 18.4164 9.22016C17.7489 9.3742 17.0506 9.45636 16.3215 9.45636C14.8283 9.45796 13.3566 9.10055 12.0304 8.41424C10.7043 7.72794 9.56258 6.73287 8.70156 5.51291C7.78138 7.74213 6.04355 9.53507 3.84413 10.5244C3.80306 10.7811 3.80306 11.0584 3.80306 11.3254C3.80306 12.3058 3.99617 13.2766 4.37136 14.1824C4.74656 15.0882 5.29649 15.9113 5.98975 16.6045C7.38987 18.0047 9.28884 18.7912 11.2689 18.7912C12.3472 18.7912 13.3844 18.555 14.3189 18.134C14.9043 19.2534 15.1713 19.8079 15.1507 19.8079C13.4666 20.3727 12.1623 20.65 11.2689 20.65C8.78371 20.65 6.41148 19.6744 4.66569 17.9183C3.60285 16.8605 2.81336 15.56 2.36534 14.1289H0.999512V9.45636H2.11888C2.45801 7.80557 3.23772 6.27737 4.37526 5.03394C5.51279 3.7905 6.96573 2.8782 8.5799 2.39385C10.1941 1.90949 11.9092 1.87115 13.5434 2.28288C15.1776 2.69462 16.6699 3.54108 17.8619 4.73244C19.1556 6.02184 20.0382 7.66567 20.3984 9.45636H21.5383V14.1289H21.4767L17.8208 17.487L12.378 16.8709V15.1559H17.3381L18.1699 14.1597ZM8.46536 11.0892C8.77345 11.0892 9.07126 11.2124 9.28691 11.4384C9.50365 11.6569 9.62526 11.9521 9.62526 12.2599C9.62526 12.5677 9.50365 12.863 9.28691 13.0815C9.07126 13.2971 8.77345 13.4203 8.46536 13.4203C7.81839 13.4203 7.29465 12.9069 7.29465 12.2599C7.29465 11.6129 7.81839 11.0892 8.46536 11.0892ZM14.0622 11.0892C14.7092 11.0892 15.2226 11.6129 15.2226 12.2599C15.2226 12.9069 14.7092 13.4203 14.0622 13.4203C13.4152 13.4203 12.8915 12.9069 12.8915 12.2599C12.8915 11.9494 13.0148 11.6516 13.2344 11.4321C13.4539 11.2125 13.7517 11.0892 14.0622 11.0892Z"
          fill="#D533FD"
          stroke="#FFE6FE"
          strokeWidth="0.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2036_1525">
          <rect
            width="22"
            height="22"
            fill="white"
            transform="translate(-0.000488281)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
