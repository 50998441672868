import React from "react";
import { LoginPage } from "./pages/LoginPage";
import Layout from "./routes/Layout";
import { Routes, Route } from "react-router-dom";
import { ProjectSettingsPage } from "./pages/ProjectSettingsPage";
import { ProductGuard } from "./routes/ProductGuard";
import OrganizationSettingPage from "./pages/OrganizationSettingPage";
import SettingPage from "./pages/SettingPage";
import { CreateProjectPage } from "./pages/CreateProjectPage";
import NewOrganizationsPage from "./pages/NewOrganizationPage";
import { Dashboard } from "./pages/Dashboard";
import ProtectedRoute from "./routes/ProtectedRoute";
import LoginRoute from "./routes/LoginRoute";
import { UsersPage } from "./pages/UsersPage";
import { UserSettingsPage } from "./pages/UserSettingsPage";
import Toast from "./components/addons/Toast";
import { useSelector } from "react-redux";
import { RootState } from "./store/store";
import { NotFoundPage } from "./pages/NotFoundPage";
import { ForbiddenPage } from "./pages/ForbiddenPage";
import ProtectedAccess from "./routes/ProtectedAccess";
import { Navigate } from "react-router-dom";
import { FileCard } from "./components/cards/FileCard";
import { FilesPage } from "./pages/FilesPage";
import { FilePage } from "./pages/FilePage";

const App: React.FC = () => {
  const toastMessage = useSelector((state: RootState) => state.system.toastMessage);
  const toastType = useSelector((state: RootState) => state.system.toastType);
  const toastTitle = useSelector((state: RootState) => state.system.toastTitle);


  return (
    <>
      <Routes>
        <Route element={<Layout />}>
          <Route element={<ProtectedAccess />}>
            <Route element={<ProtectedRoute />}>
              <Route
                path="/:orgName/:projectType/:projectId"
                element={
                  <ProductGuard>
                    <div>Some Product Link</div>
                  </ProductGuard>
                }
              />
              <Route
                path="/:orgName/project/settings/:projectType/:projectId"
                element={
                  <ProductGuard>
                    <ProjectSettingsPage />
                  </ProductGuard>
                }
              />
              <Route
                path="/:orgName/project/create/:projectType"
                element={
                  <ProductGuard>
                    <CreateProjectPage />
                  </ProductGuard>
                }
              />
              <Route path="/:orgName/dashboard" element={<Dashboard />} />
              <Route
                path="/:orgName/organization/settings/:orgId"
                element={<OrganizationSettingPage />}
              />
              <Route path="/:orgName/settings" element={<SettingPage />} />
              <Route
                path="/:orgName/user/settings"
                element={<UserSettingsPage />}
              />
              <Route
                path="/:orgName/organization/create"
                element={<NewOrganizationsPage />}
              />
              <Route path="/:orgName/users" element={<UsersPage />} />
              <Route path="/:orgName/:type/files" element={<FilesPage/>}/>
              <Route path="/:orgName/:type/file/:fileId" element={<FilePage/>} />
            </Route>
          </Route>
          <Route element={<LoginRoute />}>
            {/* <Route path="/" element={<LoginPage />} /> */}
            <Route path="/:orgName" element={<LoginPage />} />
          </Route>
          <Route path="/404" element={<NotFoundPage />} />
          <Route path="/403" element={<ForbiddenPage />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Route>
      </Routes>
      <Toast
        key={Date.now()}
        type={toastType}
        message={toastMessage}
        customTitle={toastTitle}
        className="fixed right-5 z-50"
      />
    </>
    // TODO ADD PROTECTED
  );
};

export default App;
