import { PostRequest } from "./requests";
import {CreateDeleteUserProject,ReturnCreateDelete,GetAllProjectUsers,RetuenGetAllProjectUsers,GetAllUserProjects,RetuenGetAllUserProjects, UpdateUserProjectsList, UpdateProjectUsersList} from "@/types"; 


export const createUserProject = async (data: CreateDeleteUserProject): Promise<ReturnCreateDelete> => {
    try {
        return await PostRequest<ReturnCreateDelete>("/api/user_project/create", data);
    } catch (error) {
        console.log('Error in createUserProject:', error);
        throw error;
    }
};

export const deleteUserProject = async (data: CreateDeleteUserProject): Promise<ReturnCreateDelete> => {
    try {
        return await PostRequest<ReturnCreateDelete>("/api/user_project/delete", data);
    } catch (error) {
        console.log('Error in deleteUserProject:', error);
        throw error;
    }
};

export const getAllProjectUsers = async (data: GetAllProjectUsers): Promise<RetuenGetAllProjectUsers> => {
    try {
        return await PostRequest<RetuenGetAllProjectUsers>("/api/user_project/get_all_project_users", data);
    } catch (error) {
        console.log('Error in getAllProjectUsers:', error);
        throw error;
    }
};

export const getAllUserProjects = async (data: GetAllUserProjects): Promise<RetuenGetAllUserProjects> => {
    try {
        return await PostRequest<RetuenGetAllUserProjects>("/api/user_project/get_all_user_projects", data);
    } catch (error) {
        console.log('Error in getAllUserProjects:', error);
        throw error;
    }
};

export const changeUserProjectsList = async (data: UpdateUserProjectsList): Promise<ReturnCreateDelete> => {
    try {
        return await PostRequest<ReturnCreateDelete>("/api/user_project/change_user_projects", data);
    } catch (error) {
        console.log('Error in changeUserProjectsList:', error);
        throw error;
    }
};

export const changeProjectUsersList = async (data: UpdateProjectUsersList): Promise<ReturnCreateDelete> => {
    try {
        return await PostRequest<ReturnCreateDelete>("/api/user_project/change_project_users", data);
    } catch (error) {
        console.log('Error in changeProjectUsersList:', error);
        throw error;
    }
};