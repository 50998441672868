import { useMutation } from '@tanstack/react-query';
import { getAllUserProjects } from "@/api/userProject"; 
import { GetAllUserProjects,RetuenGetAllUserProjects} from "@/types"; 
import { PostParam } from "@/types/index"; 

export function useGetAllUserProjects(props?: PostParam) {
  const { onSuccess, onError } = props || {};

  return useMutation<RetuenGetAllUserProjects, Error, GetAllUserProjects>({
    mutationFn: getAllUserProjects,
    onSuccess,
    onError
  });
}