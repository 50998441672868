import React from "react";

interface Props {
  color?: string;
}

export const Resizer = ({ color = "#262626" }: Props): JSX.Element => {
  return (
    <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Resizer"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0L8.35355 0.353553L0.353554 8.35355L0 8L8 0ZM8.35355 4.35355L4.35355 8.35355L4 8L8 4L8.35355 4.35355Z"
        fill={color}
      />
    </svg>
  );
};
