import { useMutation } from '@tanstack/react-query';
import { getAllUsers } from "@/api/user"; 
import { GetAllUsers, UserData } from "@/types"; 
import { PostParam } from "@/types/index"; 

export function useGetAllUsers(props?: PostParam) {
  const { onSuccess, onError } = props || {};

  return useMutation<UserData[], Error, GetAllUsers>({
    mutationFn: getAllUsers,
    onSuccess,
    onError
  });
}