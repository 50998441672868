import React, { useState } from "react";
import { User } from "@/icons/User";
import styles from "./css/UserAvatar.module.css";

interface UserAvatarProps {
  color: string;
  userImage?: string;
}

export const UserAvatar: React.FC<UserAvatarProps> = ({ color, userImage }) => {
  const [imgError, setImgError] = useState(false);

  return (
    <div className={`${styles.user} ${styles[color]}`}>
      {userImage !== null && !imgError ? (
        <img
          src={userImage}
          alt="User"
          className={styles.userImage}
          onError={() => setImgError(true)}
        />
      ) : (
        <User className={styles.userIcon} />
      )}
    </div>
  );
};
