import React from "react";

interface Props {
  color?: string;
}

export const ToastError = ({ color = "#D12E2E" }: Props): JSX.Element => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame" clipPath="url(#clip0_1857_8915)">
        <path
          id="Vector"
          d="M16 0L16.5184 0.00159988L17.0272 0.00800018L18.0128 0.0352001L18.4912 0.0560001L19.4176 0.1104L20.3024 0.184C27.9584 0.9264 31.0736 4.0416 31.816 11.6976L31.8896 12.5824L31.944 13.5088C31.952 13.6656 31.96 13.8256 31.9648 13.9872L31.992 14.9728L32 16L31.992 17.0272L31.9648 18.0128L31.944 18.4912L31.8896 19.4176L31.816 20.3024C31.0736 27.9584 27.9584 31.0736 20.3024 31.816L19.4176 31.8896L18.4912 31.944C18.3344 31.952 18.1744 31.96 18.0128 31.9648L17.0272 31.992L16 32L14.9728 31.992L13.9872 31.9648L13.5088 31.944L12.5824 31.8896L11.6976 31.816C4.0416 31.0736 0.9264 27.9584 0.184 20.3024L0.1104 19.4176L0.0560001 18.4912C0.0482164 18.3318 0.0412829 18.1723 0.0352001 18.0128L0.00800018 17.0272C0.00320018 16.6912 0 16.3488 0 16L0.00159988 15.4816L0.00800018 14.9728L0.0352001 13.9872L0.0560001 13.5088L0.1104 12.5824L0.184 11.6976C0.9264 4.0416 4.0416 0.9264 11.6976 0.184L12.5824 0.1104L13.5088 0.0560001C13.6656 0.0480001 13.8256 0.0400001 13.9872 0.0352001L14.9728 0.00800018C15.3088 0.00320018 15.6512 0 16 0ZM13.6176 11.424C13.2824 11.2244 12.8858 11.1542 12.5024 11.2266C12.1191 11.299 11.7753 11.5089 11.5359 11.8169C11.2965 12.125 11.1779 12.5098 11.2023 12.8992C11.2268 13.2886 11.3927 13.6556 11.6688 13.9312L13.736 16L11.6688 18.0688L11.536 18.2192C11.2873 18.5408 11.1704 18.945 11.209 19.3496C11.2475 19.7543 11.4387 20.1291 11.7436 20.398C12.0485 20.6669 12.4443 20.8096 12.8506 20.7972C13.2569 20.7848 13.6433 20.6182 13.9312 20.3312L16 18.264L18.0688 20.3312L18.2192 20.464C18.5408 20.7127 18.945 20.8296 19.3496 20.791C19.7543 20.7525 20.1291 20.5613 20.398 20.2564C20.6669 19.9515 20.8096 19.5557 20.7972 19.1494C20.7848 18.7431 20.6182 18.3567 20.3312 18.0688L18.264 16L20.3312 13.9312L20.464 13.7808C20.7127 13.4592 20.8296 13.055 20.791 12.6504C20.7525 12.2457 20.5613 11.8709 20.2564 11.602C19.9515 11.3331 19.5557 11.1904 19.1494 11.2028C18.7431 11.2152 18.3567 11.3818 18.0688 11.6688L16 13.736L13.9312 11.6688L13.7808 11.536L13.6176 11.424Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1857_8915">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
