export const timeStringToSeconds = (time: string): number => {
    const [hours, minutes, seconds, milliseconds] = time.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds + (milliseconds || 0) / 1000;
  };
  
  export const secondsToTimeString = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    const milliseconds = Math.floor((seconds % 1) * 1000);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}:${milliseconds.toString().padStart(3, '0')}`;
  };