import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import React, { useEffect, useMemo, useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  ColumnDef,
  flexRender,
  SortingState,
} from "@tanstack/react-table";
import styles from "./css/UserProjectsTable.module.css";
import { ProductTag } from "../tags/ProductTags";
import { Link } from "../links/Link";
import { ProductData, ProductTypes, ProjectData } from "@/types";
import { LinkIcon } from "@/icons/LinkIcon";
import { LinkUnattachedIcon } from "@/icons/LinkUnattachedIcon";
import SelectField from "../inputFields/SelectField";
import { Button } from "@/components/Buttons";
import { LinkPlus } from "@/icons/LinkPlus";
import { useSelector } from "react-redux";
import { formatDate } from "@/utils/date";
import { LoadingPageAnimation } from "../addons/LoadingPageAnimation";
import { RootState } from "@/store/store";

interface UserProjectsTableProps {
  className?: string;
  data: ProjectData[];
  setData: React.Dispatch<React.SetStateAction<ProjectData[]>>;
  fetched: boolean;
  setNeededFetch: (value: boolean) => void;
}

export const UserProjectsTable: React.FC<UserProjectsTableProps> = ({
  className,
  data,
  setData,
  fetched,
  setNeededFetch,
}) => {
  const projects = useSelector((state: RootState) => state.projects.projects);
  const [product, setProduct] = useState<ProductData>();
  const [project, setProject] = useState<ProjectData>();
  const [initialData, setInitialData] = useState(data);
  const products = useSelector(
    (state: RootState) => state.systemService.products
  );
  const org = useSelector((state: RootState) => state.org.userOrg);
  const [productsList, setProductsList] = useState<ProductData[]>();

  const arraysEqual = (arr1: ProjectData[], arr2: ProductData[]) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    return (
      arr1.every((item1) => arr2.some((item2) => item1.id === item2.id)) &&
      arr2.every((item2) => arr1.some((item1) => item2.id === item1.id))
    );
  };

  useEffect(() => {
    if (fetched) {
      setInitialData(data);
    }
  }, [fetched]);

  useEffect(() => {
    if (arraysEqual(data, initialData)) {
      setNeededFetch(false);
    } else {
      setNeededFetch(true);
    }
  }, [data]);

  useEffect(() => {
    if (org) {
      const product_list: ProductData[] = products.filter((product) =>
        Object.keys(org.products).includes(product.name)
      );
      setProductsList(product_list);
    }
  }, [org]);

  const filteredProjects = useMemo(() => {
    if (!product) {
      setProject(undefined);
      return [];
    }
    return projects.filter(
      (proj) =>
        proj.product_name === product.name &&
        !data.find((userProject) => userProject.id === proj.id)
    );
  }, [product, projects, data]);

  const [unlinkingProjects, setUnlinkingProjects] = useState<Set<string>>(
    new Set()
  );

  const handleAttachProject = () => {
    if (project) {
      setUnlinkingProjects((prev) => {
        const newSet = new Set(prev);
        newSet.delete(project.id);
        return newSet;
      });
      setData((prevArray) => [...prevArray, project]);
      setProduct(undefined);
    }
  };

  const handleUnlink = (projectId: string) => {
    setUnlinkingProjects((prev) => new Set(prev).add(projectId));
    setTimeout(() => {
      setData((prevArray) => prevArray.filter((proj) => proj.id !== projectId));
      setUnlinkingProjects((prev) => {
        const newSet = new Set(prev);
        newSet.delete(projectId);
        return newSet;
      });
    }, 500); // Duration of the animation
  };

  const columns = useMemo<ColumnDef<ProjectData>[]>(
    () => [
      {
        accessorKey: "product_name",
        header: "Product",
        cell: ({ getValue }) => (
          <div className={`${styles.productColumn}`}>
            <ProductTag type={getValue() as ProductTypes} short={true} />
          </div>
        ),
      },
      {
        accessorKey: "name",
        header: "Project Name",
        cell: ({ getValue }) => {
          const value = getValue<string>();
          return <div className={styles.projectColumn}>{value}</div>;
        },
      },
      {
        accessorKey: "created_at",
        header: "Date",
        cell: ({ getValue }) => {
          const value = formatDate(getValue<string>());
          return <div className={styles.dateColumn}>{value}</div>;
        },
      },
      {
        accessorKey: "link",
        header: "Link To Project",
        cell: ({ getValue }) => {
          const value = getValue<string>();
          return (
            <div className={styles.linkColumn}>
              <Link link={value} onClick={() => {}} />
            </div>
          );
        },
      },
      {
        accessorKey: "attach",
        header: "Attach",
        cell: ({ row }) => {
          const handleUnlinkClick = () => handleUnlink(row.original.id);

          return (
            <div className={styles.attachColumn}>
              <div onClick={handleUnlinkClick}>
                {!unlinkingProjects.has(row.original.id) ? (
                  <LinkIcon
                    className={`${styles.icon}`}
                    key={row.original.id}
                  />
                ) : (
                  <LinkUnattachedIcon
                    className={`${styles.icon} ${styles.disappearing}`} // Apply the disappearing class
                    key={row.original.id}
                  />
                )}
              </div>
            </div>
          );
        },
      },
    ],
    [unlinkingProjects]
  );

  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div className={styles.container}>
      <div className={`${styles.tableContainer} ${className}`}>
        <table className={styles.table}>
          <thead className={styles.tableHeadersRow}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={`${styles.headerText} ${
                      header.id === "product_name" ? styles.productColumn : ""
                    } ${header.id === "name" ? styles.projectColumn : ""} ${
                      header.id === "created_at" ? styles.dateColumn : ""
                    } ${header.id === "link" ? styles.linkColumn : ""} ${
                      header.id === "attach" ? styles.attachColumn : ""
                    }`}
                    onClick={
                      header.id !== "attach"
                        ? header.column.getToggleSortingHandler()
                        : () => {}
                    }
                  >
                    <div className="flex items-center">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {header.id !== "attach" && (
                        <span className="ml-2">
                          {{
                            asc: <AiOutlineArrowUp className="text-black" />,
                            desc: <AiOutlineArrowDown className="text-black" />,
                            undefined: (
                              <AiOutlineArrowUp className="text-black" />
                            ),
                          }[header.column.getIsSorted() as string] ?? (
                            <AiOutlineArrowUp className="text-black" />
                          )}
                        </span>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {fetched ? (
            <tbody className={styles.bodyRow}>
              {table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  className={`${styles.rowHover} ${unlinkingProjects.has(row.original.id) ? styles.disappearing : ""}`}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className={`${styles.rowText} ${
                        cell.column.id === "project_name"
                          ? styles.projectName
                          : ""
                      }`}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          ) : (
            <LoadingPageAnimation />
          )}
        </table>
      </div>
      <div className={styles.add}>
        <div className={styles.addText}>Attach A Project</div>
        <div className={styles.selects}>
          <SelectField
            data={productsList || []}
            selectedValue={product as ProductData}
            updateFunction={setProduct}
            size="xs"
            placeholder="Product"
          />
          <SelectField
            data={filteredProjects}
            selectedValue={project as ProjectData}
            updateFunction={setProject}
            size="xs"
            placeholder="Select Project"
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            icon="right-side"
            text="Attach"
            iconComponent={LinkPlus}
            iconColorType="text"
            onClick={() => handleAttachProject()}
            disabled={project === undefined}
          />
        </div>
      </div>
    </div>
  );
};
