import React, { useState } from "react";
import styles from "./css/UserSettingsPage.module.css";
import { UploadPictureCard } from "@/components/cards/UploadPictureCard";
import { InputField } from "@/components/inputFields/inputFields";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@/components/Buttons";
import { useUpdateUser } from "@/hooks/user/useUpdateUser";
import { UpdateUser } from "@/types";
import { RootState } from "@/store/store";
import { setToast } from "@/store/systemSlice";
import { setUserObject } from "@/store/userSlice";
import { useFetchAllUsers } from "@/utils/fetchData";
import { useGetUserById } from "@/hooks/user/useGetUserById";
import { useTranslation } from 'react-i18next';


export const UserSettingsPage: React.FC = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const { t } = useTranslation('UserSettingsPage');
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const dispatch = useDispatch();
  const getUser = useGetUserById({
    onSuccess(data) {
      dispatch(setUserObject(data));
      setUserData((prev) => ({
        ...prev,
        user_name: "",
        user_image: null
      }))
    },
  });
  const getAllUsers = useFetchAllUsers();

  const [userData, setUserData] = useState<UpdateUser>({
    user_id: user.id,
    org_id: user.org_id,
    first_name: user.first_name || "",
    last_name: user.last_name || "",
    role_id: user.role_id || "",
    user_name: null || "",
    user_image: null,
    status: user.status as "Active" | "On Hold",
    upload_image_action: "none",
  });

  const updateUserMutation = useUpdateUser({
    onSuccess: () => {
      setLoadingUpdate(false);
      getUser.mutate({
        org_id: user.org_id,
        user_id: userData.user_id as string,
      });
      if (user.user_type === "admin") {
        getAllUsers.mutate({
          org_id: user.org_id as string,
        });
      }

      dispatch(
        setToast({
          toastMessage: "",
          toastTitle: "User updated successfully",
          toastType: "success",
        })
      );
    },
    onError: (error) => {
      setLoadingUpdate(false);
      dispatch(
        setToast({
          toastMessage: error.response.data.message,
          toastTitle: "Failed to update user",
          toastType: "error",
        })
      );
    },
  });

  const handleChange = (field: string, value: string) => {
    if (field === "user_name" && value === user.user_name) {
      setUserData((prev) => ({
        ...prev,
        user_name: undefined,
      }));
    } else {
      setUserData((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
  };

  const handleFileChange = (file: File | null) => {
    setUserData((prevData) => ({
      ...prevData,
      user_image: file,
    }));
  };

  const handleUpdate = () => {
    setLoadingUpdate(true);
    updateUserMutation.mutate(userData);
  };

  return (
    <div className={styles.container}>
      <div className={styles.settings}>
        <div className={styles.title}>{t('title')}</div>
        <div className={styles.row}>
          <div className={styles.column}>
            <div className={styles.text}>{t('text')}</div>
            <UploadPictureCard
              isOrgUpload={false}
              file={userData.user_image as File | null}
              setFile={handleFileChange}
              existingImageUrl={user.user_image}
              setAction={(value) => handleChange("upload_image_action", value)}
            />
          </div>
          <div className={styles.column}>
            <label htmlFor="firstName" className={styles.text}>
            {t('firstName')}
            </label>
            <InputField
              id="firstName"
              inputType="text"
              data={userData.first_name as string}
              setData={(value) => handleChange("first_name", value)}
            />
            <div className={styles.gap}></div>
            <label htmlFor="userName" className={styles.text}>
            {t('userName')}
            </label>
            <InputField
              data={userData.user_name || user.user_name}
              setData={(value) => handleChange("user_name", value)}
              inputType="text"
              id="username"
              disabled={user.user_type === "super_admin"}
            />
          </div>
          <div className={styles.column}>
            <label htmlFor="lastName" className={styles.text}>
            {t('lastName')}
            </label>
            <InputField
              id="lastName"
              inputType="text"
              data={userData.last_name || ""}
              setData={(value) => handleChange("last_name", value)}
            />
            <div className={styles.gap}></div>
            <label htmlFor="userEmail" className={styles.text}>
            {t('email')}
            </label>
            <InputField
              id="userEmail"
              inputType="text"
              data={user.email}
              setData={(value) => handleChange("email", value)}
              disabled={true}
            />
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            loading={loadingUpdate}
            ready={true}
            text={t('button')}
            icon="no"
            onClick={handleUpdate}
          />
        </div>
      </div>
    </div>
  );
};
