// src/store/userSlice.ts
import { createSlice } from '@reduxjs/toolkit';
import secureLocalStorage from 'react-secure-storage';
import { getStoredData } from '@/utils/store';
import { FileData } from '@/types';

interface FilesState {
  allFiles: FileData[]
  files: FileData[]
}

  const initialState: FilesState = {
    allFiles: getStoredData<FileData[]>('allFiles', []),
    files: getStoredData<FileData[]>('files', []),

  };

const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    setAllFiles: (state, action) => {
        state.allFiles = action.payload;
  
        secureLocalStorage.setItem('allFiles', JSON.stringify(action.payload) || '');
    },
    setFiles: (state, action) => {
      state.files = action.payload;

      secureLocalStorage.setItem('files', JSON.stringify(action.payload) || '');
    },
    clearFiles: (state) => {
      state.files = [];
      state.allFiles = [];

      secureLocalStorage.removeItem('allFiles');
      secureLocalStorage.removeItem('files');
    },
  },
});

export const { setAllFiles, setFiles, clearFiles } = filesSlice.actions;
export default filesSlice.reducer;
