import React from "react";

interface Props {
  className: string;
}

export const CreateOrgIcon = ({ className }: Props): JSX.Element => {
  return (
    <svg
      width="264"
      height="40"
      viewBox="0 0 264 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g opacity="0.16" clipPath="url(#clip0_1509_7632)">
        <path
          d="M5 35H35"
          stroke="#1099FE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 16.6667H35"
          stroke="#1099FE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.33337 10L20 5L31.6667 10"
          stroke="#1099FE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.66663 16.6667V35"
          stroke="#1099FE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.3334 16.6667V35"
          stroke="#1099FE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3334 23.3333V28.3333"
          stroke="#1099FE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 23.3333V28.3333"
          stroke="#1099FE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.6666 23.3333V28.3333"
          stroke="#1099FE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.16" clipPath="url(#clip1_1509_7632)">
        <path
          d="M77.6667 35V23.3333L69.3333 15L61 23.3333V35H69.3333M77.6667 35H69.3333M77.6667 35H91V6.66667C91 6.22464 90.8244 5.80072 90.5118 5.48816C90.1993 5.17559 89.7754 5 89.3333 5H72.6667C72.2246 5 71.8007 5.17559 71.4882 5.48816C71.1756 5.80072 71 6.22464 71 6.66667V16.6667M69.3333 35V28.3333"
          stroke="#1099FE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M77.6666 11.6667V11.685"
          stroke="#1099FE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M84.3334 11.6667V11.685"
          stroke="#1099FE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M84.3334 18.3333V18.3516"
          stroke="#1099FE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M84.3334 25V25.0183"
          stroke="#1099FE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.16" clipPath="url(#clip2_1509_7632)">
        <path
          d="M117 35H147"
          stroke="#1099FE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M143.667 35V28.3333"
          stroke="#1099FE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M143.667 28.3333C144.551 28.3333 145.399 27.9821 146.024 27.357C146.649 26.7319 147 25.884 147 25V21.6666C147 20.7826 146.649 19.9347 146.024 19.3096C145.399 18.6845 144.551 18.3333 143.667 18.3333C142.783 18.3333 141.935 18.6845 141.31 19.3096C140.685 19.9347 140.333 20.7826 140.333 21.6666V25C140.333 25.884 140.685 26.7319 141.31 27.357C141.935 27.9821 142.783 28.3333 143.667 28.3333Z"
          stroke="#1099FE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M135.333 35V11.6667C135.333 10.3406 134.807 9.06884 133.869 8.13115C132.931 7.19347 131.659 6.66669 130.333 6.66669H123.667C122.341 6.66669 121.069 7.19347 120.131 8.13115C119.193 9.06884 118.667 10.3406 118.667 11.6667V35"
          stroke="#1099FE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M127 28.3333V35"
          stroke="#1099FE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M125.333 21.6667H128.667"
          stroke="#1099FE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M125.333 15H128.667"
          stroke="#1099FE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.16" clipPath="url(#clip3_1509_7632)">
        <path
          d="M173 35H203"
          stroke="#1099FE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M176.333 35V15L184.667 21.6667V15L193 21.6667H199.667"
          stroke="#1099FE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M199.667 35V21.6667L197.273 5.71C197.244 5.51241 197.144 5.33202 196.993 5.2017C196.841 5.07138 196.648 4.9998 196.448 5H194.54C194.342 4.9997 194.151 5.06958 194 5.19718C193.85 5.32478 193.749 5.5018 193.717 5.69667L191.333 20"
          stroke="#1099FE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M183 28.3333H184.667"
          stroke="#1099FE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M191.333 28.3333H193"
          stroke="#1099FE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.16" clipPath="url(#clip4_1509_7632)">
        <path
          d="M229 35H259"
          stroke="#1099FE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M229 11.6667V13.3333C229 14.6594 229.527 15.9312 230.464 16.8689C231.402 17.8066 232.674 18.3333 234 18.3333C235.326 18.3333 236.598 17.8066 237.536 16.8689C238.473 15.9312 239 14.6594 239 13.3333M229 11.6667H259M229 11.6667L232.333 5H255.667L259 11.6667M239 13.3333V11.6667M239 13.3333C239 14.6594 239.527 15.9312 240.464 16.8689C241.402 17.8066 242.674 18.3333 244 18.3333C245.326 18.3333 246.598 17.8066 247.536 16.8689C248.473 15.9312 249 14.6594 249 13.3333M249 13.3333V11.6667M249 13.3333C249 14.6594 249.527 15.9312 250.464 16.8689C251.402 17.8066 252.674 18.3333 254 18.3333C255.326 18.3333 256.598 17.8066 257.536 16.8689C258.473 15.9312 259 14.6594 259 13.3333V11.6667"
          stroke="#1099FE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M232.333 35V18.0833"
          stroke="#1099FE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M255.667 35V18.0833"
          stroke="#1099FE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M239 35V28.3333C239 27.4493 239.351 26.6014 239.976 25.9763C240.601 25.3512 241.449 25 242.333 25H245.667C246.551 25 247.399 25.3512 248.024 25.9763C248.649 26.6014 249 27.4493 249 28.3333V35"
          stroke="#1099FE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1509_7632">
          <rect width="40" height="40" fill="white" />
        </clipPath>
        <clipPath id="clip1_1509_7632">
          <rect width="40" height="40" fill="white" transform="translate(56)" />
        </clipPath>
        <clipPath id="clip2_1509_7632">
          <rect
            width="40"
            height="40"
            fill="white"
            transform="translate(112)"
          />
        </clipPath>
        <clipPath id="clip3_1509_7632">
          <rect
            width="40"
            height="40"
            fill="white"
            transform="translate(168)"
          />
        </clipPath>
        <clipPath id="clip4_1509_7632">
          <rect
            width="40"
            height="40"
            fill="white"
            transform="translate(224)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
