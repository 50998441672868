
import { axiosClient } from '../core/axiosClient';

const createFormData = <T extends Record<string, any>>(data: T): FormData => {
    const formData = new FormData();

    Object.keys(data).forEach(key => {
        const value = data[key];
        if (value instanceof File) {
            formData.append(key, value);
        } else if (value !== null && value !== undefined && value !== "") {
            formData.append(key, value.toString());
        }
    });

    return formData;
};

export async function PostRequest<T = any>(action: string, body: any): Promise<T> {
    try {
        const response = await axiosClient.post(action, body, {
            maxBodyLength: Infinity,
        });
        return response.data as T;
    } catch (error) {
        console.error('PostRequest error:', error);
        throw error;
    }
}

export async function PostRequestFormData<T extends Record<string, any>, R = any>(action: string, data: T): Promise<R> {
    try {
        const formData = createFormData(data);
        const response = await axiosClient.post(action, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            maxBodyLength: Infinity,
        });
        return response?.data as R;
    } catch (error) {
        console.error('PostRequestFormData error:', error);
        throw error;
    }
}