import React from "react";

interface Props {
  className: string;
}

export const UserHomeIcon = ({ className }: Props): JSX.Element => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Frame" clipPath="url(#clip0_125_1397)">
        <g id="Group 1261153577">
          <path
            id="Vector"
            d="M2 23.2495V6.99951C2 6.00495 2.39509 5.05112 3.09835 4.34786C3.80161 3.6446 4.75544 3.24951 5.75 3.24951H18.25C19.2446 3.24951 20.1984 3.6446 20.9016 4.34786C21.6049 5.05112 22 6.00495 22 6.99951V14.4995C22 15.4941 21.6049 16.4479 20.9016 17.1512C20.1984 17.8544 19.2446 18.2495 18.25 18.2495H7L2 23.2495Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M12 10.7495V10.7595"
            stroke="currentColor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_3"
            d="M7 10.7495V10.7595"
            stroke="currentColor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_4"
            d="M17 10.7495V10.7595"
            stroke="currentColor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_125_1397">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
