import React, { useState } from "react";
import { UploadPictureCard } from "@/components/cards/UploadPictureCard";
import { InputField } from "@/components/inputFields/inputFields";
import TextFeild from "@/components/inputFields/TextFeild";
import ColorSelectionCard from "@/components/cards/ColorSelectionCard";
import PurchasedCard from "@/components/cards/PurchasedCard";
import { Button } from "@/components/Buttons";
import { OrgData, orgColorOptions } from "@/types";
import { useUpdateOrg } from "@/hooks/organization/useUpdateOrg";
import { useCreateLicense } from "@/hooks/organization/useCreateLicense";
import { useFetchAllOrgs, useFetchUserOrg } from "@/utils/fetchData";
import { useSelector, useDispatch } from "react-redux";
import { setToast } from "@/store/systemSlice";
import { RootState } from "@/store/store";
import { useTranslation } from 'react-i18next';


interface OrganizationSettingsTabProps {
  isColorCard: boolean;
  selectedOrg: OrgData;
  setSelectedOrg: React.Dispatch<React.SetStateAction<OrgData | null>>;
}

const OrganizationSettingsTab: React.FC<OrganizationSettingsTabProps> = ({
  isColorCard,
  selectedOrg,
  setSelectedOrg,
}) => {
  const { t } = useTranslation('OrganizationSettingsTab');
  const userType = useSelector((state: RootState) => state.user.user).user_type;
  const getAllOrgs = useFetchAllOrgs();
  const getUserOrg = useFetchUserOrg();
  const usedispatch = useDispatch();
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [uploadImageAction, setUploadImageAction] = useState<string>("none"); // Possible values: "none", "replace", "delete"
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedProducts, setSelectedProducts] = useState<{
    [key: string]: boolean;
  }>({
    KalAudio: false,
    KalChat: false,
    KalDocs: false,
    KalMedia: false,
  });

  const isSuperAdmin = userType === "super_admin";
  const admin = userType === "admin";
  const products = useSelector(
    (state: RootState) => state.systemService.products
  );
  const handleUpdate = (field: keyof OrgData, value: string) => {
    setSelectedOrg((prev) => ({
      ...prev!,
      [field]: value,
    }));
  };

  const updateOrg = useUpdateOrg({
    onSuccess() {
      const orgBody = { org_id: selectedOrg?.id };
      if (isSuperAdmin) getAllOrgs.mutate();
      else getUserOrg.mutate(orgBody);
      setLoading(false);
      usedispatch(
        setToast({
          toastTitle: "Update Org",
          toastMessage: `Organization update successfully`,
          toastType: "success",
        })
      );
    },
    onError(error) {
      setLoading(false);
      console.log(`Error Updating Organization: ${error.response}`);
      usedispatch(
        setToast({
          toastTitle: "Update Org",
          toastMessage: `Error Updating Organization`,
          toastType: "error",
        })
      );
    },
  });

  const createLicenseMutation = useCreateLicense({
    onSuccess: (data) => {
      getAllOrgs.mutate();
      handleUpdate("org_name", selectedOrg.org_name)
    },
    onError: (error) => {
      usedispatch(
        setToast({
          toastTitle: "Create License",
          toastMessage: "License creation failed",
          toastType: "error",
        })
      );
    },
  });

  const handleSaveUpdateClick = () => {
    setLoading(true);
    const updateBody = {
      org_id: selectedOrg?.id,
      org_description: selectedOrg?.org_description,
      org_color: selectedOrg?.org_color,
      logo: imageFile,
      upload_image_action: uploadImageAction,
    };
    updateOrg.mutate(updateBody);

    // Create licenses for selected products
    Object.keys(selectedProducts).forEach((product) => {
      if (selectedProducts[product] && (!selectedOrg.products.hasOwnProperty(product))) {
        const product_id = products.find((p) => p.name === product)?.id || "";
        createLicenseMutation.mutate({
          org_id: selectedOrg?.id,
          product_id,
          status: "active",
        });
      }
    });
  };

  return (
    <div className="p-6 sm:p-9">
      <h2 className="form-title-text mb-3">{t("title")}</h2>
      <div className="flex flex-col md:flex-row gap-6 mb-2">
        <div className="w-full md:w-1/4">
          <div className="mb-6">
            <h3 className="form-label">{t("subtitle")}</h3>
            <UploadPictureCard
              isOrgUpload={true}
              file={imageFile}
              setFile={setImageFile}
              orgColor={
                isSuperAdmin 
                  ? (selectedOrg?.org_color as orgColorOptions)
                  : "blue"
              }
              existingImageUrl={selectedOrg.logo}
              setAction={setUploadImageAction}
            />
          </div>
          {isColorCard && isSuperAdmin && (
            <div>
              <h3 className="form-label">{t("selectColor")}</h3>
              <ColorSelectionCard
                selectedColor={selectedOrg?.org_color as orgColorOptions}
                setSelectedColor={(color: orgColorOptions) =>
                  setSelectedOrg((prev) => ({
                    ...prev!,
                    org_color: color,
                  }))
                }
              />
            </div>
          )}
        </div>
        <div className="w-full md:w-2/3">
          <div className="flex flex-col md:flex-row md:items-start gap-4 md:gap-2">
            <div className="w-full md:w-1/2">
              <div className="mb-4">
                <h3 className="form-label">{t("organizationName")}</h3>
                <InputField
                  disabled={true}
                  id="orgName"
                  inputType="text"
                  data={selectedOrg?.org_name as string}
                  setData={(value: string) => handleUpdate("org_name", value)}
                />
              </div>
              <div>
                <h3 className="form-label">{t("description")}</h3>
                <TextFeild
                  text={selectedOrg?.org_description || ""}
                  setText={(value: string) =>
                    handleUpdate("org_description", value)
                  }
                  placeholder={selectedOrg?.org_description as string}
                />
              </div>
            </div>
            <div className="w-full md:w-1/2">
              <h3 className="form-label">{t("organizationID")}</h3>
              <InputField
                id="orgID"
                inputType="text"
                data={selectedOrg?.id as string}
                setData={() => {}}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2 flex flex-col md:flex-row gap-6">
        <div className="w-full md:w-2/3">
          <PurchasedCard
            isSuperAdmin={isSuperAdmin || admin}
            selectedOrg={selectedOrg}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
          />
        </div>
        <div className="w-full md:w-1/2">
          {/* <h3 className="text-black text-[14px] font-medium font-['Roboto'] capitalize mb-2">
            Adding admin
          </h3> */}
          <div className="flex flex-col gap-2">
            {/* <ClutchEmailField setEmail={() => {}} onClick={() => {}} />
            <UserLinkList links={adminLinks} /> */}
          </div>
        </div>
      </div>
      <div className="mt-7 flex justify-center w-full">
        <div className="mt-6">
          <Button
            icon="no"
            onClick={handleSaveUpdateClick}
            text={t("button")}
            ready={true}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default OrganizationSettingsTab;
