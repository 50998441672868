import { PostRequest, PostRequestFormData } from "./requests";
import { GetDeleteFile, GetDeleteAllFiles, UploadFile } from "@/types";

export const getFile = async (data: GetDeleteFile) => {
    try {
        return await PostRequest("/api/files/get", data);
    } catch (error) {
        console.log('Error in runEvaluation:', error);
        throw error;
    }
}

export const deleteFile = async (data: GetDeleteFile) => {
    try {
        return await PostRequest("/api/files/delete", data);
    } catch (error) {
        console.log('Error in runEvaluation:', error);
        throw error;
    }
}

export const getAllFiles = async (data: GetDeleteAllFiles) => {
    try {
        return await PostRequest("/api/files/get_all", data);
    } catch (error) {
        console.log('Error in runEvaluation:', error);
        throw error;
    }
}


export const deleteAllFiles = async (data: GetDeleteAllFiles) => {
    try {
        return await PostRequest("/api/files/delete_all", data);
    } catch (error) {
        console.log('Error in runEvaluation:', error);
        throw error;
    }
}

export const uploadFile = async (data: UploadFile) => {
    try {
        return await PostRequestFormData("/api/files/create", data);
    } catch (error) {
        console.log('Error in runEvaluation:', error);
        throw error;
    }
}
