import { useMutation } from '@tanstack/react-query';
import { createProject } from "@/api/project"; 
import { CreateProject, RetuenCreateProject } from "@/types/project"; 
import { PostParam } from "@/types/index"; 

export function useCreateProject(props?: PostParam) {
  const { onSuccess, onError } = props || {};

  return useMutation<RetuenCreateProject, Error, CreateProject>({
    mutationFn: createProject,
    onSuccess,
    onError
  });
}