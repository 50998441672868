import { useMutation } from '@tanstack/react-query';
import { getAllProjectsTypes } from "../../api/product";
import {OrgIdBody,ProjectTypeData} from "@/types";
import { PostParam } from "@/types";

export function useGetAllProjectsTypes(props?: PostParam) {
    const { onSuccess, onError } = props || {};

    return useMutation<ProjectTypeData[], Error, OrgIdBody>({
        mutationFn: getAllProjectsTypes,
        onSuccess,
        onError
    });
}