import React from "react";
import { Export } from "../../icons/Export";
import { useNavigate } from "react-router-dom";
import { KalAudioIcon } from "@/icons/KalAudioIcon";
import { KalAudioText } from "@/icons/KalAudioText";
import { KalChatIcon } from "@/icons/KalChatIcon";
import { KalChatText } from "@/icons/KalChatText";
import { KalDocIcon } from "@/icons/KalDocIcon";
import { KalDocText } from "@/icons/KalDocText";
import { KalMediaIcon } from "@/icons/KalMediaIcon";
import { KalMediaText } from "@/icons/KalMediaText";
import styles from "./css/ProjectCard.module.css";
import { formatDate } from "@/utils/date";
import { ProjectData, ProductTypes } from "@/types";
import TruncateText from "../addons/TruncatedText";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";

type ProjectCardProps = {
  data: ProjectData;
};

const ProductSettings: {
  [key in ProductTypes]: {
    color: string;
    image: React.ComponentType<{ className: string }>;
    text: React.ComponentType<{ className: string }>;
  };
} = {
  KalDocs: { color: "bg-kalDocs-default", image: KalDocIcon, text: KalDocText },
  KalAudio: {
    color: "bg-kalAudio-default",
    image: KalAudioIcon,
    text: KalAudioText,
  },
  KalChat: {
    color: "bg-kalChat-default",
    image: KalChatIcon,
    text: KalChatText,
  },
  KalMedia: {
    color: "bg-kalMedia-default",
    image: KalMediaIcon,
    text: KalMediaText,
  },
};

const ProjectCard: React.FC<ProjectCardProps> = ({ data }) => {
  const {
    color,
    image: ImageComponent,
    text: TextComponent,
  } = ProductSettings[data.product_name];
  const org = useSelector((state: RootState) => state.org.userOrg);
  const user = useSelector((state: RootState) => state.user.user);
  const navigate = useNavigate();
  const handleExportClick = () => {
    if (user.user_type === "user") {
      navigate(`/${org.org_name}/${data.product_name}/${data.id}`);
    } else {
      navigate(
        `/${org.org_name}/project/settings/${data.product_name}/${data.id}`
      );
    }
  };

  return (
    <div className={styles.projectCard} onClick={handleExportClick}>
      <div className={styles.headerContainer}>
        <div className={`${styles.headerBackground} ${color}`} />
        <div
          className={`${styles.projectImageContainer} ${styles[data.product_name]}`}
        >
          <ImageComponent
            className={`${styles.projectImage} ${styles[data.product_name]}`}
          />
        </div>
        <div>
          <TextComponent className={styles.kalText} />
        </div>
        <div style={{ cursor: "pointer" }}>
          <Export className={styles.exportIcon} />
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.projectName}>{data.name}</div>
        <div className={styles.userName}>{data.created_by}</div>
        <div className={styles.date}>{formatDate(data.created_at)}</div>
        <p className={styles.description}>
          <TruncateText text={data.description} maxLength={135}/>
        </p>
      </div>
    </div>
  );
};

export default ProjectCard;
