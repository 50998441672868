import React, { FC, useState, useEffect } from "react";
import ProjectWorkspace from "@/components/addons/ProjectWorkspace";
import GeneralTab from "@/components/tabs/GeneralTabs";
import { Header } from "@/components/headers/Header";
import { orgColorOptions, ProjectData } from "@/types";
import OrganizationSettingsTab from "../components/pageParts/OrganizationSettingsTab";
import OrganizationProjectsTab from "../components/pageParts/OrganizationProjectsTab";
import { useSelector } from "react-redux";
import { OrgData } from "@/types";
import { useNavigate, useParams } from "react-router-dom";
import { useGetProjectByOrgID } from "@/hooks/project/useGetProjectsByOrgID";
import { RootState } from "@/store/store";
import { useTranslation } from 'react-i18next';




const OrganizationSettingPage: FC = () => {
  const { t, i18n } = useTranslation('OrganizationSettingPage');
  const tabs = [
    { name: t('Organization'), key: "organization_settings" },
    { name: t('Projects'), key: "projects" },
    { name: t('Usage'), key: "Usage" },
    { name: t('Billing'), key: "Billing" },
  ];
  const [activeTab, setActiveTab] = useState(tabs[0].key);
  const [orgProjectData, setOrgProjectData] = useState<ProjectData[]>([]);
  const { orgId } = useParams<{ orgId: string }>();
  const navigate = useNavigate();
  const [updateOrg, setUpdateOrg] = useState<OrgData | null>(null);
  const orgs = useSelector((state: RootState) => state.org.orgs)

  useEffect(() => {
    const selectedOrg = orgs.find((org) => org.id === orgId);
    if (selectedOrg) {
      setUpdateOrg(selectedOrg);
    } else {
      navigate("/404");
    }
  }, [orgId, location.pathname]);
  
  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  const getOrgProjects = useGetProjectByOrgID({
      onSuccess(data) {
        setOrgProjectData(data);
      },
      onError(error) {
          console.log(`Error fetching projects: ${error.response.data}`)
      },
  });

  useEffect(() => {
    if (orgId) {
      getOrgProjects.mutate({ org_id: orgId});
    }
  }, []);

  return (
    <>
      {updateOrg && (
        <>
          <Header
            orgColor={updateOrg.org_color}
            orgName={updateOrg.org_name}
            className="mb-4"
          />
          <ProjectWorkspace color={updateOrg.org_color as orgColorOptions}>
            <div className="relative flex border-b border-gray-300 pt-2 pb-0">
              <GeneralTab
                tabs={tabs}
                activeTab={activeTab}
                onTabChange={handleTabChange}
              />
            </div>

            <div className="-mt-5">
              {activeTab === "organization_settings" && (
                <OrganizationSettingsTab
                  isColorCard={true}
                  selectedOrg={updateOrg}
                  setSelectedOrg={setUpdateOrg}
                />
              )}
              {activeTab === "projects" && <OrganizationProjectsTab projects={orgProjectData}/>}
              {activeTab === "Usage" && (
                <div className="m-9">{t('UsageContent')}</div>
              )}
              {activeTab === "Billing" && (
                <div className="m-9">{t('BillingContent')}</div>
              )}
            </div>
          </ProjectWorkspace>
        </>
      )}
    </>
  );
};

export default OrganizationSettingPage;
