import React, { useState } from "react";
import styles from "./css/ClutchEmailField.module.css";
import { Button } from "@/components/Buttons";
import { EmailsInput } from "./EmailInput";
import { EmailSuggestionObject } from "@/types";

interface ClutchEmailFieldProps<T extends EmailSuggestionObject> {
  selectedEmails: T[];
  suggestions: T[];
  handleAddUser: (user: T) => boolean;
}

export const ClutchEmailField = <T extends EmailSuggestionObject>({
  selectedEmails,
  suggestions,
  handleAddUser,
}: ClutchEmailFieldProps<T>) => {
  const [email, setEmail] = useState<string>("");
  const [added, setAdded] = useState(false);

  const handleAddEmail = () => {
    const selectedEmailObj = suggestions.find(
      (suggestion) => suggestion.email === email
    );
    if (selectedEmailObj) {
      const added = handleAddUser(selectedEmailObj);
      if (added) {
        setEmail("");
        setAdded(true);
      } else {
        //TODO show error
      }
    }
  };

  return (
    <div className={styles.clutch}>
      <div className={styles.inputWrapper}>
        <EmailsInput
          added={added}
          setAdded={setAdded}
          inputValue={email}
          setInputValue={setEmail}
          suggestions={suggestions}
          selectedEmails={selectedEmails}
        />
      </div>
      <Button
        onClick={handleAddEmail}
        text="Add"
        icon="no"
        className={styles.button}
      />
    </div>
  );
};
