import { useMutation } from '@tanstack/react-query';
import { changeUserProjectsList } from "@/api/userProject"; 
import { UpdateUserProjectsList,ReturnCreateDelete } from "@/types"; 
import { PostParam } from "@/types/index"; 

export function useChangeUserProjectsList(props?: PostParam) {
  const { onSuccess, onError } = props || {};

  return useMutation<ReturnCreateDelete, Error, UpdateUserProjectsList>({
    mutationFn: changeUserProjectsList,
    onSuccess,
    onError
  });
}