import React, { useState, useEffect } from "react";
import { FileCard } from "../cards/FileCard";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { useTranslation } from "react-i18next";

interface SearchMessageProps {

}

const SearchMessage: React.FC<SearchMessageProps> = () => {
  const { t } = useTranslation('SearchMessage');
  const [displayedMessage, setDisplayedMessage] = useState("");
  const [showSources, setShowSources] = useState(false);
  const [showNewSection, setShowNewSection] = useState(false);
  const [dots, setDots] = useState("");
  const chat = useSelector((state: RootState) => state.chat);
  const files = chat.responseFilteredFiles;
  const message = chat.responseMessage;

  useEffect(() => {
    if (chat.chatWaiting) {
      setDisplayedMessage(""); // Reset the previous message
      setShowSources(false);
      setShowNewSection(false);
      const dotTimer = setInterval(() => {
        setDots((prevDots) => (prevDots.length < 3 ? prevDots + "." : ""));
      }, 500);
      return () => clearInterval(dotTimer);
    } else {
      setDots(""); // Reset dots when not waiting
      let index = 0;
      const timer = setInterval(() => {
        if (index < message.length) {
          const char = message[index];
          if (char !== undefined) {
            setDisplayedMessage((prev) => prev + char);
          }
          index++;
        } else {
          clearInterval(timer);
          setTimeout(() => {
            setShowSources(true);
            setShowNewSection(true);
          }, 500);
        }
      }, 40);
    
      return () => clearInterval(timer);
    }
  }, [message, chat.chatWaiting]); 
  
  return (
    <div className="flex flex-col items-center">
      {/* Message and files container */}
      <div className="w-[800px] px-4 py-4 bg-[#FCFCFC] rounded-lg">
        <div className="text-[#333333] text-sm font-normal font-['Roboto'] leading-5 mb-1">
          {chat.chatWaiting ? (
            <span>{t('spanhMessage')}{dots}</span>
          ) : (
            <span><div style={{ direction: /[\u0590-\u05FF]/.test(displayedMessage) ? 'rtl' : 'ltr' }}>{displayedMessage}</div></span>
          )}
        </div>
        {files.length > 0 && showSources && (
          <div className="text-[#333333] text-sm font-semibold font-['Roboto'] leading-6">
            <p>{t('sourcesAndRelatedContent')}</p>
          </div>
        )}

        {/* Separator */}
        {files.length > 0 && <div className="w-full h-[1px] bg-gray-300 my-4"></div>}

        {files.length > 0 &&  showNewSection && (
          <div className="text-[#333333] text-sm font-normal font-['Roboto'] leading-5 mb-1">
            <p>{t('relatedFilesMessage')}</p>
          </div>
        )}

        {/* FileCards section in rows */}
        {showNewSection && (
          <div className="file-section-container max-h-80 overflow-y-auto">
            <div className="flex flex-wrap gap-4">
              {files.map((file, index) => (
                <FileCard key={index} data={file} projectName={"test project"} projectColor={'blue'} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchMessage;
