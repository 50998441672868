import React from "react";

interface Props {
  className: string;
}

export const KalChatIcon = ({ className }: Props): JSX.Element => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M25.3333 3C26.571 3 27.758 3.49167 28.6332 4.36684C29.5083 5.242 30 6.42899 30 7.66667V20.1111C30 21.3488 29.5083 22.5358 28.6332 23.4109C27.758 24.2861 26.571 24.7778 25.3333 24.7778H17.5556L9.77778 29.4444V24.7778H6.66667C5.42899 24.7778 4.242 24.2861 3.36684 23.4109C2.49167 22.5358 2 21.3488 2 20.1111V7.66667C2 6.42899 2.49167 5.242 3.36684 4.36684C4.242 3.49167 5.42899 3 6.66667 3H25.3333Z"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1108 10.778H12.1242"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.8892 10.778H19.9025"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1108 17C12.6178 17.5174 13.2228 17.9284 13.8906 18.209C14.5584 18.4896 15.2754 18.6341 15.9997 18.6341C16.7241 18.6341 17.4411 18.4896 18.1089 18.209C18.7766 17.9284 19.3817 17.5174 19.8886 17"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
