import { useMutation } from '@tanstack/react-query';
import { getAllProject } from "@/api/project"; 
import { GetAllProjec, ProjectData } from "@/types/project"; 
import { PostParam } from "@/types/index"; 

export function useGetAllProject(props?: PostParam) {
  const { onSuccess, onError } = props || {};

  return useMutation<ProjectData[], Error, GetAllProjec>({
    mutationFn: getAllProject,
    onSuccess,
    onError
  });
}