import React, { useState, useEffect } from "react";
import { UserDocsIcon } from "@/icons/UserDocsIcon";
import { UserAudioIcon } from "@/icons/UserAudioIcon";
import styles from "./css/ChatFocus.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setChatState } from "@/store/chatSlice";
import { RootState } from "@/store/store";
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';



interface ChatFocusProps {
  onClose: () => void;
}

const ChatFocus: React.FC<ChatFocusProps> = ({ onClose }) => {
  const { t } = useTranslation('ChatFocus');
  const dispatch = useDispatch();
  const chatSearchFocus = useSelector((state: RootState) => state.chat.chatSearchFocus);
  const orgName = useSelector((state: RootState) => state.org.userOrg.org_name);
  const [active, setActive] = useState<"audio" | "doc" | "none">(chatSearchFocus);
  const location = useLocation();
  const decodedPathname = decodeURIComponent(location.pathname);

  const isDeshbord = (decodedPathname === `/${orgName}/dashboard`) 

  useEffect(() => {
    if (!isDeshbord) {
      onClose();
    }
  }, [isDeshbord, onClose]);

  const handleAudioClick = () => {
    dispatch(setChatState({ chatSearchFocus: "audio", toShowPrompt: true }));
    onClose();
  };

  const handleDocClick = () => {
    dispatch(setChatState({ chatSearchFocus: "doc", toShowPrompt: true }));
    onClose();
  };

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className={styles.container} onClick={handleOutsideClick}>
      <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
        <div className={styles.header}>
          <div className={styles.headerText}>
            {t("headerText")}
          </div>
        </div>

        <div className={styles.optionContainer}>
          <div
            className={`${styles.option} ${active === "audio" ? styles.optionActive : ""}`}
            onClick={handleAudioClick}
          >
            <UserAudioIcon className={styles.icon} />
            <div>
              <div className={styles.optionText}>
              {t("optionTextAudio")}
              </div>
              <div className={styles.optionSubtext}>
              {t("optionSubtextAudio")}
              </div>
              <div className={styles.optionNote}>
              {t("optionNoteAudio")}
              </div>
            </div>
          </div>

          <div
            className={`${styles.option} ${active === "doc" ? styles.optionActive : ""}`}
            onClick={handleDocClick}
          >
            <UserDocsIcon className={styles.icon} />
            <div>
              <div className={styles.optionText}>
              {t("optionTextDoc")}
              </div>
              <div className={styles.optionSubtext}>
              {t("optionSubtextDoc")}
              </div>
              <div className={styles.optionNote}>
              {t("optionNoteDoc")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatFocus;
