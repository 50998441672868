import { IoIosArrowForward } from "react-icons/io";
import React, { useEffect, useState } from "react";
import styles from "./css/CreateProjectPage.module.css";
import {
  CreateProject,
  ProductData,
  ProductTypes,
  ProjectColors,
  ProjectTypeData,
} from "@/types";
import { useParams } from "react-router-dom";
import { ProjectHeader } from "@/components/headers/ProjectHeader";
import ProjectWorkspace from "@/components/addons/ProjectWorkspace";
import { InputField } from "@/components/inputFields/inputFields";
import TextField from "@/components/inputFields/TextFeild";
import { Button, RadioButton } from "@/components/Buttons";
import { TooltipIcon } from "@/icons/TooltipIcon";
import { UserData } from "@/types/user";
import { UsersAttachments } from "@/components/pageParts/project/UsersAttachments";
import { useSelector, useDispatch } from "react-redux";
import { setToast } from "@/store/systemSlice";
import { currentDate } from "@/utils/date";
import { useNavigate } from "react-router-dom";
import { useCreateProject } from "@/hooks/project/useCreateProject";
import { useFetchAllProjects } from "@/utils/fetchData";
import { useChangeProjectUsersList } from "@/hooks/userProject/useChangeProjectUsersList";
import { RootState } from "@/store/store";
import { useTranslation } from 'react-i18next';

interface CreateProjectPageProps {}

export const CreateProjectPage: React.FC<CreateProjectPageProps> = () => {
  const { t } = useTranslation('CreateProjectPage');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { projectType } = useParams<{ projectType: string }>();
  const user = useSelector((state: RootState) => state.user.user);
  const [selectedUsers, setSelectedUsers] = useState<UserData[]>([user]);
  const products = useSelector(
    (state: RootState) => state.systemService.products
  );
  const projectsTypes = useSelector(
    (state: RootState) => state.systemService.projectTypes
  );
  const org = useSelector((state: RootState) => state.org.userOrg);
  const projects = useSelector((state: RootState) => state.projects.projects)
  const [projectTypes, setProjectTypes] = useState<ProjectTypeData[]>([]);
  const users = useSelector((state: RootState) => state.user.users);
  const [disableSave, setDisableSave] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingSaveForward, setLoadingSaveForward] = useState(false);
  const [nameError, setNameError] = useState("");

  // generate random color from ProjectColors
  const generateRandomColor = (): ProjectColors => {
    const allProjectColors: ProjectColors[] = ["pink", "darkBlue", "blue", "green", "orange", "mint", "purple", "yellow"];
    
    if (projects.length < 8) {
      const usedColors = new Set(projects.map(project => project.color));
      const availableColors = allProjectColors.filter(color => !usedColors.has(color));
      return availableColors[Math.floor(Math.random() * availableColors.length)];
    }
    
    return allProjectColors[Math.floor(Math.random() * allProjectColors.length)];
  };

  const [data, setData] = useState<CreateProject>({
    name: "",
    description: "",
    product_id: "",
    product_name: projectType as ProductTypes,
    project_type_id: "",
    org_id: org.id,
    color: generateRandomColor()
  });
  const [selectedType, setSelectedType] = useState("");

  const getAllProjects = useFetchAllProjects();

  const createProjectUsers = useChangeProjectUsersList({
    onError(error) {
      dispatch(
        setToast({
          toastMessage: error.response.data.message,
          toastType: "error",
          toastTitle: t("FailedCreateProject"),
        })
      );
    },
  });

  const createProjectMutation = useCreateProject({
    onSuccess(data) {
      getAllProjects.mutate({ org_id: org.id });
      const projectId = data.project_id;
      createProjectUsers.mutate({
        org_id: org.id,
        project_id: projectId,
        users_list: selectedUsers,
      });
      if (loadingSave) {
        navigate(`/${org.org_name}/dashboard`);
      } else {
        navigate(`https://kalAudioDomain/${projectId}`);
      }
    },
    onError(error) {
      dispatch(
        setToast({
          toastMessage: error.response.data.message,
          toastType: "error",
          toastTitle: t("FailedCreateProject"),
        })
      );
      setLoadingSave(false);
      setLoadingSaveForward(false);
    },
  });

  const handleSetData = (field: string, value: string) => {
    if(field === "name"){
      if(projects.find((proj) => proj.name === value)){
        setNameError(t("ProjectNameExists"))
      }
      else {
        setNameError("")
      }
    }
    setData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  useEffect(() => {
    const productId = products.find(
      (product: ProductData) => product.name === projectType
    )?.id;
    if (productId) {
      setProjectTypes(
        projectsTypes.filter((projectType) => projectType.product === productId)
      );
      setData((prev: CreateProject) => ({
        ...prev,
        product_id: productId,
      }));
    } else {
      console.log("Error");
    }
  }, [products, projectsTypes]);

  useEffect(() => {
    if (data.name !== "" && data.project_type_id !== "" && nameError === "") {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
  }, [data]);

  const handleRemoveUser = (user: UserData) => {
    setSelectedUsers((prevUsers) =>
      prevUsers.filter((u) => u.email !== user.email)
    );
  };

  const handleAddUser = (user: UserData) => {
    const updateUsers = [...selectedUsers, user];
    setSelectedUsers(updateUsers);
    return true;
  };

  const handleTypeChanged = (type: string) => {
    const project_type_id = projectsTypes.find(
      (project_type) => project_type.type === type
    )?.id;
    if (project_type_id) {
      setSelectedType(type);
      setData((prev: CreateProject) => ({
        ...prev,
        project_type_id: project_type_id,
      }));
    }
  };

  const handleCreateProject = (type: string) => {
    if (type == "save") {
      setLoadingSave(true);
    } else {
      setLoadingSaveForward(true);
    }
    createProjectMutation.mutate(data);
  };
  return (
    <div className={styles.container}>
      <ProjectHeader type={projectType as ProductTypes} />
      <ProjectWorkspace projectType={projectType as ProductTypes}>
        <div className={styles.settings}>
          <div className={styles.top}>
            <div className={styles.projectName}>{t('CreateProject')}</div>
            <div className={`${styles.creatorDate}`}>
              <div className={`${styles.creator}`}>{user.user_name}</div>
              <div className={`${styles.date}`}>{currentDate()}</div>
            </div>
          </div>
          <div className={styles.bottom}>
            <div className={styles.bottomCol1}>
              <div className={styles.fieldTile}>{t('ProjectName')}</div>
              <InputField
                data={data.name}
                placeholder={t("SelectProjectName")}
                inputType="text"
                id="projectName"
                setData={(value) => {
                  handleSetData("name", value);
                }}
                errorMessage={nameError}
              />
              <div className={styles.usersSection}>
                <UsersAttachments
                  users={users}
                  selectedUsers={selectedUsers}
                  handleRemoveUser={handleRemoveUser}
                  handleAddUser={handleAddUser}
                />
              </div>
            </div>
            <div className={styles.bottomCol2}>
              <div className={styles.fieldTile}>{t('ProjectDescription')}</div>
              <TextField
                placeholder={t("description")}
                text={data.description || ""}
                setText={(value) => handleSetData("description", value)}
              />

              <div className={styles.fieldTile}>{t('SelectProjectType')}</div>
              <div className={styles.types}>
                {projectTypes &&
                  projectTypes.map((type) => (
                    <div className={styles.type} key={type.id}>
                      <div className={styles.radio}>
                        <RadioButton
                          title={t(type.type)}
                          selected={selectedType}
                          titleClass={styles.radioTitle}
                          id={type.type}
                          key={type.id}
                          onClick={(value) => handleTypeChanged(value)}
                        />
                      </div>
                      <div className={styles.typeDescription}>
                        <div>
                          <TooltipIcon className={styles.tooltip} />
                        </div>
                        {t(type.description)}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.buttons}>
          <Button
            text={t("CreateTheProject")}
            icon="no"
            onClick={() => handleCreateProject("save")}
            className={styles.button}
            disabled={disableSave || loadingSaveForward}
            loading={loadingSave}
          />
          <Button
            text={t("CreateEnterProject")}
            icon="right-side"
            iconColorType="text"
            ready={true}
            iconComponent={IoIosArrowForward}
            onClick={() => handleCreateProject("forward")}
            className={styles.button}
            disabled={disableSave || loadingSave}
            loading={loadingSaveForward}
          />
        </div>
      </ProjectWorkspace>
    </div>
  );
};
