import React from "react";

interface Props {
  color?: string;
}

export const ToastSuccess = ({ color = "#75DE85" }: Props): JSX.Element => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame" clipPath="url(#clip0_1857_827)">
        <g id="Group 1261153641">
          <path
            id="Vector"
            d="M16 0L16.5184 0.00159988L17.0272 0.00800018L18.0128 0.0352001L18.4912 0.0560001L19.4176 0.1104L20.3024 0.184C27.9584 0.9264 31.0736 4.0416 31.816 11.6976L31.8896 12.5824L31.944 13.5088C31.952 13.6656 31.96 13.8256 31.9648 13.9872L31.992 14.9728L32 16L31.992 17.0272L31.9648 18.0128L31.944 18.4912L31.8896 19.4176L31.816 20.3024C31.0736 27.9584 27.9584 31.0736 20.3024 31.816L19.4176 31.8896L18.4912 31.944C18.3344 31.952 18.1744 31.96 18.0128 31.9648L17.0272 31.992L16 32L14.9728 31.992L13.9872 31.9648L13.5088 31.944L12.5824 31.8896L11.6976 31.816C4.0416 31.0736 0.9264 27.9584 0.184 20.3024L0.1104 19.4176L0.0560001 18.4912C0.0482164 18.3318 0.0412829 18.1723 0.0352001 18.0128L0.00800018 17.0272C0.00320018 16.6912 0 16.3488 0 16L0.00159988 15.4816L0.00800018 14.9728L0.0352001 13.9872L0.0560001 13.5088L0.1104 12.5824L0.184 11.6976C0.9264 4.0416 4.0416 0.9264 11.6976 0.184L12.5824 0.1104L13.5088 0.0560001C13.6656 0.0480001 13.8256 0.0400001 13.9872 0.0352001L14.9728 0.00800018C15.3088 0.00320018 15.6512 0 16 0Z"
            fill={color}
          />
          <path
            id="Vector_2"
            d="M9.59998 16.7L13.0285 20L22.1714 11.2"
            stroke="white"
            strokeWidth="3.4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1857_827">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
