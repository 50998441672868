import React, { FC, useState } from "react";
import ProjectWorkspace from "@/components/addons/ProjectWorkspace";
import GeneralTab from "@/components/tabs/GeneralTabs";
import { OrgData } from "@/types";
import OrganizationSettingsTab from "../components/pageParts/OrganizationSettingsTab";
import { useSelector } from 'react-redux';
import { RootState } from "@/store/store";
import { useTranslation } from 'react-i18next';



const SettingPage: FC = () => {
  const { t } = useTranslation('SettingPage');
  const org = useSelector((state: RootState) => state.org.userOrg)
  const user = useSelector((state: RootState) => state.user.user);
  const [updateOrg, setUpdateOrg] = useState<OrgData | null>(org);
  const tabs = [
    { name: t("organizationSettingsTab"), key: "organization_settings" },
    { name: t("rolesTab"), key: "Roles" },
    { name: t("usageTab"), key: "Usage" },
    { name: t("billingTab"), key: "Billing" },
  ];
  const [activeTab, setActiveTab] = useState(tabs[0].key);

  

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };


  return (
    <>
      <div  className="mb-6" />
      <ProjectWorkspace color={user.user_type === "super_admin"? org.org_color: "blue"}>
        {/* Tabs */}
        <div className="relative flex border-b border-gray-300 pt-2 pb-0">
          <GeneralTab
            tabs={tabs}
            activeTab={activeTab}
            onTabChange={handleTabChange}
          />
        </div>

        {/* Render the appropriate tab content based on activeTab */}
        <div className="-mt-5">
          {activeTab === "organization_settings" && <OrganizationSettingsTab selectedOrg={updateOrg as OrgData} setSelectedOrg={setUpdateOrg} isColorCard={true}/>}
          {activeTab === "Roles" && <div className="m-9">{t("rolesPlaceholder")}</div>}
          {activeTab === "Usage" && <div className="m-9">{t("usagePlaceholder")}</div>}
          {activeTab === "Billing" && <div className="m-9">{t("billingPlaceholder")}</div>}
        </div>
      </ProjectWorkspace>
    </>
  );
};

export default SettingPage;