import React from "react";

interface Props {
  className: string;
}

export const KalMediaIcon = ({ className }: Props): JSX.Element => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M22.0005 10.3336H22.0138"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 7C2 5.67392 2.52678 4.40215 3.46447 3.46447C4.40215 2.52678 5.67392 2 7 2H27C28.3261 2 29.5979 2.52678 30.5355 3.46447C31.4732 4.40215 32 5.67392 32 7V27C32 28.3261 31.4732 29.5979 30.5355 30.5355C29.5979 31.4732 28.3261 32 27 32H7C5.67392 32 4.40215 31.4732 3.46447 30.5355C2.52678 29.5979 2 28.3261 2 27V7Z"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 23.6666L10.3333 15.3333C11.88 13.845 13.7867 13.845 15.3333 15.3333L23.6667 23.6666"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.3333 20.3331L21.9999 18.6664C23.5466 17.1781 25.4533 17.1781 26.9999 18.6664L31.9999 23.6664"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
