import { useMutation } from '@tanstack/react-query';
import { changeProjectUsersList } from "@/api/userProject"; 
import { UpdateProjectUsersList,ReturnCreateDelete } from "@/types"; 
import { PostParam } from "@/types/index"; 

export function useChangeProjectUsersList(props?: PostParam) {
  const { onSuccess, onError } = props || {};

  return useMutation<ReturnCreateDelete, Error, UpdateProjectUsersList>({
    mutationFn: changeProjectUsersList,
    onSuccess,
    onError
  });
}