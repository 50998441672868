import { createSlice } from '@reduxjs/toolkit';
import secureLocalStorage from 'react-secure-storage';
import { FileData } from '@/types';


interface FilesState {
  responseMessage: string;
  toShowPrompt: boolean;
  isChatResMode: boolean;
  chatWaiting: boolean;
  chatSearchFocus: "audio" | "doc" | "none";
  chatQuery: string;
  responseFilteredFiles: FileData[];
}

const initialState: FilesState = {
  responseMessage: "",
  chatWaiting: false,
  toShowPrompt: true,
  isChatResMode: false,
  chatSearchFocus: "none",
  chatQuery: "",
  responseFilteredFiles: [],
};

const chatSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    setChatState: (state, action) => {
      const { responseMessage, toShowPrompt, isChatResMode, chatSearchFocus, chatWaiting, chatQuery, responseFilteredFiles } = action.payload;

      if (responseMessage !== undefined) {
        state.responseMessage = responseMessage;
        secureLocalStorage.setItem('responseMessage', JSON.stringify(responseMessage) || '');
      }
      if (toShowPrompt !== undefined) {
        state.toShowPrompt = toShowPrompt;
        secureLocalStorage.setItem('toShowPrompt', JSON.stringify(toShowPrompt) || '');
      }
      if (isChatResMode !== undefined) {
        state.isChatResMode = isChatResMode;
        secureLocalStorage.setItem('isChatResMode', JSON.stringify(isChatResMode) || '');
      }
      if (chatSearchFocus !== undefined) {
        state.chatSearchFocus = chatSearchFocus;
        secureLocalStorage.setItem('chatSearchFocus', JSON.stringify(chatSearchFocus) || '');
      }
      if (chatWaiting !== undefined) {
        state.chatWaiting = chatWaiting;
        secureLocalStorage.setItem('chatWaiting', JSON.stringify(chatWaiting) || '');
      }
      if (chatQuery !== undefined) {
        state.chatQuery = chatQuery;
        secureLocalStorage.setItem('chatQuery', JSON.stringify(chatQuery) || '');
      }
      if (responseFilteredFiles !== undefined) {
        state.responseFilteredFiles = responseFilteredFiles;
        secureLocalStorage.setItem('responseFilteredFiles', JSON.stringify(responseFilteredFiles) || '');
      }
    },
    clearChat: (state) => {
      state.responseMessage = "";
      state.toShowPrompt = true;
      state.isChatResMode = false;
      state.chatSearchFocus = "none";
      state.chatWaiting = false;
      state.chatQuery = "";
      state.responseFilteredFiles = [];

      secureLocalStorage.removeItem('responseMessage');
      secureLocalStorage.removeItem('toShowPrompt');
      secureLocalStorage.removeItem('isChatResMode');
      secureLocalStorage.removeItem('chatSearchFocus');
      secureLocalStorage.removeItem('chatWaiting');
      secureLocalStorage.removeItem('chatQuery');
      secureLocalStorage.removeItem('responseFilteredFiles');
    },
  },
});

export const { setChatState, clearChat } = chatSlice.actions;
export default chatSlice.reducer;
