import { GetDeleteOrgData, CreateOrgData ,UpdateOrgData,OrgData,ReturnUpdateDelete,
    CreateLicenseData, ReturnCreateLicense ,GetLicenseData , UpdateLicenseData ,ReturnGetLicense} from "@/types";
import { PostRequest ,PostRequestFormData} from "./requests";

export const getByOrgID = async (data: GetDeleteOrgData): Promise<OrgData> => {
    try {
        return await PostRequest<OrgData>("/api/org/get", data);
    } catch (error) {
        console.log('Error in getByOrgID:', error);
        throw error;
    }
}

export const createOrg = async (data: CreateOrgData) => {
    try {
        return await PostRequestFormData("/api/org/create", data);
    } catch (error) {
        console.log('Error in createOrg:', error);
        throw error;
    }
}

export const getAllOrgs = async (): Promise<OrgData[]> => {
    try {
        return await PostRequest<OrgData[]>("/api/org/get_all", {});
    } catch (error) {
        console.log('Error in getAllOrgs:', error);
        throw error;
    }
}

export const updateOrg = async (data: UpdateOrgData): Promise<ReturnUpdateDelete> => {
    try {
        return await PostRequestFormData("/api/org/update", data);
    } catch (error) {
        console.log('Error in updateOrg:', error);
        throw error;
    }
}

export const deleteOrg = async (data: GetDeleteOrgData): Promise<ReturnUpdateDelete> => {
    try {
       return await PostRequest<ReturnUpdateDelete>("/api/org/delete", data);
    } catch (error) {
        console.log('Error in deleteOrg:', error);
        throw error;
    }
}


export const createLicense = async (data: CreateLicenseData): Promise<ReturnCreateLicense> => {
    try {
        return await PostRequest<ReturnCreateLicense>("/api/org/create_license", data);
    } catch (error) {
        console.log('Error in createLicense:', error);
        throw error;
    }
};

export const getLicense = async (data: GetLicenseData): Promise<ReturnGetLicense> => {
    try {
        return await PostRequest<ReturnGetLicense>("/api/org/get_license", data);
    } catch (error) {
        console.log('Error in getLicense:', error);
        throw error;
    }
};

export const updateLicense = async (data: UpdateLicenseData): Promise<ReturnUpdateDelete> => {
    try {
        return await PostRequest<ReturnUpdateDelete>("/api/org/update_license", data);
    } catch (error) {
        console.log('Error in updateLicense:', error);
        throw error;
    }
};
