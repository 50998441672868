import React, { useState } from 'react';
import { Resizer } from '@/icons/Resizer';

interface TextFieldProps {
  placeholder: string;
  maxCharacterCount?: number;
  text: string;
  setText: (value: string) => void;
}

const TextField: React.FC<TextFieldProps> = ({ placeholder, text, setText, maxCharacterCount = 150 }) => {
  const [isActive, setIsActive] = useState(false);
  
  const borderColorClass = isActive ? 'border-skyBlue-500' : 'border-stormyGray-200';

  const handleBlur = () => {
    setIsActive(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value.length <= maxCharacterCount) {
      setText(event.target.value);
    }
  };

  return (
    <div className="w-[709px] h-40 relative">
      <div className="absolute inset-0 w-[710px] h-[151px]">
        <div className={`absolute left-0 w-full h-[119px] rounded-lg bg-white border ${borderColorClass}`}>
          <textarea
            placeholder={placeholder}
            className="w-full h-full p-2 text-[14px] font-normal leading-[19.2px] text-black resize-none rounded-lg focus:outline-none"
            value={text}
            onChange={handleChange}
            onFocus={() => setIsActive(true)}
            onBlur={handleBlur}
          ></textarea>
          <div className="absolute right-2 bottom-2">
            <Resizer />
          </div>
        </div>
      </div>
      <div className="absolute bottom-4 right-0 text-sm font-medium text-[#00000040] text-right whitespace-nowrap">
        {text.length} / {maxCharacterCount}
      </div>
    </div>
  );
};

export default TextField;