import { useMutation } from '@tanstack/react-query';
import { getAllOrgs } from "../../api/organizationApi";
import { OrgData } from "@/types";
import { PostParam } from "@/types";

export function useGetAllOrgs(props?: PostParam) {
    const { onSuccess, onError } = props || {};

    return useMutation<OrgData[], Error>({
        mutationFn: getAllOrgs,
        onSuccess,
        onError
    });
}
