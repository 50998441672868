import React, { useState, useEffect } from "react";
import styles from "./css/UserLink.module.css";
import { truncateText } from "@/utils/truncateText";
import { LinkIcon } from "@/icons/LinkIcon";
import { LinkUnattachedIcon } from "@/icons/LinkUnattachedIcon";

interface UserLinkProps {
  link: string;
  onClick: () => void;
  className?: string;
  notReady?: boolean;
}

export const UserLink: React.FC<UserLinkProps> = ({
  link,
  onClick,
  className,
  notReady = false,
}) => {
  const [unlink, setUnlink] = useState(false);

  const handleUnlink = () => {
    setUnlink(true);
    onClick();
  };

  useEffect(() => {
    if (unlink) {
      const timer = setTimeout(() => {
        setUnlink(false); // Reset unlink state after 3 seconds
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [unlink]);

  return (
    <div className={`${className} ${styles.container} ${notReady ? styles.notReady : ""}`}>
      <div className={`${styles.link}`}>
        <p className={`${styles.text} ${notReady ? styles.notReady : ""}`}>
          {truncateText(link, 30)}
        </p>
        <div onClick={handleUnlink}>
          {!unlink && (
            <LinkIcon className={`${styles.icon} ${notReady ? styles.notReady : ""}`} />
          )}
        </div>
        {unlink && <LinkUnattachedIcon className={styles.icon} />}
      </div>
    </div>
  );
};
