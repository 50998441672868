import React from "react";

interface Props {
  className: string;
}

export const TooltipIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.54967 11.333H8.54967V7.33301H7.54967V11.333ZM7.99937 6.09967C8.15513 6.09967 8.28578 6.04856 8.39134 5.94634C8.49689 5.84412 8.54967 5.71745 8.54967 5.56634C8.54967 5.40578 8.49699 5.2712 8.39164 5.16259C8.28627 5.05398 8.15572 4.99967 7.99997 4.99967C7.84422 4.99967 7.71356 5.05398 7.60801 5.16259C7.50245 5.2712 7.44967 5.40578 7.44967 5.56634C7.44967 5.71745 7.50235 5.84412 7.60771 5.94634C7.71307 6.04856 7.84363 6.09967 7.99937 6.09967ZM8.00411 14.6663C7.08484 14.6663 6.22095 14.4913 5.41244 14.1413C4.60393 13.7913 3.8969 13.3136 3.29134 12.708C2.68579 12.1024 2.20801 11.395 1.85801 10.5857C1.50801 9.77632 1.33301 8.91155 1.33301 7.99134C1.33301 7.07113 1.50801 6.20636 1.85801 5.39702C2.20801 4.58768 2.68579 3.88301 3.29134 3.28301C3.8969 2.68301 4.60435 2.20801 5.41369 1.85801C6.22302 1.50801 7.08779 1.33301 8.00801 1.33301C8.92822 1.33301 9.79299 1.50801 10.6023 1.85801C11.4117 2.20801 12.1163 2.68301 12.7163 3.28301C13.3163 3.88301 13.7913 4.58856 14.1413 5.39967C14.4913 6.21078 14.6663 7.07597 14.6663 7.99524C14.6663 8.91451 14.4913 9.77839 14.1413 10.5869C13.7913 11.3954 13.3163 12.1014 12.7163 12.7049C12.1163 13.3084 11.4108 13.7862 10.5997 14.1383C9.78856 14.4903 8.92337 14.6663 8.00411 14.6663ZM8.00801 13.6663C9.58023 13.6663 10.9163 13.1136 12.0163 12.008C13.1163 10.9024 13.6663 9.56356 13.6663 7.99134C13.6663 6.41912 13.1174 5.08301 12.0195 3.98301C10.9215 2.88301 9.58162 2.33301 7.99967 2.33301C6.43301 2.33301 5.0969 2.88196 3.99134 3.97987C2.88578 5.0778 2.33301 6.41773 2.33301 7.99967C2.33301 9.56634 2.88578 10.9024 3.99134 12.008C5.0969 13.1136 6.43578 13.6663 8.00801 13.6663Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.2"
        strokeLinejoin="round"
      />
    </svg>
  );
};
