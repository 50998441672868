import { PostRequest } from "./requests";
import { LoginData, ResetPasswordData } from "@/types";

export const login = async (data: LoginData) => {
    try {
        return await PostRequest("/login", data);
    } catch (error) {
        console.log('Error in Login:', error);
        throw error;
    }
}

export const resetPassword = async (data: ResetPasswordData) => {
    try {
        return await PostRequest("/reset_password", data);
    } catch (error) {
        console.log('Error in reset password:', error);
        throw error;
    }
}