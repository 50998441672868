import React from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';

const LoginRoute: React.FC = () => {
  const { orgName } = useParams<{ orgName: string }>();
  const isAuthenticated = useSelector((state: RootState) => state.user.userAuth);

  return isAuthenticated ? <Navigate to={`/${orgName}/dashboard`} /> : <Outlet />;
};

export default LoginRoute;