// src/store/store.ts
import { configureStore } from '@reduxjs/toolkit';
import userSlice from './userSlice';
import orgSlice from './orgSlice';
import projectsSlice from "./projectsSlice"
import systemServiceSlice from './systemServiceSlice';
import systemSlice from './systemSlice';
import filesSlice from './filesSlice';
import chatSlice from './chatSlice';
const store = configureStore({
  reducer: {
    user: userSlice,
    org: orgSlice,
    projects: projectsSlice,
    systemService: systemServiceSlice,
    system: systemSlice,
    files: filesSlice,
    chat: chatSlice
  },
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware().concat(MIDDLEWARE NAME IF WE WILL USE THAT),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
