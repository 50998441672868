import React, { useEffect, useRef, useState } from "react";
import { CustomerClientField } from "../inputFields/CustomerClientField";
import { mockTransactionResult } from "@/mocks/file";
import { mockRuleResults } from "@/mocks/rules";
import styles from "./css/AudioView.module.css";
import { SpeackerIcon } from "@/icons/SpeakerIcon";
import { EditResultsIcon } from "@/icons/EditResultIcon";
import { DownloadFileIcon } from "@/icons/DownloadFileIcon";
import { FileData, TaskData, Word } from "@/types";
import { Sentimental } from "./Sentimental";
import { FullScreenIcon } from "@/icons/FullScreenIcon";
import { BussinessRuleAcordion } from "../acordion/BussinessRuleAcordion";
import { RuleResults } from "@/types/rules";
import { useTranslation } from 'react-i18next';

interface Props {
  file: FileData;
  task: TaskData | undefined;
}

export const AudioView: React.FC<Props> = ({ file, task }) => {
  const [currentTime, setCurrentTime] = useState("00:00:00:000");
  const [currentPress, setCurrentPress] = useState("00:00:00:000");
  const audioRef = useRef<HTMLAudioElement>(null);
  const [editMode, setEditMode] = useState(false);
  const [editSpeacker, setEditSpeacker] = useState(false);
  const { t } = useTranslation('AudioView');
  const [transactionResult, setTransactionResult] = useState(
    mockTransactionResult
  );
  const [duration, setDuration] = useState(0);
  const [speackerA, setSpeackerA] = useState("Client");
  const [speackerB, setSpeackerB] = useState("Agent");

  //   const handleTimeUpdate = () => {
  //     if (audioRef.current) {
  //       const current = audioRef.current.currentTime;
  //       const hours = Math.floor(current / 3600);
  //       const minutes = Math.floor((current % 3600) / 60);
  //       const seconds = Math.floor(current % 60);
  //       const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  //       setCurrentTime(formattedTime);
  //     }
  //   };

  useEffect(() => {
    if(audioRef && audioRef.current){
        setDuration(audioRef.current.duration);
    }
  }, [audioRef])

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      const current = audioRef.current.currentTime;
      const hours = Math.floor(current / 3600);
      const minutes = Math.floor((current % 3600) / 60);
      const seconds = Math.floor(current % 60);
      const milliseconds = Math.floor((current % 1) * 1000);

      const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}:${milliseconds.toString().padStart(3, "0")}`;

      setCurrentTime(formattedTime);
    }
  };

  useEffect(() => {
    if (audioRef.current) {
      const [hours, minutes, seconds, milliseconds] = currentPress
        .split(":")
        .map(Number);
      const newTimeInSeconds =
        hours * 3600 + minutes * 60 + seconds + milliseconds / 1000;
    //   if (newTimeInSeconds <= duration) {
    //     console.log(newTimeInSeconds)
    //     console.log(duration)
        audioRef.current.currentTime = newTimeInSeconds;
    //   }
    //   else {
    //     if(newTimeInSeconds > duration && duration !== 0){
    //         console.log("here")
    //         setCurrentTime("00:00:00:000");
    //         audioRef.current.pause();
    //     }
    //   }
    }
  }, [currentPress]);

  const handleNameChange = (speacker: string, value: string) => {
    speacker === "client" ? setSpeackerA(value) : setSpeackerB(value);
    // edit the data in the db
  };

  const handleEnd = () => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
    console.log("here")
    setCurrentTime("00:00:00:000");
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener('ended', handleEnd);
      return () => {
        audioRef.current?.removeEventListener('ended', handleEnd);
      };
    }
  }, []);

  const handleTextChange = (speacker: string, words: Word[]) => {
    setTransactionResult((prev) => ({
      ...prev,
      [speacker]: words,
    }));
  };

  const handleDowlonadTransc = () => {
    console.log("here");
    const json = JSON.stringify(transactionResult, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${file.name}.json`;
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <div className={styles.container}>
      <div className={styles.conversationContainer}>
        <div className={styles.actions}>
          <div className={styles.actionsIcons}>
            <div
              className={styles.icon}
              onClick={() => setEditSpeacker(!editSpeacker)}
            >
              <SpeackerIcon />
            </div>
            <div className={styles.icon} onClick={() => setEditMode(!editMode)}>
              <EditResultsIcon />
            </div>
            <div className={styles.icon} onClick={() => handleDowlonadTransc()}>
              <DownloadFileIcon />
            </div>
          </div>
        </div>
        <div className={styles.conversation}>
          {task &&
            task.results?.transcription &&
            Object.entries(task.results.transcription).map(
              ([role, words], index) => {
                const role_type = role.split("_")[0].toLocaleLowerCase();
                return (
                  <CustomerClientField
                    key={index}
                    type={role_type as "client" | "customer"}
                    name={role_type === "client" ? speackerA : speackerB}
                    text={words as Word[]}
                    currentAudioTime={currentTime}
                    setText={(words) => handleTextChange(role_type, words)}
                    setName={(value) => handleNameChange(role_type, value)}
                    direction={role_type === "client" ? "rtl" : "ltr"}
                    editMode={editMode}
                    editNameMode={editSpeacker}
                    setCurrentTime={setCurrentPress}
                  />
                );
              }
            )}
        </div>
        <div className={styles.fileContainer}>
          <audio
            controls
            ref={audioRef}
            onTimeUpdate={handleTimeUpdate}
            onEnded={handleEnd}
            className={styles.audioPlayer}
          >
            <source src={file?.url || ""} type={file?.type || ""} />
            {t('messageBrowserSupport')}
          </audio>
        </div>
      </div>
      <div className={styles.resultsContainer}>
        <div className={styles.summTitle}>{t('summTitle')}</div>
        <div className={styles.summResults}>
          <textarea
            className={styles.nonEditableTextarea}
            readOnly
            dir={task?.results?.summary?.charAt(0).match(/[\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC]/) ? 'rtl' : 'ltr'}
            value={task && task.results?.summary ? task.results.summary : ""}
          />
          <div className={styles.summResult}>
            <div className={styles.callDuration}>
            {t('callDuration')}{" "}
              <div className={styles.duration}>{file?.duration}</div>
            </div>
            <Sentimental sentiment="happy" />
            <div className={styles.summActions}>
              <div className={styles.summActionIcon} onClick={() => {}}>
                <DownloadFileIcon />
              </div>
              <div className={styles.summActionIcon} onClick={() => {}}>
                <FullScreenIcon />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rules}>
          <div className={styles.rulesTitle}>{t('rulesTitle')}</div>
          <div className={styles.rules}>
            {task?.results?.business_rules?.map((rule: RuleResults) => (
              <BussinessRuleAcordion
                key={rule.title || ""}
                rule={rule}
                setCurrentTime={setCurrentPress}
              />
            ))}
          </div>
          <div className={styles.bottom}>
            <div className={styles.summActions}>
              <div className={styles.summActionIcon} onClick={() => {}}>
                <DownloadFileIcon />
              </div>
              <div className={styles.summActionIcon} onClick={() => {}}>
                <FullScreenIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
