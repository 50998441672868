import React from "react";

interface Props {
  className?: string;
}

export const FullScreenIcon = ({ className }: Props): JSX.Element => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_78_11141)">
        <path
          d="M2 11.3337C2 11.1568 2.07024 10.9873 2.19526 10.8623C2.32029 10.7372 2.48986 10.667 2.66667 10.667H4.66667C4.84348 10.667 5.01305 10.7372 5.13807 10.8623C5.2631 10.9873 5.33333 11.1568 5.33333 11.3337V13.3337C5.33333 13.5105 5.2631 13.68 5.13807 13.8051C5.01305 13.9301 4.84348 14.0003 4.66667 14.0003H2.66667C2.48986 14.0003 2.32029 13.9301 2.19526 13.8051C2.07024 13.68 2 13.5105 2 13.3337V11.3337Z"
          stroke="#5C5D71"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.6665 8.00033V4.00033C2.6665 3.6467 2.80698 3.30756 3.05703 3.05752C3.30708 2.80747 3.64622 2.66699 3.99984 2.66699H11.9998C12.3535 2.66699 12.6926 2.80747 12.9426 3.05752C13.1927 3.30756 13.3332 3.6467 13.3332 4.00033V12.0003C13.3332 12.3539 13.1927 12.6931 12.9426 12.9431C12.6926 13.1932 12.3535 13.3337 11.9998 13.3337H7.99984"
          stroke="#5C5D71"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 5.33301H10.6667V7.99967"
          stroke="#5C5D71"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.6668 5.33301L7.3335 8.66634"
          stroke="#5C5D71"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_78_11141">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
