import React from "react";
import styles from "./css/StatusPage.module.css";
import NotFoundNumber from "@/assets/404.png";
import NotFoundDraw from "@/assets/404Draw.png"
import { useTranslation } from 'react-i18next';

export const NotFoundPage: React.FC = () => {
  const { t } = useTranslation('NotFoundPage');
  return (
    <div className={styles.container}>
      <div className={styles.boldTitle}>{t('title')}</div>
      <div className={styles.title}>
        {t('subtitle')}
      </div>
      <div className={styles.numberImage}>
        <img src={NotFoundNumber} alt="404" />
      </div>
      <div className={styles.drawImage}>
        <img src={NotFoundDraw}/>
      </div>
    </div>
  );
};
