import React, { useState } from "react";
import ProjectWorkspace from "@/components/addons/ProjectWorkspace";
import { CreateOrgData } from "@/types";
import NewOrganization from "@/components/pageParts/NewOrganization";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";

const NewOrganizationsPage: React.FC = () => {
  const adminRole = useSelector((state: RootState) => state.systemService.roles).find((role) => role.name === "admin");
  const [createOrg, setCreateOrg] = useState<CreateOrgData>({
    org_description: "",
    org_name: "",
    org_color: "blue",
    domain: "",
    admin_email: "",
    logo: null,
    user_name: "",
    first_name: "",
    last_name: "",
    role_id: adminRole?.id || ""
  });

  return (
    <>
      <div className="flex flex-col items-center px-0 py-2 justify-center relative h-[40px]" />
      <ProjectWorkspace color={createOrg.org_color}>
        <div className="-mt-5">
          <NewOrganization org={createOrg} setOrg={setCreateOrg}/>
        </div>
      </ProjectWorkspace>
    </>
  );
};

export default NewOrganizationsPage;
