import { useMutation } from '@tanstack/react-query';
import { createUser } from "@/api/user"; 
import { CreateUser,ReturnCreateUser } from "@/types"; 
import { PostParam } from "@/types/index"; 

export function useCreateUser(props?: PostParam) {
  const { onSuccess, onError } = props || {};

  return useMutation<ReturnCreateUser, Error, CreateUser>({
    mutationFn: createUser,
    onSuccess,
    onError
  });
}