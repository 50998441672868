import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import React, { useState } from "react";
import styles from "./css/BussinessRuleAcordion.module.css";
import { RuleResults } from "@/types/rules";

interface Props {
  rule: RuleResults;
  setCurrentTime: (val: string) => void;
}

const color = {
  ValueExtraction: "green",
  WordCount: "red",
  PhraseMatch: "blue",
  TopicCheck: "orange",
  ValueExtractionWithTest: "purple",
  SequenceMatch: "mint",
};

export const BussinessRuleAcordion = ({
  rule,
  setCurrentTime,
}: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const direction = rule.title
    .charAt(0)
    .match(/[\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC]/)
    ? "rtl"
    : "ltr";

  return (
    <div className={styles.accordion} onClick={toggleAccordion}>
      <div className={styles.accordionTitle} dir={direction}>
        {rule.title.split("'").map((segment, index) => (
          <React.Fragment key={index}>
            <span className={index % 2 === 1 ? styles[color[rule.type]] : ""}>
              {segment}
            </span>
            {index < rule.title.split("'").length - 1 && <span></span>}
          </React.Fragment>
        ))}
        <div className={`${styles.openIcon} ${styles[direction]}`}>
          {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </div>
      </div>
      {isOpen && (
        <div className={styles.accordionContent}>
          {Object.entries(rule.results).map(([key, value], index) => (
            <div
              key={index}
              className={`${styles.contentResult} ${styles[direction]}`}
              onClick={(e) => {
                e.stopPropagation();
                setCurrentTime(key);
              }}
            >
              {direction === "ltr" ? (
                <>
                  <div className={styles.time}>{key.substring(0, 8)}</div>
                  <div className={styles.sentence}>{value}</div>
                </>
              ) : (
                <>
                  <div className={styles.sentence}>{value}</div>
                  <div className={styles.time}>{key.substring(0, 8)}</div>
                </>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
