import { PostRequest } from "./requests";
import { RerunGetTask } from "@/types";

export const getTask = async (data: RerunGetTask) => {
    try {
        return await PostRequest("/api/tasks/get", data);
    } catch (error) {
        console.log('Error in runEvaluation:', error);
        throw error;
    }
}

export const RerunTask = async (data: RerunGetTask) => {
    try {
        return await PostRequest("/api/tasks/rerun", data);
    } catch (error) {
        console.log('Error in runEvaluation:', error);
        throw error;
    }
}