// src/components/RouteGuard.tsx
import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { ProductTypes } from "@/types";

const VALID_PRODUCT_TYPES: ProductTypes[] = [
  "KalAudio",
  "KalDocs",
  "KalChat",
  "KalMedia",
];

interface RouteGuardProps {
  children: React.ReactNode;
}

export const ProductGuard: React.FC<RouteGuardProps> = ({ children }) => {
  const { projectType } = useParams<{ projectType: string }>();

  if (
    !projectType ||
    !VALID_PRODUCT_TYPES.includes(projectType as ProductTypes)
  ) {
    return <Navigate to="/404" />;
  }

  return <>{children}</>;
};
