import React from "react";
import styles from "./css/RadioButton.module.css";

interface RadioButtonProps {
  className?: string;
  title: string;
  selected: string;
  onClick: (selected: string) => void;
  id: string;
  titleClass?: string
}

export const RadioButton: React.FC<RadioButtonProps> = ({
  className,
  selected,
  id,
  title,
  onClick,
  titleClass = ""
}) => {
  return (
    <div className={styles.wrapper}>
      <div
        className={`${styles.container} ${className}`}
        onClick={() => onClick(id)}
      >
        <div
          className={`${styles.radio} ${selected === id ? styles.on : styles.off}`}
        >
          {selected === id && <div className={`${styles.innerOn}`}></div>}
        </div>
      </div>
      <div className={titleClass}>{title}</div>
    </div>
  );
};
