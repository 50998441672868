import React, { useEffect, useRef, useState } from "react";

interface Tab {
  name: string;
  key: string;
}

interface TabsProps {
  tabs: Tab[];
  activeTab: string;
  onTabChange: (key: string) => void;
}

const GeneralTab: React.FC<TabsProps> = ({ tabs, activeTab, onTabChange }) => {
  const [underlineWidth, setUnderlineWidth] = useState(0);
  const activeTabRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (activeTabRef.current) {
      setUnderlineWidth(activeTabRef.current.offsetWidth);
    }
  }, [activeTab]);

  return (
    <div className="relative flex">
      {tabs.map((tab) => (
        <div
          key={tab.key}
          className="flex flex-col items-center justify-center cursor-pointer px-2 h-6 relative mx-2"
          onClick={() => onTabChange(tab.key)}
        >
          <div
            ref={activeTab === tab.key ? activeTabRef : null}
            className={`text-base font-medium text-center whitespace-nowrap leading-[19.2px] ${
              activeTab === tab.key ? "text-[#1890ff]" : "text-black"
            }`}
          >
            {tab.name}
          </div>
          {activeTab === tab.key && (
            <div
              className="absolute bottom-0 h-[2px] bg-[#1890ff]"
              style={{ width: `${underlineWidth}px`, left: `calc(50% - ${underlineWidth / 2}px)` }}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default GeneralTab;
