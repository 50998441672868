import React from "react";

interface Props {
  className: string;
}

export const ClientIcon = ({ className }: Props): JSX.Element => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="28" height="28" rx="14" fill="#E6EDFF" />
      <g clipPath="url(#clip0_2036_1601)">
        <path
          d="M13.9999 5.6665C14.824 5.6665 15.6296 5.91087 16.3148 6.36871C17 6.82655 17.5341 7.4773 17.8494 8.23866C18.1648 9.00001 18.2473 9.83779 18.0865 10.646C17.9258 11.4543 17.5289 12.1967 16.9462 12.7794C16.3635 13.3622 15.621 13.759 14.8128 13.9198C14.0045 14.0805 13.1668 13.998 12.4054 13.6827C11.644 13.3673 10.9933 12.8333 10.5355 12.148C10.0776 11.4628 9.83325 10.6573 9.83325 9.83317L9.83742 9.65234C9.88402 8.57963 10.3429 7.56632 11.1184 6.82372C11.894 6.08112 12.9262 5.66657 13.9999 5.6665Z"
          fill="#4880FF"
        />
        <path
          d="M15.6667 15.6665C16.7718 15.6665 17.8316 16.1055 18.613 16.8869C19.3944 17.6683 19.8334 18.7281 19.8334 19.8332V20.6665C19.8334 21.1085 19.6578 21.5325 19.3453 21.845C19.0327 22.1576 18.6088 22.3332 18.1667 22.3332H9.83341C9.39139 22.3332 8.96746 22.1576 8.6549 21.845C8.34234 21.5325 8.16675 21.1085 8.16675 20.6665V19.8332C8.16675 18.7281 8.60573 17.6683 9.38714 16.8869C10.1685 16.1055 11.2283 15.6665 12.3334 15.6665H15.6667Z"
          fill="#4880FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_2036_1601">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
