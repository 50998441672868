import React from "react";

interface Props {
  className: string;
}

export const XIcon = ({ className }: Props): JSX.Element => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11 11H9.93019L6.00453 6.3078L2.02448 11H1L5.46056 5.80164L1.39891 1H2.45966L6.0408 5.30233L9.64914 1H10.6736L6.58477 5.7948L11 11Z"
        fill="#133C99"
        stroke="#133C99"
        stroke-linejoin="round"
      />
    </svg>
  );
};
