import React from "react";
import styles from "./css/FileHeader.module.css";
import { FileData } from "@/types";
import { ProjectTag } from "../tags/ProjectTag";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";

interface Props {
    file: FileData;
}

export const FileHeader: React.FC<Props> = ({ file }) => {
    const allProjects = useSelector((state: RootState) => state.projects.projects)
    const project = allProjects.find((project) => project.id === file.project_id)

  return (
    <div className={`${styles.container} text-white`}>
      <ProjectTag color={project?.color || "green"} name={project?.name || "תמלול ועדה רפואית"} size="lg"/>
      <div className={styles.title}>File Name</div>
      <div className={styles.value}>{file?.name}</div>
      <div className={styles.title}>Date</div>
      <div className={styles.value}>{file?.created_at.split(" ")[0]}</div>
    </div>
  );
};


