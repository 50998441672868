import React, { useEffect, useState } from "react";
import { UploadPictureCard } from "@/components/cards/UploadPictureCard";
import { InputField } from "@/components/inputFields/inputFields";
import TextFeild from "@/components/inputFields/TextFeild";
import ColorSelectionCard from "@/components/cards/ColorSelectionCard";
import { Button } from "@/components/Buttons";
import AdminUserFormCard from "../cards/AdminUserFormCard";
import { useNavigate } from "react-router-dom";
import { CreateOrgData, orgColorOptions } from "@/types";
import { useCreateOrg } from "@/hooks/organization/useCreateOrg";
import { useDispatch, useSelector } from "react-redux";
import { setToast } from "@/store/systemSlice";
import { useFetchAllOrgs } from "@/utils/fetchData";
import { RootState } from "@/store/store";
import { isValidDomain, isValidEmail } from "@/utils/validations";
import { useTranslation } from 'react-i18next';


interface Props {
  org: CreateOrgData;
  setOrg: React.Dispatch<React.SetStateAction<CreateOrgData>>;
}

const NewOrganization: React.FC<Props> = ({ org, setOrg }) => {
  const { t } = useTranslation('NewOrganization');
  const [toNavigate, setToNavigate] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isCreatingAndEntering, setIsCreatingAndEntering] = useState(false);
  const [disableForm, setDisableForm] = useState(true);
  const [errors, setErrors] = useState({
    domain: "",
    admin_email: "",
    org_name: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getAllOrgs = useFetchAllOrgs();
  const userOrg = useSelector((state: RootState) => state.org.userOrg);
  const orgs = useSelector((state: RootState) => state.org.orgs);

  const createOrg = useCreateOrg({
    onSuccess(data) {
      try {
        // Wait for both the org creation and fetching all orgs to complete
        getAllOrgs.mutate();

        dispatch(
          setToast({
            toastTitle: "Organization created successfully",
            toastMessage: ``,
            toastType: "success",
          })
        );

        if (toNavigate && data.org_id) {
          navigate(`/${userOrg.org_name}/organization/settings/${data.org_id}`);
        } else {
          navigate(`/${userOrg.org_name}/dashboard`);
        }
      } catch (error) {
        console.error("Error updating orgs or navigating:", error);
        dispatch(
          setToast({
            toastTitle: "New organization created successfully",
            toastMessage: `Error updating organization list`,
            toastType: "error",
          })
        );
      } finally {
        setIsCreating(false);
        setIsCreatingAndEntering(false);
      }
    },
    onError(error) {
      dispatch(
        setToast({
          toastTitle: "Failed to create new organization",
          toastMessage: error.response.data.message,
          toastType: "error",
        })
      );
      setIsCreating(false);
      setIsCreatingAndEntering(false);
    },
  });

  const handleCreate = (shouldNavigate: boolean) => {
    if (shouldNavigate) {
      setIsCreatingAndEntering(true);
    } else {
      setIsCreating(true);
    }
    setToNavigate(shouldNavigate);

    createOrg.mutateAsync(org);
  };
  const handleUploadFile = (file: File | null) => {
    setOrg((prev: CreateOrgData) => ({
      ...prev,
      logo: file,
    }));
  };

  const handleUpdate = (field: string, value: string) => {
    let valid = true;
    if (field === "domain" && value !== "") {
      if (!isValidDomain(value)) {
        setErrors((prev) => ({
          ...prev,
          [field]: t("domainError"),
        }));
        valid = false;
      }
    }
    if (field === "admin_email" && value !== "") {
      if (!isValidEmail(value)) {
        setErrors((prev) => ({
          ...prev,
          [field]: t("emailError"),
        }));
        valid = false;
      }
    }
    if (field === "org_name" && value !== "") {
      const nameFound = orgs.find(
        (org) => org.org_name.toLowerCase() === value.toLocaleLowerCase()
      );
      if (nameFound !== undefined) {
        setErrors((prev) => ({
          ...prev,
          [field]: t("nameError"),
        }));
        valid = false;
      }
    }
    if (valid) {
      setErrors((prev) => ({
        ...prev,
        [field]: "",
      }));
    }
    setOrg((prev: CreateOrgData) => ({
      ...prev,
      [field]: value,
    }));
  };

  useEffect(() => {
    checkSave()
  },[org, errors])

  const checkSave = () => {
    if (
      org.admin_email === "" ||
      org.domain === "" ||
      org.org_name === "" ||
      org.first_name === "" ||
      org.last_name === "" ||
      org.user_name === "" ||
      Object.values(errors).some((error) => error !== "")
    ) {
      setDisableForm(true)
    }
    else {
      setDisableForm(false)
    }
  };

  return (
    <div className="p-6 sm:p-9">
      <h2 className="form-title-text mb-3">{t("title")}</h2>

      <div className="flex flex-col md:flex-row gap-6 mb-2">
        <div className="w-full md:w-1/4">
          <div className="mb-6">
            <h3 className="form-label">{t("subtitle")}</h3>
            <UploadPictureCard
              isOrgUpload={true}
              file={org.logo ?? null}
              setFile={handleUploadFile}
              orgColor={org.org_color}
              setAction={() => {}}
            />
          </div>
          <div>
            <h3 className="form-label">{t("selectColor")}</h3>
            <ColorSelectionCard
              selectedColor={org.org_color}
              setSelectedColor={(color: orgColorOptions) =>
                setOrg((prev: CreateOrgData) => ({ ...prev, org_color: color }))
              }
            />
          </div>
        </div>
        <div className="w-full md:w-2/3">
          <div className="flex flex-col md:flex-row md:items-start gap-4 md:gap-2">
            <div className="w-full md:w-1/2">
              <div className="mb-4">
                <h3 className="form-label">{t("organizationName")}</h3>
                <InputField
                  id="orgName"
                  inputType="text"
                  data={org.org_name}
                  setData={(value) => handleUpdate("org_name", value)}
                  errorMessage={errors["org_name"]}
                />
              </div>
              <div>
                <h3 className="form-label">{t("description")}</h3>
                <TextFeild
                  text={org.org_description}
                  setText={(value) => handleUpdate("org_description", value)}
                  placeholder={t("descriptionPlaceholder")}
                />
                <AdminUserFormCard
                  adminDetails={org}
                  onAdminDetailsChange={handleUpdate}
                  errorMessage={errors["admin_email"]}
                />
              </div>
            </div>

            <div className="w-full md:w-1/2">
              <h3 className="form-label">{t("domain")}</h3>
              <InputField
                id="orgID"
                inputType="text"
                data={org.domain}
                setData={(value) => handleUpdate("domain", value)}
                errorMessage={errors.domain}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full mt-4 flex justify-center pl-[200px] flex-row gap-4">
        <Button
          icon="no"
          onClick={() => handleCreate(false)}
          text={t("organizationButton")}
          disabled={isCreating || isCreatingAndEntering || disableForm}
          loading={isCreating}
        />
        <Button
          icon="no"
          onClick={() => handleCreate(true)}
          text={t("enterSettingButton")}
          ready={true}
          disabled={isCreating || isCreatingAndEntering || disableForm}
          loading={isCreatingAndEntering}
        />
      </div>
    </div>
  );
};

export default NewOrganization;
