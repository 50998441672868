import React, { useRef, useState } from "react";
import Navbar from "../components/bars/Navbar";
import SideBar from "../components/bars/SideBar";
import Footer from "../components/bars/Footer";
import ChatHeader  from "../components/chat/ChatHeader";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styles from "./css/Layout.module.css";
import { RootState } from "@/store/store";
import { useUploadFile } from "@/hooks/files/useUploadFile";
import { useFetchAllFiles } from "@/utils/fetchData";
import { setToast } from "@/store/systemSlice";

const Layout: React.FC = () => {
  const location = useLocation();
  const decodedPathname = decodeURIComponent(location.pathname);
  const { orgName } = useParams<{ orgName: string }>();
  const user = useSelector((state: RootState) => state.user.user);
  const orgId = useSelector((state: RootState) => state.org.userOrg?.id);
  const projectId = useSelector((state: RootState) => state.projects.projects[0]?.id);
  const dispatch = useDispatch();
  const getAllFiles = useFetchAllFiles();
  
  const uploadFile  = useUploadFile({
    onSuccess(data) {
      dispatch(setToast({
        toastTitle: "Upload File",
        toastMessage: "File uploaded successfully",
        toastType: "success",
      }));
    },
    onError(error) {
      dispatch(setToast({
        toastTitle: "Upload File",
        toastMessage: "File upload failed",
        toastType: "error",
      }));
    },
  });

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && orgId && projectId) {
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      let currentProduct: 'KalDocs' | 'KalAudio' | 'KalMedia';

      if (['csv', 'pdf', 'docx'].includes(fileExtension || '')) {
        currentProduct = 'KalDocs';
      } else if (['png', 'jpg', 'jpeg'].includes(fileExtension || '')) {
        currentProduct = 'KalMedia';
      } else {
        currentProduct = 'KalAudio';
      }

      uploadFile.mutate({
        project_id: projectId,
        org_id: orgId,
        product: currentProduct,
        name: file.name,
        file: file,
      });

      getAllFiles.mutate({
        project_id: projectId,
        org_id: orgId,
      });

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };
  
  const navType =
    decodedPathname === `/${orgName}` || decodedPathname === `/${orgName}/`
      ? "logo"
      : user.user_type === "super_admin"
        ? "super_admin"
        : user.user_type === "admin"
          ? "org_user"
          : user.user_type === "user"
            ? "user"
            : "logo";

  const shouldShowSideBar =
    decodedPathname !== `/${orgName}/` &&
    decodedPathname !== `/${orgName}` &&
    !decodedPathname.startsWith("/404") &&
    !decodedPathname.startsWith("/403") &&
    navType !== "logo";

  const shouldShowChatHeader =
    user.user_type === "user" && (
    decodedPathname.startsWith(`/${orgName}/dashboard`) ||
    decodedPathname.startsWith(`/${orgName}/doc/files`)||
    decodedPathname.startsWith(`/${orgName}/audio/files`) ||
    decodedPathname.startsWith(`/${orgName}/media/files`)
    )

  return (
    <div className="flex flex-col h-screen">
      <Navbar type={navType} />
      <div className="flex flex-1 overflow-hidden">
        {shouldShowSideBar && <SideBar type="Dashboard" />}
        <div className={`flex flex-col flex-1 overflow-hidden ${styles.inner}`}>
          <div className="flex-1 overflow-auto">
            <Outlet />
          </div>
          {shouldShowChatHeader && (
            <div className="relative">
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileUpload}
                accept=".wav,.ogg,.mp3,.csv,.pdf,.docx,.png,.jpg,.jpeg"
                className="hidden"
              />
              <button
                className="absolute bottom-full right-4 mb-4 w-12 h-12 bg-sky-500 text-white rounded-full flex items-center justify-center shadow-lg hover:bg-sky-600 transition-colors duration-300"
                onClick={triggerFileInput}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                </svg>
              </button>
              <ChatHeader user={user}/>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
