import React from "react";
import styles from "./css/ProjectHeader.module.css";

interface Props {
    orgColor: string;  
    orgName: string;
    className?: string;
}

export const Header: React.FC<Props> = ({ orgColor, orgName, className }) => {
  return (
    <div className={`${styles.container} ${orgColor ? styles[orgColor] : '!bg-skyBlue-500'} ${className} text-white`}>
      {orgName}
    </div>
  );
};


