import { PostRequest } from "./requests";
import {GetRolesData , GeneralActionRoleData , RoleData } from "@/types"; 

export const getRoles = async (data: GetRolesData): Promise<RoleData[]> => {
    try {
       return await PostRequest<RoleData[]>("/api/system_service/get_roles", data);
    } catch (error) {
        console.log('Error in getRoles:', error);
        throw error;
    }
};

export const getRole = async (data: GeneralActionRoleData): Promise<RoleData> => {
    try {
       return await PostRequest<RoleData>("/api/system_service/get_role", data);
    } catch (error) {
        console.log('Error in getRole:', error);
        throw error;
    }
};