import React from "react";
import styles from "./css/ChatPrompt.module.css";

interface Prompt {
  id: string;
  text: string;
}

interface ChatPromptProps {
  prompt: Prompt;
  onPromptClick: (promptId: string, promptText: string) => void;
}

const ChatPrompt: React.FC<ChatPromptProps> = ({ prompt, onPromptClick }) => {
  const boldWords = ["disconnect", "switch", "calls", "call", "word", "yelled"];

  return (
    <div
      className={styles.container}
      onClick={() => onPromptClick(prompt.id, prompt.text)}
    >
      <p className={styles.promptText}>
        {prompt.text
          .split(" ")
          .map((word, index) =>
            boldWords.includes(word.toLowerCase()) ? (
              <strong key={index}>{word} </strong>
            ) : (
              word + " "
            )
          )}
      </p>
    </div>
  );
};

export default ChatPrompt;
