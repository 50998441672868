import React from "react";

interface MingcuteWarningLineProps {
  className?: string;
  color?: string;
}

export const MingcuteWarningLine: React.FC<MingcuteWarningLineProps> = ({
  className,
  color = "#2F54EB",
}) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ position: "relative", top: "-4px" }}
    >
      <g id="mingcute:warning-line" clipPath="url(#clip0_1549_10388)">
        <g id="Group">
          <path
            id="Vector"
            d="M7.99995 1.33325C11.6819 1.33325 14.6666 4.31792 14.6666 7.99992C14.6666 11.6819 11.6819 14.6666 7.99995 14.6666C4.31795 14.6666 1.33328 11.6819 1.33328 7.99992C1.33328 4.31792 4.31795 1.33325 7.99995 1.33325ZM7.99995 2.66658C6.58546 2.66658 5.22891 3.22849 4.22871 4.22868C3.22852 5.22888 2.66662 6.58543 2.66662 7.99992C2.66662 9.4144 3.22852 10.771 4.22871 11.7712C5.22891 12.7713 6.58546 13.3332 7.99995 13.3332C9.41443 13.3332 10.771 12.7713 11.7712 11.7712C12.7714 10.771 13.3333 9.4144 13.3333 7.99992C13.3333 6.58543 12.7714 5.22888 11.7712 4.22868C10.771 3.22849 9.41443 2.66658 7.99995 2.66658ZM7.99995 9.99992C8.17676 9.99992 8.34633 10.0702 8.47135 10.1952C8.59638 10.3202 8.66661 10.4898 8.66661 10.6666C8.66661 10.8434 8.59638 11.013 8.47135 11.138C8.34633 11.263 8.17676 11.3332 7.99995 11.3332C7.82314 11.3332 7.65357 11.263 7.52854 11.138C7.40352 11.013 7.33328 10.8434 7.33328 10.6666C7.33328 10.4898 7.40352 10.3202 7.52854 10.1952C7.65357 10.0702 7.82314 9.99992 7.99995 9.99992ZM7.99995 3.99992C8.17676 3.99992 8.34633 4.07016 8.47135 4.19518C8.59638 4.3202 8.66661 4.48977 8.66661 4.66658V8.66658C8.66661 8.84339 8.59638 9.01296 8.47135 9.13799C8.34633 9.26301 8.17676 9.33325 7.99995 9.33325C7.82314 9.33325 7.65357 9.26301 7.52854 9.13799C7.40352 9.01296 7.33328 8.84339 7.33328 8.66658V4.66658C7.33328 4.48977 7.40352 4.3202 7.52854 4.19518C7.65357 4.07016 7.82314 3.99992 7.99995 3.99992Z"
            fill={color}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1549_10388">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
