import React from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';

const ProtectedRoute: React.FC = () => {
  const { orgName } = useParams<{ orgName: string }>();
  const isAuthenticated = useSelector((state: RootState) => state.user.userAuth);
  console.log("Is auth: "+isAuthenticated)

  return isAuthenticated ? <Outlet /> : <Navigate to={`/${orgName}`} />;
};

export default ProtectedRoute;