import { useMutation } from '@tanstack/react-query';
import { getAllProjectUsers } from "@/api/userProject"; 
import { GetAllProjectUsers,RetuenGetAllProjectUsers} from "@/types"; 
import { PostParam } from "@/types/index"; 

export function useGetAllProjectUsers(props?: PostParam) {
  const { onSuccess, onError } = props || {};

  return useMutation<RetuenGetAllProjectUsers, Error, GetAllProjectUsers>({
    mutationFn: getAllProjectUsers,
    onSuccess,
    onError
  });
}