import React from "react";

interface Props {
  className: string;
}

export const LinkUnattachedIcon = ({ className }: Props): JSX.Element => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_1857_9026)">
        <g clipPath="url(#clip1_1857_9026)">
          <path
            d="M17.5 22V20"
            stroke="#FF6D69"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.5 15L15.5 9"
            stroke="#FF6D69"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.5 5.99994L11.963 5.46394C12.9008 4.52627 14.1727 3.99954 15.4989 3.99963C16.825 3.99973 18.0968 4.52663 19.0345 5.46444C19.9722 6.40224 20.4989 7.67413 20.4988 9.00029C20.4987 10.3265 19.9718 11.5983 19.034 12.5359L18.5 12.9999"
            stroke="#FF6D69"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.5 18L13.103 18.534C12.1543 19.4722 10.8738 19.9984 9.53952 19.9984C8.20523 19.9984 6.92477 19.4722 5.97602 18.534C5.50838 18.0716 5.13711 17.521 4.88373 16.9141C4.63035 16.3073 4.49988 15.6561 4.49988 14.9985C4.49988 14.3408 4.63035 13.6897 4.88373 13.0829C5.13711 12.476 5.50838 11.9254 5.97602 11.463L6.50002 11"
            stroke="#FF6D69"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.5 17H22.5"
            stroke="#FF6D69"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.5 7H4.5"
            stroke="#FF6D69"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.5 2V4"
            stroke="#FF6D69"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1857_9026">
          <rect width="24" height="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_1857_9026">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
