import React from "react";

interface Props {
  className?: string;
}

export const AlbumIcon = ({ className }: Props): JSX.Element => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_26_308)">
        <path
          d="M23.3335 18.89C23.3335 16.5322 24.2701 14.271 25.9373 12.6038C27.6045 10.9366 29.8657 10 32.2235 10H61.1102C62.2776 10 63.4336 10.2299 64.5122 10.6767C65.5908 11.1235 66.5708 11.7783 67.3963 12.6038C68.2219 13.4293 68.8767 14.4094 69.3235 15.4879C69.7702 16.5665 70.0002 17.7225 70.0002 18.89V47.7767C70.0002 48.9441 69.7702 50.1001 69.3235 51.1787C68.8767 52.2573 68.2219 53.2373 67.3963 54.0628C66.5708 54.8884 65.5908 55.5432 64.5122 55.99C63.4336 56.4367 62.2776 56.6667 61.1102 56.6667H32.2235C29.8657 56.6667 27.6045 55.73 25.9373 54.0628C24.2701 52.3956 23.3335 50.1344 23.3335 47.7767V18.89Z"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3733 24.2002C12.3511 24.7829 11.5009 25.6252 10.9086 26.6419C10.3163 27.6585 10.0028 28.8136 10 29.9902V63.3235C10 66.9902 13 69.9902 16.6667 69.9902H50C52.5 69.9902 53.86 68.7069 55 66.6569"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M56.6665 23.3335H56.6998"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.3335 43.3332L35.4802 31.1866C35.8547 30.8117 36.2995 30.5142 36.7891 30.3113C37.2787 30.1084 37.8035 30.0039 38.3335 30.0039C38.8635 30.0039 39.3883 30.1084 39.8779 30.3113C40.3675 30.5142 40.8122 30.8117 41.1868 31.1866L53.3335 43.3332"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50 40.0001L55.48 34.5201C55.8546 34.1451 56.2994 33.8477 56.789 33.6448C57.2786 33.4419 57.8034 33.3374 58.3333 33.3374C58.8633 33.3374 59.3881 33.4419 59.8777 33.6448C60.3673 33.8477 60.8121 34.1451 61.1867 34.5201L70 43.3334"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_26_308">
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
