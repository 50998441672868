import React from "react";
import styles from "./css/StatusPage.module.css";
import ForbiddenNumber from "@/assets/403.png";
import ForbiddenDraw from "@/assets/403Draw.png";
import { useTranslation } from 'react-i18next';


export const ForbiddenPage: React.FC = () => {
  const { t } = useTranslation('ForbiddenPage');

  return (
    <div className={styles.container}>
      <div className={styles.boldTitle}>{t('title')}</div>
      <div className={styles.title}>
      {t('subtitle')}
      </div>
      <div className={styles.numberImage}>
        <img src={ForbiddenNumber} alt="404" />
      </div>
      <div className={styles.drawImage}>
        <img src={ForbiddenDraw} />
      </div>
    </div>
  );
};
