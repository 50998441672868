import { useMutation } from '@tanstack/react-query';
import { getProducts } from "../../api/product";
import { ProductData } from '@/types';

type Props = {
    onSuccess?: (data: ProductData[], variables: { org_id: string }) => void;
    onError?: (error: Error) => void;
  };

export function useGetProducts(props?: Props) {
    const { onSuccess, onError } = props || {};

    return useMutation<ProductData[] ,Error, {org_id: string}>({
        mutationFn: getProducts,
        onSuccess: (data, variables) => {
            if (onSuccess) {
              onSuccess(data, variables);
            }
          },
        onError
    });
}