import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';

const ProtectedAccess: React.FC = () => {
  const { orgName } = useParams<{ orgName: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const decodedPathname = decodeURIComponent(location.pathname);
  const user = useSelector((state: RootState) => state.user.user);
  const org = useSelector((state: RootState) => state.org.userOrg);
  const isAuthenticated = user.user_type !== "";
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const checkAccess = () => {
      if (!isAuthenticated) {
        console.log("navigate to login beacus: is auth is"+isAuthenticated)
        
        navigate(`/${orgName}`);
        return;
      }

      console.log(decodedPathname)

      const pathMatch =
        (decodedPathname !== `/${orgName}/dashboard` &&
          !decodedPathname.startsWith(`/${orgName}/KalAudio`) &&
          !decodedPathname.startsWith(`/${orgName}/KalChat`) &&
          !decodedPathname.startsWith(`/${orgName}/KalDocs`) &&
          !decodedPathname.startsWith(`/${orgName}/KalMedia`) &&
          !decodedPathname.startsWith(`/${orgName}/user/settings`) &&
          !decodedPathname.startsWith(`/${orgName}/doc/files`) &&
          !decodedPathname.startsWith(`/${orgName}/audio/files`) &&
          !decodedPathname.startsWith(`/${orgName}/media/files`) &&
          !decodedPathname.startsWith(`/${orgName}/doc/file/`) &&
          !decodedPathname.startsWith(`/${orgName}/audio/file/`) &&
          !decodedPathname.startsWith(`/${orgName}/media/file/`) &&
          user.user_type === 'user') ||
        (decodedPathname.startsWith(`/${orgName}/project`) &&
          user.user_type === 'super_admin') ||
        (decodedPathname.startsWith(`/${orgName}/organization`) &&
          user.user_type === 'admin') ||
        ((decodedPathname !== `/${orgName}` || decodedPathname !== `/${orgName}/`) &&
          org.org_name.toLowerCase() !== orgName?.toLowerCase());

      if (pathMatch) {
        navigate("/403");
      } else {
        setIsChecking(false);
      }
    };

    checkAccess();
  }, [location.pathname, user, org, orgName, isAuthenticated, navigate]);

  if (!isAuthenticated) {
    console.log("navigate to login beacus: is auth is"+isAuthenticated)
    return <Navigate to={`/${orgName}`} />;
  }

  if (isChecking) {
    return null;
  }

  return <Outlet />;
};

export default ProtectedAccess;