import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./css/Sentimental.module.css";
import Happy from "@/assets/Good.png";
import Worried from "@/assets/Worst.png";
import Neutral from "@/assets/Neutral.png"
import Love from "@/assets/Loveit.png"
import Sad from "@/assets/itsFine.png"

interface Props {
  sentiment: string;
}

export const Sentimental: React.FC<Props> = ({ sentiment }) => {
  const { t } = useTranslation('Sentimental');
  return (
    <div className={styles.container}>
      <div className={styles.text}>{t("textSentimentalRating")}</div>
      <div className={styles.icons}>
        <div
          className={`${styles.icon} ${sentiment === "worried" ? styles.selected : ""}`}
        >
          <img src={Worried} />
        </div>
        <div
          className={`${styles.icon} ${sentiment === "sad" ? styles.selected : ""}`}
        >
           <img src={Sad} />
        </div>
        <div
          className={`${styles.icon} ${sentiment === "nerual" ? styles.selected : ""}`}
        >
            <img src={Neutral} />
        </div>
        <div
          className={`${styles.icon} ${sentiment === "happy" ? styles.selected : ""}`}
        >
          <img src={Happy} />
        </div>
        <div
          className={`${styles.icon} ${sentiment === "love" ? styles.selected : ""}`}
        >
            <img src={Love} />
        </div>
      </div>
    </div>
  );
};
