import React from "react";

interface Props {
  className: string;
}

export const KalDocText = ({ className }: Props): JSX.Element => {
  return (
    <svg
      width="75"
      height="16"
      viewBox="0 0 75 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.72 15H0.8V1.54H3.72V6.96H5.44C5.6 6.54 5.86 6.12 6.18 5.68L9.44 1.54H12.76L8.92 6.48C8.38 7.2 8.1 7.7 8.1 8.2C8.1 8.7 8.38 9.18 8.92 9.9L12.88 15H9.3L6.18 10.9C5.86 10.48 5.62 10.06 5.46 9.64H3.72V15ZM18.018 15.26C15.138 15.26 13.118 12.9 13.118 10.02C13.118 7.18 15.438 4.8 18.478 4.8C21.578 4.8 23.858 7.04 23.858 10.02V15H21.158V13.84C20.458 14.72 19.378 15.26 18.018 15.26ZM18.478 12.6C19.938 12.6 21.018 11.44 21.018 10.04C21.018 8.64 19.938 7.48 18.478 7.48C17.018 7.48 15.958 8.64 15.958 10.04C15.958 11.44 17.018 12.6 18.478 12.6ZM26.6352 0.539999H29.4752V15H26.6352V0.539999ZM50.7753 8.28C50.7753 12.42 47.6953 15 43.4553 15H38.4953V1.54H43.5153C47.8753 1.54 50.7753 4.22 50.7753 8.28ZM47.7753 8.28C47.7753 5.86 46.0553 4.24 43.5153 4.24H41.4153V12.38H43.4553C45.9753 12.38 47.7753 10.8 47.7753 8.28ZM57.7358 15.26C54.6958 15.26 52.3758 12.9 52.3758 10.02C52.3758 7.16 54.7158 4.8 57.7358 4.8C60.7958 4.8 63.1158 7.18 63.1158 10.02C63.1158 12.9 60.7758 15.26 57.7358 15.26ZM57.7358 12.6C59.1958 12.6 60.2758 11.44 60.2758 10.04C60.2758 8.64 59.1958 7.48 57.7358 7.48C56.2758 7.48 55.2158 8.64 55.2158 10.04C55.2158 11.44 56.2758 12.6 57.7358 12.6ZM69.8247 15.26C66.8447 15.28 64.5047 12.9 64.5047 10.04C64.5047 7.16 66.8447 4.8 69.8247 4.82C71.7247 4.84 73.4847 5.7 74.3847 7.62L72.0447 8.9C71.6047 8.04 70.8047 7.5 69.8247 7.5C68.4047 7.5 67.3447 8.64 67.3447 10.04C67.3447 11.44 68.4047 12.58 69.8247 12.58C70.8047 12.58 71.6047 12.04 72.0447 11.18L74.3847 12.46C73.4847 14.38 71.7247 15.24 69.8247 15.26Z"
        fill="white"
      />
    </svg>
  );
};
