// src/store/userSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import secureLocalStorage from 'react-secure-storage';
import { getBooleanValue, getStoredData } from '@/utils/store';
import { ProjectData } from '@/types';

interface ProjectsState {
  projects: ProjectData[] | []
  projectsFetched: boolean
}

const initialState: ProjectsState = {
    projects: getStoredData<ProjectData[]>('projects', []),
    projectsFetched: getBooleanValue('projectsFetched')
};

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setProjects: (state, action: PayloadAction<ProjectsState>) => {
      state.projects = action.payload.projects;
      state.projectsFetched = true;

      secureLocalStorage.setItem('projects', JSON.stringify(action.payload.projects) || '');
      secureLocalStorage.setItem('projectsFetched', JSON.stringify(true) || '');
    },
    clearProjects: (state) => {
      state.projects = [];
      state.projectsFetched = false;

      secureLocalStorage.removeItem('projects');
      secureLocalStorage.removeItem('projectsFetched');
    },
  },
});

export const { setProjects, clearProjects } = projectsSlice.actions;
export default projectsSlice.reducer;
