import { useMutation } from '@tanstack/react-query';
import { updateOrg } from "../../api/organizationApi"; 
import { UpdateOrgData ,ReturnUpdateDelete} from "@/types";
import { PostParam } from "@/types/index";

export function useUpdateOrg(props?: PostParam) {
  const { onSuccess, onError } = props || {};

  return useMutation<ReturnUpdateDelete, Error, UpdateOrgData>({
    mutationFn: updateOrg,
    onSuccess,
    onError
  });
}
