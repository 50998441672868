import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import React, { useMemo, useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  ColumnDef,
  flexRender,
  SortingState,
} from "@tanstack/react-table";
import { ProjectData, ProductTypes } from "@/types";
import { ProductTag } from "../tags/ProductTags";
import { Link } from "@/components/links/Link";
import styles from "./css/OrganizationProjectsTable.module.css";
import { useTranslation } from 'react-i18next';

interface OrganizationProjectsTableProps {
  className?: string;
  data: ProjectData[];
}

export const OrganizationProjectsTable: React.FC<OrganizationProjectsTableProps> = ({
  className,
  data,
}) => {
  const { t, i18n } = useTranslation('OrganizationProjectsTable');
  const columns = useMemo<ColumnDef<ProjectData>[]>(
    () => [
      {
        accessorKey: "name",
        header: t("ProjectName"),
      },
      {
        accessorKey: "product_name",
        header: t("ProductName"),
        cell: ({ getValue }) => <ProductTag type={getValue<ProductTypes>()} />,
      },
      {
        accessorKey: "created_at",
        header: t("Date"),
      },
      {
        accessorKey: "link",
        header: t("ProjectLink"),
        cell: ({ getValue }) => (
          <Link size="lg" link={getValue<string>()} onClick={() => {}} />
        ),
      },
    ],
    []
  );
  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div className={`${styles.tableContainer} ${className}`}>
      <table className={styles.table}>
        <thead className={styles.tableHeadersRow}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  className={styles.headerText}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  <div className="flex items-center">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    <span className="ml-2">
                      {{
                        asc: <AiOutlineArrowUp className="text-black" />,
                        desc: <AiOutlineArrowDown className="text-black" />,
                        undefined: <AiOutlineArrowUp className="text-black" />,
                      }[header.column.getIsSorted() as string] ?? <AiOutlineArrowUp className="text-black" />}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className={styles.bodyRow}>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className={`${styles.rowText} ${cell.column.id === "project_name" ? styles.projectName : ""}`}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrganizationProjectsTable;
