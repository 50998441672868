import React from "react";

interface Props {
  className?: string;
}

export const LinkPlus = ({ className }: Props): JSX.Element => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_4001_2432)">
        <g clipPath="url(#clip1_4001_2432)">
          <path
            d="M9 15.4961L15 9.49609"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11 6.49605L11.463 5.96005C11.9273 5.49562 12.4786 5.1272 13.0853 4.87585C13.692 4.62449 14.3423 4.49512 14.999 4.49512C15.6557 4.49512 16.306 4.62449 16.9127 4.87585C17.5194 5.1272 18.0707 5.49562 18.535 5.96005C19.0002 6.42396 19.3693 6.97511 19.6211 7.58192C19.8729 8.18874 20.0024 8.83928 20.0023 9.49626C20.0022 10.1532 19.8725 10.8037 19.6205 11.4105C19.3686 12.0172 18.9993 12.5683 18.534 13.032"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.6029 19.0301C11.6539 19.9678 10.3735 20.4937 9.0394 20.4937C7.70527 20.4937 6.4249 19.9678 5.4759 19.0301C5.00825 18.5677 4.63699 18.0171 4.38361 17.4102C4.13023 16.8034 3.99976 16.1522 3.99976 15.4946C3.99976 14.8369 4.13023 14.1858 4.38361 13.579C4.63699 12.9721 5.00825 12.4215 5.4759 11.9591L5.9999 11.4961"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 19.4961H22"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19 16.4961V22.4961"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4001_2432">
          <rect
            width="24"
            height="24"
            fill="currentColor"
            transform="translate(0 0.496094)"
          />
        </clipPath>
        <clipPath id="clip1_4001_2432">
          <rect
            width="24"
            height="24"
            fill="currentColor"
            transform="translate(0 0.496094)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
