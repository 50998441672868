import React from "react";

interface Props {
  color?: string;
}

export const ToastWarning = ({ color = "#FAC914" }: Props): JSX.Element => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame" clipPath="url(#clip0_1857_8931)">
        <path
          id="Vector"
          d="M15.9998 2C17.389 2 18.6836 2.67931 19.4764 3.81393L19.6291 4.04667L31.4321 23.7541C31.7908 24.3753 31.9859 25.0774 31.9993 25.7947C32.0126 26.5119 31.8437 27.2208 31.5084 27.8549C31.1731 28.4891 30.6823 29.0278 30.082 29.4206C29.4817 29.8133 28.7916 30.0473 28.0762 30.1006L27.7926 30.1123H4.19111C3.475 30.1042 2.7726 29.9148 2.14949 29.5618C1.52637 29.2089 1.00285 28.7037 0.627789 28.0936C0.252729 27.4836 0.0383587 26.7884 0.00468811 26.073C-0.0289824 25.3577 0.119144 24.6454 0.435242 24.0028L0.579251 23.7337L12.3763 4.04085C12.7541 3.418 13.2859 2.90299 13.9206 2.54552C14.5553 2.18804 15.2714 2.00016 15.9998 2ZM16.0144 21.3903L15.8296 21.4005C15.4761 21.4425 15.1502 21.6128 14.9138 21.879C14.6774 22.1452 14.5468 22.4889 14.5468 22.8449C14.5468 23.201 14.6774 23.5446 14.9138 23.8108C15.1502 24.0771 15.4761 24.2473 15.8296 24.2894L15.9998 24.2995L16.1846 24.2894C16.5381 24.2473 16.864 24.0771 17.1004 23.8108C17.3368 23.5446 17.4674 23.201 17.4674 22.8449C17.4674 22.4889 17.3368 22.1452 17.1004 21.879C16.864 21.6128 16.5381 21.4425 16.1846 21.4005L16.0144 21.3903ZM15.9998 11.2078C15.6435 11.2079 15.2997 11.3387 15.0334 11.5754C14.7672 11.8122 14.5971 12.1384 14.5554 12.4923L14.5452 12.6625V18.481L14.5554 18.6512C14.5974 19.0047 14.7677 19.3306 15.0339 19.567C15.3001 19.8034 15.6438 19.934 15.9998 19.934C16.3559 19.934 16.6996 19.8034 16.9658 19.567C17.232 19.3306 17.4022 19.0047 17.4443 18.6512L17.4545 18.481V12.6625L17.4443 12.4923C17.4026 12.1384 17.2325 11.8122 16.9663 11.5754C16.7 11.3387 16.3561 11.2079 15.9998 11.2078Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1857_8931">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
