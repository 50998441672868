import React, { useEffect, useRef, useState } from "react";
import styles from "./css/CustomerClientField.module.css";
import { ClientIcon } from "@/icons/Client";
import { CustomerIcon } from "@/icons/Customer";
import { timeStringToSeconds, secondsToTimeString } from "@/utils/timeUtils";

interface CustomerClientFieldProps {
  type: "client" | "customer";
  name: string;
  text: { start: string; end: string; word: string }[];
  editMode?: boolean;
  editNameMode?: boolean;
  currentAudioTime: string;
  direction: "rtl" | "ltr";
  setName: (newVal: string) => void;
  setText: (newVal: { start: string; end: string; word: string }[]) => void;
  setCurrentTime: (value: string) => void;
}

export const CustomerClientField: React.FC<CustomerClientFieldProps> = ({
  type,
  name,
  text,
  editMode = false,
  editNameMode = false,
  currentAudioTime,
  direction,
  setName,
  setText,
  setCurrentTime,
}) => {
  const [editedText, setEditedText] = useState(
    text.map((item) => item.word).join(" ")
  );
  const [editedName, setEditedName] = useState(name);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setEditedText(text.map((item) => item.word).join(" "));
  }, [text]);

  useEffect(() => {
    setEditedName(name);
  }, [name])

  useEffect(() => {
    if (editMode && textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height =
        textareaRef.current.scrollHeight + "px";
    }
  }, [editedText, editMode]);

  const handleSentenceChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newSentence = e.target.value;
    setEditedText(newSentence);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditedName(e.target.value);
    setName(e.target.value);
  };

  const handleNameBlur = () => {
    setName(editedName);
  };

  const handleBlur = () => {
    const originalWords = text.map((item) => item.word);
    const newWords = editedText.split(/\s+/);

    const newText = adjustTimings(text, originalWords, newWords);
    setText(newText);
  };

  const adjustTimings = (
    originalText: { start: string; end: string; word: string }[],
    originalWords: string[],
    newWords: string[]
  ) => {
    const totalStartTime = timeStringToSeconds(originalText[0].start);
    const totalEndTime = timeStringToSeconds(
      originalText[originalText.length - 1].end
    );
    const totalDuration = totalEndTime - totalStartTime;

    // Calculate the average time per word in the original text
    const avgTimePerWord = totalDuration / originalWords.length;

    let newText = [];
    let currentTime = totalStartTime;

    for (let i = 0; i < newWords.length; i++) {
      const word = newWords[i];
      const originalIndex = originalWords.indexOf(word);

      let wordDuration;
      if (originalIndex !== -1) {
        // If the word exists in the original text, use its original duration
        const originalWord = originalText[originalIndex];
        wordDuration =
          timeStringToSeconds(originalWord.end) -
          timeStringToSeconds(originalWord.start);
      } else {
        // For new words, use the average time per word
        wordDuration = avgTimePerWord;
      }

      // Ensure we don't exceed the total duration
      const endTime = Math.min(currentTime + wordDuration, totalEndTime);

      newText.push({
        start: secondsToTimeString(currentTime),
        end: secondsToTimeString(endTime),
        word: word,
      });

      currentTime = endTime;

      // If we've reached the end time, distribute the remaining words evenly
      if (currentTime >= totalEndTime && i < newWords.length - 1) {
        const remainingWords = newWords.length - i - 1;
        const remainingTime = (totalEndTime - currentTime) / remainingWords;

        for (let j = i + 1; j < newWords.length; j++) {
          const wordEndTime = Math.min(
            currentTime + remainingTime,
            totalEndTime
          );
          newText.push({
            start: secondsToTimeString(currentTime),
            end: secondsToTimeString(wordEndTime),
            word: newWords[j],
          });
          currentTime = wordEndTime;
        }
        break;
      }
    }

    // Ensure the last word ends exactly at the total end time
    if (newText.length > 0) {
      newText[newText.length - 1].end = secondsToTimeString(totalEndTime);
    }

    return newText;
  };

  const currentAudioTimeInSeconds = timeStringToSeconds(currentAudioTime);

  return (
    <div className={styles.container}>
      <div className={`${styles.name} ${styles[type]} `}>
        {type === "client" ? (
          <ClientIcon className={styles.icon} />
        ) : (
          <CustomerIcon className={styles.icon} />
        )}
        <div className={`${styles.nameText} ${editNameMode ? styles.edit : ""}`}>
          {editNameMode ? (
            <textarea
            value={editedName}
            onChange={handleNameChange}
            onBlur={handleNameBlur}
            className={styles.editableName}
            style={{
                resize: "none",
                overflow: "auto",
                width: "100%",
                minHeight: "1em",
                outline: "none",
              }}
          />
          ) : (
            name
          )}
        </div>
      </div>
      <div className={styles.textContainer} style={{ direction }}>
        {editMode ? (
          <textarea
            ref={textareaRef}
            value={editedText}
            onChange={handleSentenceChange}
            onBlur={handleBlur}
            className={styles.editableSentence}
            style={{
              direction: direction,
              textAlign: direction === "rtl" ? "right" : "left",
              resize: "none",
              overflow: "hidden",
              width: "100%",
              minHeight: "1em",
              outline: "none",
            }}
          />
        ) : (
          text.map((item, index) => {
            const startInSeconds = timeStringToSeconds(item.start);
            const endInSeconds = timeStringToSeconds(item.end);
            const isActive =
              currentAudioTimeInSeconds >= startInSeconds &&
              currentAudioTimeInSeconds < endInSeconds;
            return (
              <span
                key={index}
                className={`${styles.word} ${isActive ? styles.activeWord : ""}`}
                style={{ width: `${item.word.length}ch` }}
                onClick={() => setCurrentTime(item.start)}
              >
                {item.word}{" "}
              </span>
            );
          })
        )}
      </div>
      <div className={`${styles.time} ${styles[direction]}`}>
        {text[0]?.start.substring(0, 8)}
      </div>
    </div>
  );
};
