import React from 'react';
import styles from "./css/ColorSelectionCard.module.css";
import { orgColorOptions } from '@/types';

type ColorOption = {
  left: string;
  top: string;
  backgroundColor: string;
};

const colors: ColorOption[] = [
  { left: '0', top: '0', backgroundColor: 'blue' },
  { left: '42px', top: '0', backgroundColor: 'mint' },
  { left: '84px', top: '0', backgroundColor: 'green' },
  { left: '126px', top: '0', backgroundColor: 'yellow' },
  { left: '168px', top: '0', backgroundColor: 'orange' },
  { left: '210px', top: '0', backgroundColor: 'red' },
  { left: '0', top: '32px', backgroundColor: 'lightGrey' },
  { left: '42px', top: '32px', backgroundColor: 'grey' },
  { left: '84px', top: '32px', backgroundColor: 'black' },
  { left: '126px', top: '32px', backgroundColor: 'electricBlue' },
  { left: '168px', top: '32px', backgroundColor: 'royalPurple' },
  { left: '210px', top: '32px', backgroundColor: 'pink' },
];

interface CardColorsProps {
  selectedColor: orgColorOptions;
  setSelectedColor: (option: orgColorOptions) => void;
}

const ColorSelectionCard: React.FC<CardColorsProps> = ({ selectedColor, setSelectedColor }) => {
  const handleSelectColor = (color: orgColorOptions) => {
    setSelectedColor(color);
  };

  return (
    <div className={styles.container}>
      {colors.map((color, index) => (
        <div
          key={index}
          className={`${styles.outerCircle} ${selectedColor === color.backgroundColor ? styles.selected : ''} ${styles[color.backgroundColor]}`}
          style={{ left: color.left, top: color.top }}
          onClick={() => handleSelectColor(color.backgroundColor as orgColorOptions)}
        >
          <div className={`${styles.innerCircle} ${styles[color.backgroundColor]}`} />
        </div>
      ))}
    </div>
  );
};

export default ColorSelectionCard;
