import React, { useState, useRef, useEffect } from "react";
import styles from "./css/EmailInput.module.css";
import { EmailSuggestionObject } from "@/types";
import { useTranslation } from 'react-i18next';

interface EmailInputProps<T extends EmailSuggestionObject> {
  added: boolean;
  setAdded: (value: boolean) => void;
  inputValue: string;
  setInputValue: (value: string) => void;
  suggestions: T[];
  selectedEmails: T[];
}

export const EmailsInput = <T extends EmailSuggestionObject>({
  added,
  setAdded,
  inputValue,
  setInputValue,
  suggestions,
  selectedEmails,
}: EmailInputProps<T>) => {
  const { t } = useTranslation('EmailInput');

  const [isFocus, setIsFocus] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState<T[]>([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);
  const [lastInteraction, setLastInteraction] = useState<
    "mouse" | "keyboard" | null
  >(null);

  const inputRef = useRef<HTMLInputElement>(null);
  const suggestionsRef = useRef<HTMLDivElement>(null);
  const activeSuggestionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isFocus) {
      if (!added) {
        let filtered = [];
        if (inputValue === "") {
          filtered = suggestions.filter(
            (suggestion) =>
              !selectedEmails.some(
                (emailObj) => emailObj.email === suggestion.email
              )
          );
        } else {
          filtered = suggestions.filter(
            (suggestion) =>
              suggestion.email
                .toLowerCase()
                .includes(inputValue.toLowerCase()) &&
              !selectedEmails.some(
                (emailObj) => emailObj.email === suggestion.email
              )
          );
        }
        setFilteredSuggestions(filtered);
        setShowSuggestions(true);
      } else {
        setAdded(false);
      }
    }
  }, [inputValue, suggestions, selectedEmails, isFocus]);

  useEffect(() => {
    if (added) {
      setShowSuggestions(false);
      setAdded(false);
    }
  }, [added]);

  useEffect(() => {
    if (activeSuggestionRef.current) {
      activeSuggestionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [activeSuggestionIndex]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputFocus = () => {
    setIsFocus(true);
    if (!added) {
      let filtered = [];
      if (inputValue === "") {
        filtered = suggestions.filter(
          (suggestion) =>
            !selectedEmails.some(
              (emailObj) => emailObj.email === suggestion.email
            )
        );
      } else {
        filtered = suggestions.filter(
          (suggestion) =>
            suggestion.email.toLowerCase().includes(inputValue.toLowerCase()) &&
            !selectedEmails.some(
              (emailObj) => emailObj.email === suggestion.email
            )
        );
      }
      setFilteredSuggestions(filtered);
      setShowSuggestions(true);
    } else {
      setAdded(false);
    }
  };

  const handleInputBlur = () => {
    // Delay hiding the suggestions to allow click events on suggestions
    setTimeout(() => {
      setIsFocus(false);
      setShowSuggestions(false);
      setActiveSuggestionIndex(-1);
    }, 200);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    setLastInteraction("keyboard");
    switch (e.key) {
      case "Enter":
        e.preventDefault();
        if (filteredSuggestions[activeSuggestionIndex]) {
          handleSuggestionClick(filteredSuggestions[activeSuggestionIndex]);
        }
        break;
      case "ArrowUp":
        e.preventDefault();
        if (activeSuggestionIndex > 0) {
          setActiveSuggestionIndex(activeSuggestionIndex - 1);
        } else {
          setActiveSuggestionIndex(filteredSuggestions.length - 1);
        }
        break;
      case "ArrowDown":
        e.preventDefault();
        if (activeSuggestionIndex < filteredSuggestions.length - 1) {
          setActiveSuggestionIndex(activeSuggestionIndex + 1);
        } else {
          setActiveSuggestionIndex(0);
        }
        break;
      default:
        break;
    }
  };

  const handleSuggestionClick = (suggestion: T) => {
    setShowSuggestions(false);
    setAdded(true);
    setInputValue(suggestion.email);
  };

  const handleSuggestionMouseEnter = (index: number) => {
    if (lastInteraction !== "keyboard") {
      setActiveSuggestionIndex(index);
    }
    setLastInteraction("mouse");
  };

  return (
    <div className={styles.emailInputContainer}>
      <input
        ref={inputRef}
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        onKeyDown={handleInputKeyDown}
        className={styles.emailInput}
        placeholder={t("EnterEmail")}
        id="field"
      />
      {showSuggestions && (
        <div className={styles.suggestionsList} ref={suggestionsRef}>
          {filteredSuggestions.map((suggestion, index) => (
            <div
              key={suggestion.email}
              ref={index === activeSuggestionIndex ? activeSuggestionRef : null}
              className={`${styles.suggestionItem} ${
                index === activeSuggestionIndex ? styles.keyboardSelected : ""
              }
              ${selectedEmails.some((emailObj) => emailObj.email === suggestion.email) ? styles.selectedItem : ""}`}
              onMouseDown={() => handleSuggestionClick(suggestion)}
              onMouseEnter={() => handleSuggestionMouseEnter(index)}
            >
              {suggestion.email}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
