export const formatDate = (date: string) => {
    if(date){
        return date.split(' ')[0];
    }
    return '';
}

export const currentDate = (): string => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');

    return `${day}/${month}/${year}`;
};
