import React from "react";

interface Props {
  className: string;
}

export const SettingIcon = ({ className }: Props): JSX.Element => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Frame" clipPath="url(#clip0_3006_889)">
        <g id="Group 1261153544">
          <path
            id="Vector"
            d="M9.95278 2.60967C10.4734 0.463444 13.5266 0.463444 14.0472 2.60967C14.1253 2.93209 14.2785 3.23151 14.4942 3.48356C14.7099 3.7356 14.9821 3.93316 15.2886 4.06013C15.5951 4.18711 15.9272 4.23993 16.258 4.21428C16.5887 4.18864 16.9087 4.08526 17.192 3.91256C19.0779 2.76367 21.2376 4.92211 20.0887 6.80922C19.9162 7.09236 19.813 7.41219 19.7874 7.74272C19.7618 8.07326 19.8146 8.40516 19.9414 8.71146C20.0683 9.01777 20.2656 9.28982 20.5173 9.50551C20.7691 9.7212 21.0682 9.87444 21.3903 9.95278C23.5366 10.4734 23.5366 13.5266 21.3903 14.0472C21.0679 14.1253 20.7685 14.2785 20.5164 14.4942C20.2644 14.7099 20.0668 14.9821 19.9399 15.2886C19.8129 15.5951 19.7601 15.9272 19.7857 16.258C19.8114 16.5887 19.9147 16.9087 20.0874 17.192C21.2363 19.0779 19.0779 21.2376 17.1908 20.0887C16.9076 19.9162 16.5878 19.813 16.2573 19.7874C15.9267 19.7618 15.5948 19.8146 15.2885 19.9414C14.9822 20.0683 14.7102 20.2656 14.4945 20.5173C14.2788 20.7691 14.1256 21.0682 14.0472 21.3903C13.5266 23.5366 10.4734 23.5366 9.95278 21.3903C9.87466 21.0679 9.72151 20.7685 9.5058 20.5164C9.29009 20.2644 9.01791 20.0668 8.71143 19.9399C8.40494 19.8129 8.0728 19.7601 7.74204 19.7857C7.41128 19.8114 7.09125 19.9147 6.808 20.0874C4.92211 21.2363 2.76244 19.0779 3.91133 17.1908C4.08379 16.9076 4.18701 16.5878 4.21259 16.2573C4.23818 15.9267 4.18541 15.5948 4.05858 15.2885C3.93175 14.9822 3.73443 14.7102 3.48267 14.4945C3.2309 14.2788 2.9318 14.1256 2.60967 14.0472C0.463444 13.5266 0.463444 10.4734 2.60967 9.95278C2.93209 9.87466 3.23151 9.72151 3.48356 9.5058C3.7356 9.29009 3.93316 9.01791 4.06013 8.71143C4.18711 8.40494 4.23993 8.0728 4.21428 7.74204C4.18864 7.41128 4.08526 7.09125 3.91256 6.808C2.76367 4.92211 4.92211 2.76244 6.80922 3.91133C8.03144 4.65444 9.61544 3.99689 9.95278 2.60967Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M8.33307 11.9998C8.33307 12.9723 8.71938 13.9049 9.40701 14.5925C10.0946 15.2802 11.0273 15.6665 11.9997 15.6665C12.9722 15.6665 13.9048 15.2802 14.5925 14.5925C15.2801 13.9049 15.6664 12.9723 15.6664 11.9998C15.6664 11.0273 15.2801 10.0947 14.5925 9.40707C13.9048 8.71944 12.9722 8.33313 11.9997 8.33313C11.0273 8.33313 10.0946 8.71944 9.40701 9.40707C8.71938 10.0947 8.33307 11.0273 8.33307 11.9998Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3006_889">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
