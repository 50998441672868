import React, { useReducer, useEffect, useRef } from "react";
import magnifierActive from "../../assets/magnifierActive.svg";
import magnifierStatic from "../../assets/magnifierStatic.svg";
import styles from "./css/SearchBar.module.css";
import { useTranslation } from 'react-i18next';

interface Option {
  id: string;
  name: string;
}

interface State {
  inputState: "static" | "active";
  filteredOptions: Option[];
  selectedOptionIndex: number;
  isOptionsOpen: boolean;
  lastSelectedOption: string | null;
}

type Action =
  | { type: "mouse_enter" }
  | { type: "mouse_leave" }
  | {
      type: "filter_options";
      payload: { searchText: string; options: Option[] };
    }
  | { type: "select_option"; payload: Option }
  | { type: "close_options" }
  | { type: "open_options" }
  | { type: "move_selection"; payload: "up" | "down" }
  | { type: "reset_selection" };

interface Props {
  options: Option[];
  onOptionClick: (option: Option) => void;
  value: string;
  onChange: (value: string) => void;
  isListNeeded: boolean;
}

export const SearchBar: React.FC<Props> = ({
  options,
  onOptionClick,
  value,
  onChange,
  isListNeeded,
}) => {
  const { t } = useTranslation('SearchBar');
  const [state, dispatch] = useReducer(reducer, {
    inputState: "static",
    filteredOptions: [],
    selectedOptionIndex: -1,
    isOptionsOpen: false,
    lastSelectedOption: null,
  });

  const ref = useRef<HTMLDivElement>(null);

  const handleOptionClick = (option: Option) => {
    dispatch({ type: "select_option", payload: option });
    onChange(option.name);
    onOptionClick(option);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      dispatch({ type: "close_options" });
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case "ArrowDown":
        event.preventDefault();
        dispatch({ type: "move_selection", payload: "down" });
        break;
      case "ArrowUp":
        event.preventDefault();
        dispatch({ type: "move_selection", payload: "up" });
        break;
      case "Enter":
        event.preventDefault();
        if (
          state.selectedOptionIndex >= 0 &&
          state.selectedOptionIndex < state.filteredOptions.length
        ) {
          const selectedOption =
            state.filteredOptions[state.selectedOptionIndex];
          handleOptionClick(selectedOption);
        }
        break;
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    dispatch({
      type: "filter_options",
      payload: { searchText: value, options },
    });
    if (value && value !== state.lastSelectedOption) {
      dispatch({ type: "open_options" });
    } else if (!value) {
      dispatch({ type: "close_options" });
    }
  }, [value, options, state.lastSelectedOption]);

  return (
    <div className={styles.searchBarContainer} ref={ref}>
      <div
        className={`${styles.searchBar} ${
          state.inputState === "active"
            ? styles.searchBarActive
            : styles.searchBarStatic
        }`}
        onMouseLeave={() => {
          dispatch({ type: "mouse_leave" });
        }}
        onMouseEnter={() => {
          dispatch({ type: "mouse_enter" });
        }}
      >
        <div className={styles.inputContainer}>
          <input
            type="text"
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            onKeyDown={handleKeyDown}
            className={`${styles.inputField} ${
              state.inputState === "active"
                ? styles.inputFieldActive
                : styles.inputFieldStatic
            }`}
            placeholder={
              state.inputState === "static"
                ? t("searchHerePlaceholder")
                : t("searchHerePlaceholder")
            }
          />
        </div>
        <img
          className="w-6 h-6"
          alt="Magnifier"
          src={
            state.inputState === "active" ? magnifierActive : magnifierStatic
          }
        />
      </div>
      {isListNeeded && state.isOptionsOpen && state.filteredOptions.length > 0 && (
        <ul className={styles.optionList}>
          {state.filteredOptions.map((option, index) => (
            <li
              key={option.id}
              className={`${styles.optionItem} ${
                index === state.selectedOptionIndex ? styles.selectedOption : ""
              }`}
              onClick={() => handleOptionClick(option)}
            >
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "mouse_enter":
      return { ...state, inputState: "active" };

    case "mouse_leave":
      return { ...state, inputState: "static" };

    case "filter_options":
      return {
        ...state,
        filteredOptions: action.payload.options.filter((option) =>
          option.name
            .toLowerCase()
            .includes(action.payload.searchText.toLowerCase())
        ),
        selectedOptionIndex: -1,
      };

    case "select_option":
      return {
        ...state,
        isOptionsOpen: false,
        inputState: "active",
        selectedOptionIndex: -1,
        lastSelectedOption: action.payload.name,
      };

    case "close_options":
      return { ...state, isOptionsOpen: false, selectedOptionIndex: -1 };

    case "open_options":
      return { ...state, isOptionsOpen: true };

    case "move_selection":
      const direction = action.payload === "up" ? -1 : 1;
      const newIndex =
        (state.selectedOptionIndex + direction + state.filteredOptions.length) %
        state.filteredOptions.length;
      return { ...state, selectedOptionIndex: newIndex };

    case "reset_selection":
      return { ...state, selectedOptionIndex: -1 };

    default:
      return state;
  }
}
