import { useMutation } from '@tanstack/react-query'
import { getTask } from '../../api/task'
import { PostParam } from '@/types'

export function useGetTask(props?: PostParam) {
    const { onSuccess, onError } = props || {};

    return useMutation({
        mutationFn: getTask,
        onSuccess,
        onError
    })
}