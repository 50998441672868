import React, { useState, useEffect } from "react";
import styles from "./css/UsersPage.module.css";
import UsersTable from "@/components/tables/UsersTable";
import GeneralTab from "@/components/tabs/GeneralTabs";
import { SearchBar } from "@/components/bars/SearchBar";
import { Button } from "@/components/Buttons";
import SelectField from "@/components/inputFields/SelectField";
import { CreateUser, UserData } from "@/types";
import { RoleData } from "@/types";
import { InputField } from "@/components/inputFields/inputFields";
import { useSelector, useDispatch } from "react-redux";
import { useCreateUser } from "@/hooks/user/useCreateUser";
import { useFetchAllUsers } from "@/utils/fetchData";
import { RootState } from "@/store/store";
import { setToast } from "@/store/systemSlice";
import { isValidEmail } from "@/utils/validations";
import { useTranslation } from 'react-i18next';


export const UsersPage: React.FC = () => {
  const { t } = useTranslation('UsersPage');
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("managment");
  const users = useSelector((state: RootState) => state.user.users);
  const user = useSelector((state: RootState) => state.user.user);
  const [filteredUsers, setFilteredUsers] = useState<UserData[]>(users);
  const [searchText, setSearchText] = useState("");
  const roles = useSelector((state: RootState) => state.systemService.roles);
  const org = useSelector((state: RootState) => state.org.userOrg);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [errors, setErrors] = useState({
    "email": "",
    "user_name": ""
  })
  const [saveDisable, setSaveDisable] = useState(true);
  const [data, setData] = useState<CreateUser>({
    org_id: user.org_id as string,
    org_name: org.org_name,
    user_name: "",
    email: "",
    first_name: "",
    last_name: "",
    role_id: "",
    user_image: null,
  });

  const tabs = [
    {
      name: t("usersManagmentTabs"),
      key: "managment",
    },
    {
      name: t("createNewUserTabs"),
      key: "users",
    },
  ];
  const createUserMutation = useCreateUser({
    onSuccess: () => {
      setLoadingCreate(false);
      getAllUsers.mutate({ org_id: org.id });
      dispatch(
        setToast({
          toastMessage: "",
          toastTitle: "User created successfuly",
          toastType: "success",
        })
      );
    },
    onError: (error) => {
      setLoadingCreate(false);
      dispatch(
        setToast({
          toastMessage: error.response.data.message,
          toastTitle: "Failed to create new user",
          toastType: "error",
        })
      );
    },
  });
  const getAllUsers = useFetchAllUsers();

  useEffect(() => {
    const fetchUsers = () => {
      getAllUsers.mutate({ org_id: org.id });
    };

    fetchUsers(); // Initial fetch
    const intervalId = setInterval(fetchUsers, 60000);

    return () => clearInterval(intervalId);
  }, [org.id]);

  useEffect(() => {
    if (JSON.stringify(users) !== JSON.stringify(filteredUsers)) {
      setFilteredUsers(users.filter((user) => user.email.startsWith(searchText)));
    }
  }, [users]);

  const handleSearchChange = (value: string) => {
    setSearchText(value);
    if (value === "") {
      setFilteredUsers(users);
    } else {
      setFilteredUsers(users.filter((user) => user.email.startsWith(value)));
    }
  };

  const handleTabChange = (key: string) => {
    setSelectedTab(key);
    if (key === "managment") {
      setData((prevData: CreateUser) => ({
        ...prevData,
        user_name: "",
        role_id: "",
        first_name: "",
        last_name: "",
        email: "",
      }));
    }
  };

  const handleDataChange = (field: string, value: string) => {
    let valid = true
    if(field === "email"){
      if((!isValidEmail(value) && value !== "")){
        setErrors((prev) => ({
          ...prev,
          [field]: "Please insert valid email"
        }))
        valid = false
      }
      else if(users.find((use) => use.email === value)){
        setErrors((prev) => ({
          ...prev,
          [field]: "Email already exists"
        }))
        valid = false
      }
    }
    if(field === "user_name"){
      if(users.find((use) => use.user_name.toLocaleLowerCase() === value.toLocaleLowerCase()) !== undefined){
        setErrors((prev) => ({
          ...prev,
          [field]: "User name already exists"
        }))
        valid = false
      }
    }
    if(valid){
      setErrors((prev) => ({
        ...prev,
        [field]: ""
      }))
    }
    setData((prevData: CreateUser) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const checkSave = () => {
    if(data.email === "" || data.first_name === "" || data.last_name === "" || data.role_id === "" || data.user_name === "" || Object.values(errors).some((error) => error !== "")){
      setSaveDisable(true);
    }
    else {
      setSaveDisable(false);
    }
  }

  useEffect(() => {
    checkSave()
  }, [data, errors])

  const handleRoleChange = (role: RoleData | undefined) => {
    if (role) {
      setData((prevData: CreateUser) => ({
        ...prevData,
        role_id: role.id,
      }));
    } else {
      setData((prevData: CreateUser) => ({
        ...prevData,
        role_id: "",
      }));
    }
  };

  const handleCreateUser = () => {
    setLoadingCreate(true);
    createUserMutation.mutate(data);
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>{t("title")}</div>
          <div className={styles.tabs}>
            <GeneralTab
              tabs={tabs}
              activeTab={selectedTab}
              onTabChange={handleTabChange}
            />
          </div>
        </div>
        <div className={styles.tabView}>
          {selectedTab === "managment" ? (
            <div className={styles.managment}>
              <div className={styles.labelInput}>
                <div className={styles.text}>{t("searchByEmail")}</div>
                <SearchBar
                  options={users.map((user) => ({
                    id: user.id,
                    name: user.email,
                  }))}
                  onOptionClick={() => {}}
                  value={searchText}
                  onChange={handleSearchChange}
                  isListNeeded={false}
                />
              </div>
              <UsersTable data={filteredUsers} users={users} />
            </div>
          ) : (
            <div className={styles.createUser}>
              <div className={styles.row}>
                <div className={styles.labelInput}>
                  <label htmlFor="userName" className={styles.text}>
                  {t("userName")}
                  </label>
                  <InputField
                    setData={(value) => handleDataChange("user_name", value)}
                    inputType="text"
                    id="user_name"
                    data={data.user_name}
                    errorMessage={errors["user_name"]}
                  />
                </div>

                <div className={styles.labelInput}>
                  <label htmlFor="email" className={styles.text}>
                  {t("email")}
                  </label>
                  <InputField
                    setData={(value) => handleDataChange("email", value)}
                    inputType="text"
                    id="email"
                    data={data.email}
                    errorMessage={errors["email"]}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.labelInput}>
                  <label htmlFor="firstName" className={styles.text}>
                  {t("firstName")}
                  </label>
                  <InputField
                    setData={(value) => handleDataChange("first_name", value)}
                    inputType="text"
                    id="firstName"
                    data={data.first_name}
                  />
                </div>
                <div className={styles.labelInput}>
                  <label htmlFor="lastName" className={styles.text}>
                  {t("lastName")}
                  </label>
                  <InputField
                    setData={(value) => handleDataChange("last_name", value)}
                    inputType="text"
                    id="lastName"
                    data={data.last_name}
                  />
                </div>
              </div>
              <div className={`${styles.text} ${styles.subTitle}`}>
              {t("permissions")}
              </div>
              <div className={styles.row}>
                <div className={styles.labelInput}>
                  <label htmlFor="role" className={styles.text}>
                  {t("role")}
                  </label>
                  <SelectField
                    data={roles}
                    updateFunction={handleRoleChange}
                    selectedValue={
                      roles.find((role) => role.id === data.role_id) as RoleData
                    }
                  />
                </div>
              </div>
              <Button
                loading={loadingCreate}
                text={t("button")}
                icon="no"
                onClick={handleCreateUser}
                className={styles.button}
                disabled={saveDisable}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
