import { useMutation } from '@tanstack/react-query';
import { getUserById } from "@/api/user"; 
import { GatDeleteUser, UserData } from "@/types"; 
import { PostParam } from "@/types/index"; 

export function useGetUserById(props?: PostParam) {
  const { onSuccess, onError } = props || {};

  return useMutation<UserData, Error, GatDeleteUser>({
    mutationFn: getUserById,
    onSuccess,
    onError
  });
}