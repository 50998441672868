import { useMutation } from '@tanstack/react-query';
import { getAllProjectByOrgID } from "@/api/project"; 
import { GetAllProjec, ProjectData } from "@/types/project"; 
import { PostParam } from "@/types/index"; 

export function useGetProjectByOrgID(props?: PostParam) {
  const { onSuccess, onError } = props || {};

  return useMutation<ProjectData[], Error, GetAllProjec>({
    mutationFn: getAllProjectByOrgID,
    onSuccess,
    onError
  });
}