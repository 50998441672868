import { useMutation } from '@tanstack/react-query';
import { getRoles } from "../../api/systemService";
import { RoleData ,GetRolesData} from "@/types";
import { PostParam } from "@/types";

export function useGetRoles(props?: PostParam) {
    const { onSuccess, onError } = props || {};

    return useMutation<RoleData[], Error, GetRolesData>({
        mutationFn: getRoles,
        onSuccess,
        onError
    });
}