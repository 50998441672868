import React, { useState } from "react";
import styles from "./css/MenuButton.module.css";

interface MenuButtonProps {
  iconComponent?: React.ElementType;
  iconSrc?: string;
  iconColorType: "fill" | "stroke" | "text";
  className?: string;
  text: string;
  selected: string;
  onClick: (selected: string) => void;
  showText?: boolean;
}

export const MenuButton: React.FC<MenuButtonProps> = ({
  iconComponent: IconComponent,
  iconSrc,
  className,
  text,
  selected,
  iconColorType,
  showText = true,
  onClick,
}) => {
  const [hover, setHover] = useState(false);
  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  return (
    <div
      className={`${styles.button} ${className} ${selected === text ? styles.selected : ""} ${!showText? styles.shrink: ''} group`}
      onClick={() => onClick(text)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.iconContainer}>
        {IconComponent ? (
          <IconComponent
            className={`${styles.icon} ${selected === text ? styles.selected : ""} ${hover ? styles.hover : ""} ${iconColorType === "fill" ? styles.fillColor : iconColorType === "stroke" ? styles.strokeColor : styles.textColor}`}
          />
        ) : (
          <img
            src={iconSrc}
            alt=""
            className={`${styles.icon} ${selected === text ? styles.selected : ""} ${iconColorType === "fill" ? styles.fillColor : iconColorType === "stroke" ? styles.strokeColor : styles.textColor}`}
          />
        )}
      </div>

      {showText && (
        <div
          className={`${styles.text} ${selected === text ? styles.selected : ""} ${hover ? styles.hover : ""}`}
        >
          {text}
        </div>
      )}
    </div>
  );
};
