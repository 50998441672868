import React from "react";

interface Props {
  color?: string;
}

export const ToastInfo = ({ color = "#1890FF" }: Props): JSX.Element => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame" clipPath="url(#clip0_1857_8934)">
        <path
          id="Vector"
          d="M15.9937 0C24.827 0 31.9873 7.16037 31.9873 15.9937C31.9907 20.1928 30.3425 24.2247 27.3986 27.2191C24.4547 30.2134 20.4514 31.9299 16.2528 31.9979C12.0543 32.0659 7.99738 30.4801 4.95804 27.5827C1.91869 24.6853 0.140726 20.7089 0.00799702 16.5119L0 15.9937L0.00639739 15.5458C0.243104 6.92046 7.30911 0 15.9937 0ZM15.9937 14.3943H14.3943L14.2072 14.4055C13.8185 14.4517 13.4602 14.6389 13.2002 14.9316C12.9403 15.2243 12.7967 15.6022 12.7967 15.9937C12.7967 16.3851 12.9403 16.763 13.2002 17.0557C13.4602 17.3484 13.8185 17.5356 14.2072 17.5818L14.3943 17.593V22.3911L14.4055 22.5783C14.4476 22.9353 14.6087 23.2677 14.8629 23.5219C15.1171 23.7761 15.4495 23.9372 15.8065 23.9793L15.9937 23.9905H17.593L17.7802 23.9793C18.1372 23.9372 18.4696 23.7761 18.7238 23.5219C18.978 23.2677 19.1391 22.9353 19.1812 22.5783L19.1924 22.3911L19.1812 22.204C19.143 21.8777 19.0052 21.5711 18.7866 21.3259C18.5679 21.0806 18.2791 20.9087 17.9593 20.8334L17.7802 20.8014L17.593 20.7918V15.9937L17.5818 15.8065C17.5397 15.4495 17.3786 15.1171 17.1244 14.8629C16.8702 14.6087 16.5378 14.4476 16.1808 14.4055L15.9937 14.3943ZM16.0097 9.5962L15.8065 9.6074C15.4178 9.65364 15.0596 9.84084 14.7996 10.1335C14.5397 10.4262 14.3961 10.8041 14.3961 11.1956C14.3961 11.587 14.5397 11.9649 14.7996 12.2576C15.0596 12.5503 15.4178 12.7375 15.8065 12.7837L15.9937 12.7949L16.1968 12.7837C16.5855 12.7375 16.9438 12.5503 17.2037 12.2576C17.4637 11.9649 17.6073 11.587 17.6073 11.1956C17.6073 10.8041 17.4637 10.4262 17.2037 10.1335C16.9438 9.84084 16.5855 9.65364 16.1968 9.6074L16.0097 9.5962Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1857_8934">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
