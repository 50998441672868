import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

interface TruncateTextProps {
  text: string;
  maxLength: number;
  tooltipPlacement?: 'top' | 'bottom';
}

const TruncateText: React.FC<TruncateTextProps> = ({ 
  text, 
  maxLength, 
  tooltipPlacement = 'top' 
}) => {
  if (!text) return <p></p>;

  if (text.length <= maxLength) {
    return <p>{text}</p>;
  }

  const truncatedText = `${text.slice(0, maxLength)}...`;

  return (
    <Tippy content={text} placement={tooltipPlacement}>
      <p>{truncatedText}</p>
    </Tippy>
  );
};

export default TruncateText;