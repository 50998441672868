import React from "react";

interface Props {
  className?: string;
}

export const SpeackerIcon = ({ className }: Props): JSX.Element => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_78_11053)">
        <path
          d="M6.66602 5.99984C6.66602 6.35346 6.80649 6.6926 7.05654 6.94265C7.30659 7.19269 7.64573 7.33317 7.99935 7.33317C8.35297 7.33317 8.69211 7.19269 8.94216 6.94265C9.19221 6.6926 9.33268 6.35346 9.33268 5.99984C9.33268 5.64622 9.19221 5.30708 8.94216 5.05703C8.69211 4.80698 8.35297 4.6665 7.99935 4.6665C7.64573 4.6665 7.30659 4.80698 7.05654 5.05703C6.80649 5.30708 6.66602 5.64622 6.66602 5.99984Z"
          stroke="#5C5D71"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.66602 5.33317V3.99984C2.66602 3.64622 2.80649 3.30708 3.05654 3.05703C3.30659 2.80698 3.64573 2.6665 3.99935 2.6665H5.33268"
          stroke="#5C5D71"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.66602 10.6665V11.9998C2.66602 12.3535 2.80649 12.6926 3.05654 12.9426C3.30659 13.1927 3.64573 13.3332 3.99935 13.3332H5.33268"
          stroke="#5C5D71"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.666 2.6665H11.9993C12.353 2.6665 12.6921 2.80698 12.9422 3.05703C13.1922 3.30708 13.3327 3.64622 13.3327 3.99984V5.33317"
          stroke="#5C5D71"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.666 13.3332H11.9993C12.353 13.3332 12.6921 13.1927 12.9422 12.9426C13.1922 12.6926 13.3327 12.3535 13.3327 11.9998V10.6665"
          stroke="#5C5D71"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.33398 10.6668C5.33398 10.3132 5.47446 9.97407 5.72451 9.72402C5.97456 9.47397 6.3137 9.3335 6.66732 9.3335H9.33398C9.68761 9.3335 10.0267 9.47397 10.2768 9.72402C10.5268 9.97407 10.6673 10.3132 10.6673 10.6668"
          stroke="#5C5D71"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_78_11053">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
