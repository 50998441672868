import React, { useEffect, useState } from "react";
import { InputField } from "@/components/inputFields/inputFields";
import styles from "./css/LoginCard.module.css";
import { LoginData, UserData } from "@/types";
import { Button } from "@/components/Buttons";
import { useLogin } from "@/hooks/gateway/useLogin";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "@/store/userSlice";
import { setUserOrg } from "@/store/orgSlice";
import { useNavigate, useParams } from "react-router-dom";
import {
  useFetchAllProducts,
  useFetchAllProjectTypes,
  useFetchAllProjects,
  useFetchRoles,
  useFetchAllOrgs,
  useFetchAllUsers,
} from "@/utils/fetchData";
import { setToast } from "@/store/systemSlice";
import { RootState } from "@/store/store";
import { useResetPassword } from "@/hooks/gateway/useResetPassword";
import { useTranslation } from 'react-i18next';

export const LoginCard: React.FC = () => {
  const { t } = useTranslation('LoginCard');
  
  const { orgName } = useParams<{ orgName: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projects, projectsFetched } = useSelector(
    (state: RootState) => state.projects
  );
  const [forgotPassword, setForgotPassword] = useState(false);
  const [details, setDetails] = useState<LoginData>({
    orgName: orgName as string,
    identity: "",
    password: "",
  });
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [doneAll, setDoneALL] = useState(false);
  const [userData, setUserData] = useState<UserData>();
  const getAllProjects = useFetchAllProjects();
  const getAllProducts = useFetchAllProducts();
  const getAllOrgs = useFetchAllOrgs();
  const getAllRoles = useFetchRoles();
  const getAllProjectsTypes = useFetchAllProjectTypes();
  const getAllUsers = useFetchAllUsers();

  const resetPassword = useResetPassword({
    onSuccess(data) {
      setLoading(false);
      dispatch(
        setToast({
          toastTitle: t(data),
          toastMessage: "",
          toastType: "success",
        })
      );
    },
    onError(error) {
      setLoading(false);
      dispatch(
        setToast({
          toastTitle: t(error.response.data),
          toastMessage: "",
          toastType: "error",
        })
      );
    },
  })
  const login = useLogin({
    onSuccess(data) {
      setUserData(data.user);
      const user = {
        user: data.user,
        token: data.token,
        userAuth: true,
        users: [],
      };
      const orgBody = { org_id: user.user.org_id };
      if (data.user.user_type === "admin") {
        getAllUsers.mutate(orgBody);
        getAllProjectsTypes.mutate(orgBody);
        getAllProducts.mutate(orgBody);
      }

      getAllRoles.mutate(orgBody);

      if (user.user.user_type === "super_admin") {
        getAllOrgs.mutate();
        getAllProducts.mutate(orgBody);
      } else if (
        user.user.user_type === "admin" ||
        user.user.user_type === "user"
      ) {
        getAllProjects.mutate(orgBody);
      }
      dispatch(setUserOrg(data.org));
      dispatch(setUser(user));
      setDoneALL(true);
    },
    onError(error) {
      dispatch(
        setToast({
          toastTitle: t('LoginFailed'),
          toastMessage: t(error.response.data.message),
          toastType: "error",
        })
      );
      setLoading(false);
    },
  });

  useEffect(() => {
    if (userData) {
      if (
        (projectsFetched || userData.user_type === "super_admin") &&
        doneAll
      ) {
        if (userData.user_type === "user") {
          console.log(projects.length);
          const projectsLengthOne = projects.length === 1;
          if (projectsLengthOne) {
            navigate(
              `/${orgName}/${projects[0].product_name}/${projects[0].id}`
            );
          } else {
            navigate(`/${orgName}/dashboard`);
          }
        } else {
          navigate(`/${orgName}/dashboard`);
        }
      }
    }
  }, [projects, projectsFetched, userData, doneAll]);

  const handleInputChange = (name: string, value: string) => {
    if (name === "email") {
      setEmail(value);
    } else {
      setDetails((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleClick = () => {
    setLoading(true);
    if(forgotPassword){
      resetPassword.mutate({email})
    }
    else {
      login.mutate(details);
    }
  };

  const handleForgotPassword = () => {
    if (!loading) {
      setForgotPassword(!forgotPassword);
      setEmail("");
      setDetails({ identity: "", password: "", orgName: orgName as string });
    }
  };

  return (
    <div className={styles.card}>
      <div className={styles.topContainer}>
        <div className={styles.topLabel}>{t('Login')}</div>
      </div>
      <div className={`${styles.formContainer}`}>
        <div className={`${styles.form} ${forgotPassword ? styles.swap : ""}`}>
          <div className={`${styles.formContent} ${styles.front}`}>
            <div className={styles.textInputContainer}>
              <label htmlFor="userEmail" className={styles.label}>
                {t('EmailOrName')}
              </label>
              <div className={styles.inputField}>
                <InputField
                  id="userEmail"
                  placeholder={t('EnterEmailOrName')}
                  inputType="text"
                  data={details.identity}
                  setData={(value: string) =>
                    handleInputChange("identity", value)
                  }
                />
              </div>
            </div>
            <div className={styles.textInputContainerPassword}>
              <label htmlFor="password" className={styles.label}>
                {t('Password')}
              </label>
              <div className={styles.inputField}>
                <InputField
                  id="password"
                  placeholder={t('EnterPassword')}
                  inputType="password"
                  data={details.password}
                  setData={(value: string) =>
                    handleInputChange("password", value)
                  }
                />
              </div>
            </div>
            <Button
              text={t('Login')}
              icon="no"
              onClick={handleClick}
              size="large"
              className={styles.button}
              loading={loading}
              disabled={details.password === "" || details.identity === ""}
            />
            <div className={styles.bottomLabelContainer}>
              <div
                className={styles.bottomLabel}
                onClick={handleForgotPassword}
              >
                {t('ForgotPassword')}
              </div>
            </div>
          </div>
          <div className={`${styles.formContent} ${styles.back}`}>
            <div className={styles.textInputContainer}>
              <label htmlFor="email" className={styles.label}>
                {t('Email')}
              </label>
              <div className={styles.inputField}>
                <InputField
                  id="email"
                  placeholder={t('EnterEmail')}
                  inputType="text"
                  data={email}
                  setData={(value: string) => handleInputChange("email", value)}
                />
              </div>
            </div>
            <Button
              text={t('SendLink')}
              icon="no"
              onClick={handleClick}
              size="large"
              className={`${styles.forgotPasswordButton}`}
              loading={loading}
              disabled={email === ""}
            />
            <div className={styles.bottomLabelContainer}>
              <div
                className={styles.bottomLabelLogin}
                onClick={handleForgotPassword}
              >
                {t('BackLogin')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginCard;
