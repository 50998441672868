import React from "react";
import { useNavigate } from "react-router-dom";
import { Export } from "../../icons/Export";
import { OrgData } from "@/types";
import CardEmptyImage from "../../assets/CardEmptyImage.png";
import styles from "./css/OrganiztionCard.module.css";
import TruncateText from "../addons/TruncatedText";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";

interface OrganizationCardProps {
  className?: string;
  data: OrgData;
}

export const OrganizationCard: React.FC<OrganizationCardProps> = ({
  className,
  data,
}) => {
  const navigate = useNavigate();
  const org = useSelector((state: RootState) => state.org.userOrg);
  const imageUrl =
    !data.logo || data.logo.trim() === "" ? CardEmptyImage : data.logo;
  const handleExportClick = () => {
    navigate(`/${org.org_name}/organization/settings/${data.id}`);
  };

  return (
    <div
      className={`${styles.organizationCard} ${className} ${styles[data.org_color]}`}
      onClick={handleExportClick}
    >
      <div className={styles.relativeContainer}>
        <div className={styles.imageContainer}>
          <img
            className={styles.image}
            alt={data.org_name}
            src={imageUrl}
            onError={(e) => {
              e.currentTarget.src = CardEmptyImage;
            }}
          />
          <div className={styles.organizationName}>{data.org_name}</div>
          <div>
            <Export className={styles.exportIcon} />
          </div>
        </div>
        <p className={styles.description}>
          <TruncateText text={org.org_description} maxLength={135}/>
        </p>
      </div>
    </div>
  );
};
