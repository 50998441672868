import { useMutation } from '@tanstack/react-query'
import {login} from "@/api/gateway"
import { PostParam } from '@/types'

export function useLogin(props?: PostParam) {
    const { onSuccess, onError } = props || {};

    return useMutation({
        mutationFn: login,
        onSuccess,
        onError
    })
}