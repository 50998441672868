import { ProductTypes, orgColorOptions } from "@/types";
import React, { FC } from "react";
import styles from "./css/ProjectWorkspace.module.css"

interface Props {
  projectType?: ProductTypes;
  children: React.ReactNode;
  color?: orgColorOptions;
}

const ProjectWorkspace: FC<Props> = ({ projectType, children, color = "blue" }) => {
  const getBorderColorClass = () => {
    if (!projectType) return '';
    
    switch (projectType) {
      case "KalDocs":
        return "border-kalDocs-default";
      case "KalMedia":
        return "border-kalMedia-default";
      case "KalChat":
        return "border-kalChat-default";
      case "KalAudio":
        return "border-kalAudio-default";
      default:
        return '';
    }
  };

  return (
    <div
      className={`${styles.container} ${projectType? getBorderColorClass(): styles[color]}`}
    >
      <div className="w-full h-full overflow-auto">
        {children}
      </div>
    </div>
  );
};

export default ProjectWorkspace;