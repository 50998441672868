import React from "react";

interface EyeProps {
  color?: string;
}

export const Eye: React.FC<EyeProps> = ({ color = "#acabba" }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: '8px' }}
    >
      <path
        d="M8.00014 4.03125C5.47983 4.03125 3.63451 5.30938 2.33295 8C3.63451 10.6906 5.47983 11.9688 8.00014 11.9688C10.522 11.9688 12.3673 10.6906 13.6673 8C12.3658 5.30938 10.5205 4.03125 8.00014 4.03125ZM7.93764 10.75C6.41889 10.75 5.18764 9.51875 5.18764 8C5.18764 6.48125 6.41889 5.25 7.93764 5.25C9.45639 5.25 10.6876 6.48125 10.6876 8C10.6876 9.51875 9.45639 10.75 7.93764 10.75Z"
      />
      <path
        d="M14.722 7.59688C13.2407 4.47656 11.0017 2.90625 8.00009 2.90625C4.99697 2.90625 2.75947 4.47656 1.27822 7.59844C1.2188 7.72425 1.18799 7.86165 1.18799 8.00078C1.18799 8.13991 1.2188 8.27732 1.27822 8.40312C2.75947 11.5234 4.99853 13.0938 8.00009 13.0938C11.0032 13.0938 13.2407 11.5234 14.722 8.40156C14.8423 8.14844 14.8423 7.85469 14.722 7.59688ZM8.00009 11.9688C5.47978 11.9688 3.63447 10.6906 2.3329 8C3.63447 5.30938 5.47978 4.03125 8.00009 4.03125C10.5204 4.03125 12.3657 5.30938 13.6673 8C12.3673 10.6906 10.522 11.9688 8.00009 11.9688Z"
        fill={color}
      />
      <path
        d="M7.93755 5.25C6.4188 5.25 5.18755 6.48125 5.18755 8C5.18755 9.51875 6.4188 10.75 7.93755 10.75C9.4563 10.75 10.6876 9.51875 10.6876 8C10.6876 6.48125 9.4563 5.25 7.93755 5.25ZM7.93755 9.75C6.97037 9.75 6.18755 8.96719 6.18755 8C6.18755 7.03281 6.97037 6.25 7.93755 6.25C8.90474 6.25 9.68755 7.03281 9.68755 8C9.68755 8.96719 8.90474 9.75 7.93755 9.75Z"
        fill={color}
      />
    </svg>
  );
};
