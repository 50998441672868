import React from "react";

interface Props {
  className: string;
}

export const User = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`${className}`}
      fill="none"
      height="36"
      viewBox="0 0 36 36"
      width="36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.9208 28.104C31.1628 26.3088 30.0629 24.678 28.6824 23.3027C27.306 21.9234 25.6755 20.8237 23.881 20.0643C23.8649 20.0563 23.8489 20.0523 23.8328 20.0442C26.3359 18.2362 27.9632 15.2911 27.9632 11.9683C27.9632 6.46387 23.5033 2.00405 17.9989 2.00405C12.4944 2.00405 8.03459 6.46387 8.03459 11.9683C8.03459 15.2911 9.66182 18.2362 12.1649 20.0482C12.1489 20.0563 12.1328 20.0603 12.1167 20.0683C10.3167 20.8277 8.70156 21.9165 7.31539 23.3067C5.9361 24.6831 4.83639 26.3136 4.077 28.1081C3.33097 29.8649 2.92863 31.7484 2.89173 33.6567C2.89066 33.6996 2.89818 33.7423 2.91386 33.7822C2.92953 33.8221 2.95303 33.8585 2.98299 33.8892C3.01294 33.9199 3.04874 33.9443 3.08827 33.961C3.1278 33.9776 3.17027 33.9862 3.21316 33.9862H5.62388C5.80066 33.9862 5.94129 33.8456 5.94531 33.6728C6.02566 30.571 7.2712 27.6661 9.47298 25.4643C11.7511 23.1862 14.7766 21.9326 17.9989 21.9326C21.2212 21.9326 24.2466 23.1862 26.5248 25.4643C28.7266 27.6661 29.9721 30.571 30.0524 33.6728C30.0565 33.8496 30.1971 33.9862 30.3739 33.9862H32.7846C32.8275 33.9862 32.87 33.9776 32.9095 33.961C32.949 33.9443 32.9848 33.9199 33.0148 33.8892C33.0447 33.8585 33.0682 33.8221 33.0839 33.7822C33.0996 33.7423 33.1071 33.6996 33.106 33.6567C33.0658 31.7362 32.6681 29.8679 31.9208 28.104ZM17.9989 18.879C16.1547 18.879 14.419 18.1599 13.1132 16.854C11.8074 15.5482 11.0882 13.8125 11.0882 11.9683C11.0882 10.1241 11.8074 8.38842 13.1132 7.08262C14.419 5.77681 16.1547 5.05762 17.9989 5.05762C19.8431 5.05762 21.5788 5.77681 22.8846 7.08262C24.1904 8.38842 24.9096 10.1241 24.9096 11.9683C24.9096 13.8125 24.1904 15.5482 22.8846 16.854C21.5788 18.1599 19.8431 18.879 17.9989 18.879Z"
        fill="white"
        fillOpacity="0.85"
      />
    </svg>
  );
};