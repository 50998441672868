import React from "react";
import { OrganizationCard } from "../components/cards/OrganiztionCard";
import styles from "./css/AllOrganizations.module.css";
import CreateNewOrgCard from "../components/cards/CreateNewOrgCard";
import { useSelector } from "react-redux";
import { LoadingPageAnimation } from "@/components/addons/LoadingPageAnimation";
import { RootState } from "@/store/store";

interface AllOrganizationsProps {
  // orgsList: []
}

const AllOrganizationsPage: React.FC<AllOrganizationsProps> = () => {
  const orgsData = useSelector((state: RootState) => state.org.orgs);
  const orgsFetched = useSelector((state: RootState) => state.org.orgsFetched);

  return (
    <div className={styles.container}>
      {!orgsFetched ? (
        <LoadingPageAnimation />
      ) : (
        <div className={styles.org}>
          <div className={`${styles.customGrid} p-10`}>
            <div className={styles.gridItem}>
              <CreateNewOrgCard />
            </div>
            {orgsData.map((data, index) => (
              <div key={index} className={styles.gridItem}>
                <OrganizationCard data={data} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AllOrganizationsPage;