// src/store/userSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import secureLocalStorage from 'react-secure-storage';
import { getStoredData } from '@/utils/store';
import { ProductData, ProjectTypeData, RoleData } from '@/types';

interface SystemServiceState {
  products: ProductData[] | []
  roles: RoleData[] | []
  projectTypes: ProjectTypeData[]
}

const initialState: SystemServiceState = {
    products: getStoredData<ProductData[]>('products', []),
    roles: getStoredData<RoleData[]>('roles', []),
    projectTypes: getStoredData<ProjectTypeData[]>('projectTypes', [])
};

const systemServiceSlice = createSlice({
  name: 'systemService',
  initialState,
  reducers: {
    setSystemService: (state, action: PayloadAction<SystemServiceState>) => {
      state.products = action.payload.products;
      state.roles = action.payload.roles
      state.projectTypes = action.payload.projectTypes

      secureLocalStorage.setItem('products', JSON.stringify(action.payload.products) || '');
      secureLocalStorage.setItem('roles', JSON.stringify(action.payload.roles) || '');
      secureLocalStorage.setItem('projectTypes', JSON.stringify(action.payload.projectTypes) || '');
    },
    setProducts: (state, action) => {
      state.products = action.payload;

      secureLocalStorage.setItem('products', JSON.stringify(action.payload) || '');
    },
    setProjectTypes: (state, action) => {
        state.projectTypes = action.payload

        secureLocalStorage.setItem('projectTypes', JSON.stringify(action.payload) || '');
    },
    setRoles: (state, action) => {
        state.roles = action.payload

        secureLocalStorage.setItem('roles', JSON.stringify(action.payload) || '');
    },
    clearSystemService: (state) => {
      state.products = [];
      state.roles = [];
      state.projectTypes = []

      secureLocalStorage.removeItem('products');
      secureLocalStorage.removeItem('roles');
      secureLocalStorage.removeItem('projectTypes');
    },
  },
});

export const { setSystemService,setProducts, setProjectTypes, setRoles, clearSystemService } = systemServiceSlice.actions;
export default systemServiceSlice.reducer;
