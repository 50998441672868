import React from "react";
import styles from "./css/LoadingpageAnimation.module.css"
import { useTranslation } from 'react-i18next';

export const LoadingPageAnimation: React.FC = () => {
  const { t } = useTranslation('LoginPage');

  return (
    <div className={styles["loading-container"]}>
      <div
        className={styles["loading-spinner"]}
        role="status">
        <span className={styles["loading-text"]}>{t('loadingSpinner')}</span>
      </div>
    </div>
  );
};
