import { PostRequest } from "./requests";
import {GetDeleteProjectsTypesData ,ProductData , ProjectTypeData, OrgIdBody} from "@/types"; 

export const getProducts = async (data: OrgIdBody): Promise<ProductData[]> => {
    try {
        return await PostRequest<ProductData[]>("/api/product/get_products", data);
    } catch (error) {
        console.log('Error in getProducts:', error);
        throw error;
    }
}

export const getProduct = async (data: GetDeleteProjectsTypesData): Promise<ProductData> => {
    try {
        return await PostRequest<ProductData>("/api/product/get_product", data);
    } catch (error) {
        console.log('Error in getProduct:', error);
        throw error;
    }
}

export const getProjectType = async (data: GetDeleteProjectsTypesData): Promise<ProjectTypeData> => {
    try {
        return await PostRequest<ProjectTypeData>("/api/product/get_project_types", data);
    } catch (error) {
        console.log('Error in getProjectType:', error);
        throw error;
    }
}

export const getAllProjectsTypes = async (data: OrgIdBody): Promise<ProjectTypeData[]> => {
    try {
        return await PostRequest<ProjectTypeData[]>("/api/product/get_all_project_types", data);
    } catch (error) {
        console.log('Error in getProjectType:', error);
        throw error;
    }
}