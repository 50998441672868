import { useMutation } from '@tanstack/react-query'
import { getFile } from '../../api/files'
import { PostParam } from '@/types'

export function useGetFile(props?: PostParam) {
    const { onSuccess, onError } = props || {};

    return useMutation({
        mutationFn: getFile,
        onSuccess,
        onError
    })
}