import React from "react";

interface Props {
  className: string;
}

export const LinkIcon = ({ className }: Props): JSX.Element => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_1599_7344)">
        <path
          d="M7.125 12.375L11.875 7.625"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.70831 5.24996L9.07485 4.82562C9.81728 4.0833 10.8242 3.66631 11.8741 3.66638C12.924 3.66646 13.9308 4.08359 14.6731 4.82602C15.4154 5.56845 15.8324 6.57536 15.8324 7.62524C15.8323 8.67512 15.4152 9.68197 14.6727 10.4243L14.25 10.7916"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.2917 14.75L9.97739 15.1728C9.22629 15.9155 8.21259 16.3321 7.15628 16.3321C6.09997 16.3321 5.08627 15.9155 4.33518 15.1728C3.96496 14.8067 3.67104 14.3708 3.47045 13.8904C3.26985 13.41 3.16656 12.8945 3.16656 12.3739C3.16656 11.8532 3.26985 11.3377 3.47045 10.8573C3.67104 10.3769 3.96496 9.94098 4.33518 9.57492L4.75001 9.20837"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1599_7344">
          <rect
            width="19"
            height="19"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
