import { ToastTypes } from '@/types';
import { createSlice } from '@reduxjs/toolkit';

interface SystemState {
  toastMessage: string;
  toastType: ToastTypes;
  toastTitle: string;
  isToastVisible: boolean;
}

const initialState: SystemState = {
  toastMessage: 'init',
  toastType: 'info', 
  toastTitle: '',
  isToastVisible: false,
};

const systemSlice = createSlice({
  name: 'system', 
  initialState,
  reducers: {
    setToast: (state, action) => {
      state.toastMessage = action.payload.toastMessage;
      state.toastType = action.payload.toastType;
      state.toastTitle = action.payload.toastTitle;
      state.isToastVisible = true;
    },
    hideToast: (state) => {
      state.isToastVisible = false;
    }
  },
});

export const { setToast, hideToast } = systemSlice.actions;
export default systemSlice.reducer;
