import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './css/LanguageSwitcher.module.css';
import translationButtonHeaderSvg from "@/assets/translationButtonHeader.svg"

const LanguageSwitcher: React.FC = () => {
  const { i18n, t } = useTranslation('LanguageSwitcher');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('i18nextLng', lang);
    document.documentElement.dir = lang === 'he' ? 'rtl' : 'ltr';
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    document.documentElement.dir = i18n.language === 'he' ? 'rtl' : 'ltr';
  }, [i18n.language]);

  return (
    <div className={styles.languageSwitcher}>
      <button
        value={i18n.language}
        className={styles.container}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)} // Toggle dropdown
      >
        <img src={translationButtonHeaderSvg} alt="translation button header" />
      </button>

      {isDropdownOpen && (
          <ul className={styles.dropdownMenu}>
            <li className={styles.ilOption} onClick={() => handleLanguageChange('en')}>{t('English')}</li>
            <li className={styles.ilOption} onClick={() => handleLanguageChange('he')}>{t('Hebrew')}</li>
          </ul>
        )}
    </div>

  );
};

export default LanguageSwitcher;
