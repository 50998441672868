import React from "react";

interface Props {
  className: string;
}

export const Plus = ({ className }: Props): JSX.Element => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_1509_7681)">
        <path
          d="M12.9894 5.20626C12.7933 5.01017 12.5273 4.9 12.25 4.9C11.9727 4.9 11.7067 5.01017 11.5106 5.20626C11.3145 5.40236 11.2043 5.66833 11.2043 5.94565V11.2043H5.94565C5.66833 11.2043 5.40236 11.3145 5.20626 11.5106C5.01017 11.7067 4.9 11.9727 4.9 12.25C4.9 12.5273 5.01017 12.7933 5.20626 12.9894C5.40236 13.1855 5.66833 13.2957 5.94565 13.2957H11.2043V18.5543C11.2043 18.8317 11.3145 19.0976 11.5106 19.2937C11.7067 19.4898 11.9727 19.6 12.25 19.6C12.5273 19.6 12.7933 19.4898 12.9894 19.2937C13.1855 19.0976 13.2957 18.8317 13.2957 18.5543V13.2957H18.5543C18.8317 13.2957 19.0976 13.1855 19.2937 12.9894C19.4898 12.7933 19.6 12.5273 19.6 12.25C19.6 11.9727 19.4898 11.7067 19.2937 11.5106C19.0976 11.3145 18.8317 11.2043 18.5543 11.2043H13.2957V5.94565C13.2957 5.66833 13.1855 5.40236 12.9894 5.20626Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="0.2"
        />
      </g>
      <defs>
        <clipPath id="clip0_1509_7681">
          <rect x="4" y="4" width="16" height="16" rx="8" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};
