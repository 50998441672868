import { DownloadFileIcon } from "@/icons/DownloadFileIcon";
import { FullScreenIcon } from "@/icons/FullScreenIcon";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Sentimental } from "./Sentimental";
import styles from "./css/PdfView.module.css";
import { FileData, TaskData } from "@/types";
import * as pdfjsLib from "pdfjs-dist";

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

interface Props {
  file: FileData;
  task: TaskData | undefined;
}

export const PdfView: React.FC<Props> = ({ file, task }) => {
  const { t } = useTranslation('PdfView');
  const containerRef = useRef<HTMLDivElement>(null);

  function downloadPDF(pdfUrl: string, filename: string) {
    const a = document.createElement("a");
    a.href = pdfUrl;
    a.download = filename;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  

  useEffect(() => {
    const renderPDF = async () => {
      try {
        const loadingTask = pdfjsLib.getDocument(file.url);
        const pdf = await loadingTask.promise;

        const container = containerRef.current;
        if (container) {
          (container as HTMLDivElement).innerHTML = "";

          for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
            const page = await pdf.getPage(pageNumber);

            const viewport = page.getViewport({ scale: 0.99999999 });
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            if (context) {
              // Set the width of the canvas to the full width of the container
              const scale = container.clientWidth / viewport.width;
              const scaledViewport = page.getViewport({ scale });

              canvas.height = scaledViewport.height;
              canvas.width = scaledViewport.width;

              const renderContext = {
                canvasContext: context,
                viewport: scaledViewport,
              };

              // Append the canvas to the container
              container.appendChild(canvas);

              await page.render(renderContext).promise;
            }
          }
        }
      } catch (error) {
        console.error("Error rendering PDF:", error);
      }
    };

    renderPDF();
  }, [file.url]);

  return (
    <div className={styles.container}>
      <div className={styles.pdfViewContainer}>
        <div className={styles.summActions}>
          <div className={styles.summActionIcon} onClick={() => downloadPDF(file.url, file.name)}>
            <DownloadFileIcon />
          </div>
        </div>
        <div className={styles.pdfView}>
          <div ref={containerRef}></div>
        </div>
      </div>
      <div className={styles.resultsContainer}>
        <div className={styles.summTitle}>{t("summTitle")}</div>
        <div className={styles.summResults}>
          <textarea
            className={styles.nonEditableTextarea}
            readOnly
            dir={task?.results?.summary?.charAt(0).match(/[\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC]/) ? 'rtl' : 'ltr'}
            value={task && task.results?.summary? task.results.summary: ""}
          />
          <div className={styles.summResult}>
            <Sentimental sentiment="happy" />
            <div className={styles.summActions}>
              <div className={styles.summActionIcon} onClick={() => {}}>
                <DownloadFileIcon />
              </div>
              <div className={styles.summActionIcon} onClick={() => {}}>
                <FullScreenIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
