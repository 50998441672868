import React from "react";

interface Props {
  className: string;
}

export const AudioIcon = ({ className }: Props): JSX.Element => {
  return (
    <svg
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M66.7688 25.7313L49.2688 8.23125C49.0364 7.99916 48.7606 7.81513 48.4571 7.68966C48.1536 7.56419 47.8284 7.49974 47.5 7.5H17.5C16.1739 7.5 14.9021 8.02678 13.9645 8.96447C13.0268 9.90215 12.5 11.1739 12.5 12.5V37.5C12.5 38.163 12.7634 38.7989 13.2322 39.2678C13.7011 39.7366 14.337 40 15 40C15.663 40 16.2989 39.7366 16.7678 39.2678C17.2366 38.7989 17.5 38.163 17.5 37.5V12.5H45V27.5C45 28.163 45.2634 28.7989 45.7322 29.2678C46.2011 29.7366 46.837 30 47.5 30H62.5V67.5H52.5C51.837 67.5 51.2011 67.7634 50.7322 68.2322C50.2634 68.7011 50 69.337 50 70C50 70.663 50.2634 71.2989 50.7322 71.7678C51.2011 72.2366 51.837 72.5 52.5 72.5H62.5C63.8261 72.5 65.0979 71.9732 66.0355 71.0355C66.9732 70.0979 67.5 68.8261 67.5 67.5V27.5C67.5003 27.1716 67.4358 26.8464 67.3103 26.5429C67.1849 26.2394 67.0008 25.9636 66.7688 25.7313ZM50 16.0344L58.9656 25H50V16.0344Z"
        fill="currentColor"
      />
      <path
        d="M9.16699 56.8053V61.3886M15.2781 50.6942V67.4997M21.3892 46.1108V73.6108M27.5003 53.7497V64.4442M33.6114 49.1664V69.0275M39.7226 56.8053V61.3886"
        stroke="currentColor"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
