import React, { useState, useEffect } from "react";

export const Progress: React.FC = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 1));
    }, 50);
    return () => clearInterval(interval);
  }, []);

  const keyframes = `
    @keyframes move {
      0% {
        left: -50px; /* Start off-screen */
      }
      100% {
        left: 100%; 
      }
    }
  `;

  return (
    <div style={{ padding: '10px', width: '200px', borderRadius: '5px', overflow: 'hidden' }}>
      <style>{keyframes}</style>
      <div style={{ width: '100%', height: '8px', backgroundColor: '#ccc', borderRadius: '5px', overflow: 'hidden', position: 'relative' }}>
        <div
          style={{
            width: '50px', 
            height: '100%',
            backgroundColor: '#007bff',
            borderRadius: '10px', 
            position: 'absolute',
            top: 0,
            left: '-50px', // Start off-screen
            animation: 'move 4s linear infinite', 
          }}
        />
      </div>
    </div>
  );
};
